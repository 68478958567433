// src/data/studyMaterialsData.js
import { icseconcisebiologyselina9 } from './icseconcisebiologyselina9.js';
import { icseconcisechemistryselina9 } from './icseconcisechemistryselina9.js';
import { icsecomputerapplications9 } from './icsecomputerapplications9.js';
import { icsegeography9 } from './icsegeography9.js';
import { icsehistorycivics9 } from './icsehistorycivics9.js';
import { icseconcisephysicsselina9 } from './icseconcisephysicsselina9.js';
import { icsedalalsimplifiedchemistry9 } from './icsedalalsimplifiedchemistry9.js';
import { veenabhargavagoyalgeography9 } from './veenabhargavagoyalgeography9.js';

export const studyMaterialsData = {
    ICSE: [
        {
            class: 'Class 9',
            name: 'Concise Biology Selina',
            chapters: icseconcisebiologyselina9.chapters // Accessing only the chapters array
        },
        {
            class: 'Class 9',
            name: 'Concise Physics Selina',
            chapters: icseconcisephysicsselina9.chapters // Accessing only the chapters array
        },
        {
            class: 'Class 9',
            name: 'Concise Chemistry Selina',
            chapters: icseconcisechemistryselina9.chapters // Accessing only the chapters array
        },
        {
            class: 'Class 9',
            name: 'Dalal Simplified Chemistry',
            chapters: icsedalalsimplifiedchemistry9.chapters // Accessing only the chapters array
        },
        {
            class: 'Class 9',
            name: 'Computer Application',
            chapters: icsecomputerapplications9.chapters // Accessing only the chapters array
        },
        {
            class: 'Class 9',
            name: 'Geography',
            chapters: icsegeography9.chapters // Accessing only the chapters array
        },
        {
            class: 'Class 9',
            name: 'Veena Bhargava Goyal Geography',
            chapters: veenabhargavagoyalgeography9.chapters // Accessing only the chapters array
        },
        {
            class: 'Class 9',
            name: 'History & Civics',
            chapters: icsehistorycivics9.chapters // Accessing only the chapters array
        }
        
        // Add other subjects for other classes here
    ],
    CBSE: [
        // Add CBSE subjects similarly
    ]
};
