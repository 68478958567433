// src/components/StudyMaterials.js
import React, { useState } from 'react';
import './StudyMaterials.css';
import { studyMaterialsData } from '../data/studyMaterialsData.js';

const StudyMaterials = () => {
    const [selectedBoard, setSelectedBoard] = useState('ICSE');

    const handleBoardChange = (board) => {
        setSelectedBoard(board);
    };

    // Group study materials by class
    const groupedMaterials = studyMaterialsData[selectedBoard].reduce((acc, material) => {
        if (!acc[material.class]) {
            acc[material.class] = [];
        }
        acc[material.class].push(material);
        return acc;
    }, {});

    return (
        <div className="study-materials-container">
            <section className="study-materials-hero text-center">
                <div className="container">
                    <h1 className="display-4 font-weight-bold">Study Materials</h1>
                    <p className="lead">Access comprehensive study materials for different classes and subjects.</p>
                </div>
            </section>

            <div className="board-selector text-center">
                <button 
                    className={`board-button ${selectedBoard === 'ICSE' ? 'active' : ''}`} 
                    onClick={() => handleBoardChange('ICSE')}
                >
                    ICSE
                </button>
                <button 
                    className={`board-button ${selectedBoard === 'CBSE' ? 'active' : ''}`} 
                    onClick={() => handleBoardChange('CBSE')}
                >
                    CBSE
                </button>
            </div>

            <div className="materials-grid container">
                {Object.keys(groupedMaterials).map((className, index) => (
                    <div key={index} className="class-section">
                        <h2 className="class-title">{className}</h2>
                        <div className="subjects-grid">
                            {groupedMaterials[className].map((material, idx) => (
                                <a 
                                    key={idx} 
                                    href={`/study-materials/${selectedBoard}/${material.class}/${encodeURIComponent(material.name)}`} 
                                    className="subject-card"
                                >
                                    <h3 className="subject-name">{material.name}</h3>
                                    <p className="subject-category">{material.category}</p>
                                </a>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StudyMaterials;
