export const veenabhargavagoyalgeography9 = {
    class: "9",
    name: "Veena Bhargava Goyal Geography",
    category: "Veena Bhargava Goyal Geography",
    chapters: [
        { 
            title: "Earth as a Planet",
            questions: [
                { 
                    question: "<p>Why is the earth a unique planet?</p>",
                    answer: `<p>Earth is known as a unique planet because it is the only planet to support life. No other planet till date is known to have life on it.</p>`
                },
                { 
                    question: "<p>Why the earth is called the blue planet?<br/>OR<br/>Why earth is called the watery planet?</p>",
                    answer: `<p>The earth is called the blue planet or watery planet because abundance of water on the earth gives it a vivid blue colour when viewed from space.</p>`
                },
                { 
                    question: "<p>What is the surface of the earth made up of?</p>",
                    answer: `<p>The surface of earth is made up of solid crust of rocks. The main part of the crust consists of igneous rocks, the rest consists of sedimentary and metamorphic rocks.</p>`
                },
                { 
                    question: "<p>Name a planet that cannot support life. Give reasons for your answer.</p>",
                    answer: `<p>Venus is one of the planets that cannot support life because it is the hottest planet of the solar system. Venus is the second nearest planet to the Sun. Its atmosphere contains large amount of carbon dioxide due to which it is the hottest planet. Due to its high temperature it cannot support life.</p>`
                },
                { 
                    question: "<p>State three factors that have made life possible on the planet earth.<br/>OR<br/>State the conditions favourable for life to exist on earth.</p>",
                    answer: `<ol><li>Its distance from the Sun is neither too near nor too far, hence ideal temperature conditions.</li><li>It has atmosphere containing oxygen and nitrogen necessary to support life.</li><li>It has water cycle. Presence of water has made existence of life possible on earth. It is essential for all kinds of life.</li></ol>`
                },
                { 
                    question: "<p>Name the various realms of the earth.</p>",
                    answer: `<p>The various realms of the earth are — lithosphere, hydrosphere and atmosphere. Biosphere consists of all these realms along with the life.</p>`
                },
                { 
                    question: "<p>Why is the shape of the earth described as an oblate spheroid?</p>",
                    answer: `<p>The shape of the earth is described as an oblate spheroid because the measurements of earth at the equator and poles show that it has a slight bulge at the equator while it is slightly flattened at the poles. This shape is also known as a Geoid, meaning earth shaped.</p>`
                },
                { 
                    question: "<p>Give two proofs about the shape of the earth.</p>",
                    answer: `<ol><li><strong>All celestial bodies are spherical</strong> — Spheres are the only geometrical shapes that are circular in outline. Sun, Moon amd all other celestial bodies appear to have circular outline. Since Earth too belongs to the same system, there is no reason why Earth should be an exception.</li><li><strong>Circumnavigation of the earth</strong> — Time and again people travelling round the world in different directions came back to the starting point.</li></ol>`
                },
                { 
                    question: "<p>What is the significance of the atmosphere on earth as compared to other planets?</p>",
                    answer: `<ol><li>The earths atmosphere contains 21% oxygen, 78% nitrogen with traces of carbon dioxide, water vapour and other gases. This allows the existence of life on earth.</li><li>It extends up to 1600 km.</li><li>Atmosphere acts as a blanket of air protecting us from the harmful ultraviolet rays of the sun.</li><li>It retains the heat radiated from the earth's surface.</li></ol>`
                },
                { 
                    question: "<p>List the conditions favourable to life on earth.</p>",
                    answer: `<ol><li>The position of earth in solar system is such that it is neither too far nor too near to the sun. Hence, it receives just the right amount of heat necessary for life.</li><li>The atmosphere of other planets is either too thin or too dense, full of poisonous gases, making it impossible for life to exist. On the other hand, earth's atmosphere extends up to 1600 km and contains adequate amount of nitrogen (78%), oxygen (21%) and other gases like helium, carbon dioxide and water vapour.</li><li>It acts as a blanket, protecting us from harmful ultraviolet rays of the sun, and retains the heat radiated from the earth's surface.</li><li>Earth's water cycle is the most unique feature. Water is essential for the existence of life.</li></ol>`
                },
                { 
                    question: "<p>Terrestrial planets</p>",
                    answer: `<p>The planets that are made up of hard silicate rocks or metals are called Terrestrial planets. The other planets lack a solid surface. The four inner planets of the solar system- Mercury, venus, earth and Mars are terrestrial planets.</p>`
                },
                { 
                    question: "<p>Oxygen cycle</p>",
                    answer: `<p>Oxygen cycle refers to the biochemical cycle which is involved in maintaining the level of oxygen in the atmosphere. Men, animals inhale oxygen and exhale carbon dioxide which is taken by plants. Plants use this carbon dioxide in photosynthesis and release oxygen, which is again used in respiration.</p>`
                },
                { 
                    question: "<p>Unique planet</p>",
                    answer: `<p>Earth is known as a unique planet because it is the only planet to support life. No other planet till date is known to have life on it. The presence of oxygen, water and habitable temperature enables earth to sustain life.</p>`
                },
                { 
                    question: "<p>Water Cycle</p>",
                    answer: `<p>Water cycle refers to circulation of water from earth's surface by evaporation and transpiration to cloud formation by condensation and again falling back on earth's surface in the form of precipitation.</p>`
                },
                { 
                    question: "<p>Biosphere</p>",
                    answer: `<p>Biosphere refers to three realms of earth i.e. lithosphere, atmosphere and hydrosphere; along with life present on it. It is the sphere of living organisms or biological processes lying at the interfaces between atmosphere, lithosphere and hydrosphere.</p>`
                },
                { 
                    question: "<p>Environment</p>",
                    answer: `<p>The surroundings or conditions in which a person, animal or plant lives or operates is called environment. It consists of all the biotic and abiotic factors and their effects on the organism.</p>`
                },
                { 
                    question: "<p>Atmosphere</p>",
                    answer: `<p>The envelop of air surrounding the earth is called atmosphere. The atmosphere consists of oxygen, nitrogen, carbon dioxide, argon, water vapour etc. It forms a 1600 km thick covering around the earth.</p>`
                },
                { 
                    question: "<p>The earth is the only habitable planet.</p>",
                    answer: `<ol><li>It has habitable temperature condition.</li><li>It has atmosphere containing oxygen and nitrogen necessary to support life.</li><li>It has water in all three states.</li></ol>`
                },
                { 
                    question: "<p>The earth's shape is Geoid.</p>",
                    answer: `<p>The Earth is shaped like an orange, slightly flattened at the poles and with a bulge at the Equator. Hence, the earth's shape is Geoid. The word 'Geoid' means earth-shaped.</p>`
                },
                { 
                    question: "<p>The earth is the home of humankind.</p>",
                    answer: `<p>The earth is the home of humankind because it is the only planet which can support human life. The life exists on earth because it has the right temperature, gases like oxygen and nitrogen; and water.</p>`
                },
                { 
                    question: "<p>Earth's orbit lies between</p>",
                    answer: `<p>Venus and Mars</p>`
                },
                { 
                    question: "<p>The amount of oxygen in earth's atmosphere is</p>",
                    answer: `<p>21%</p>`
                },
                { 
                    question: "<p>Earth's biosphere is also called</p>",
                    answer: `<p>ecosystem</p>`
                },
                { 
                    question: "<p>The average distance of earth from the sun is</p>",
                    answer: `<p>150 million km</p>`
                },
                { 
                    question: "<p>Earth completes its one revolution around the sun in</p>",
                    answer: `<p>365 1⁄4 days</p>`
                },
                { 
                    question: "<p>Earth's shape is described as</p>",
                    answer: `<p>Oblate spheroid</p>`
                },
                { 
                    question: "<p>Which of the following process is not involved in water cycle process?</p>",
                    answer: `<p>Lithification</p>`
                },
                { 
                    question: "<p>Lithosphere consists of</p>",
                    answer: `<p>Soil &amp; minerals</p>`
                },
                { 
                    question: "<p>The earth is the ............... planet from the Sun.</p>",
                    answer: `<p>Third</p>`
                },
                { 
                    question: "<p>Our solar system is the part of which of the following galaxy?</p>",
                    answer: `<p>Milky Way Galaxy</p>`
                },
                { 
                    question: "<p>Which of the following force is responsible for the oblate spheroid shape of earth?</p>",
                    answer: `<p>Centrifugal force</p>`
                },
                { 
                    question: "<p>Geoid means</p>",
                    answer: `<p>Earth shaped</p>`
                },
                { 
                    question: "<p>Draw a neat diagram to show the realms of the Earth.</p>",
                    answer: `<p>Below diagram shows the realms of the earth:</p>`
                },
                { 
                    question: "<p>Draw a neat diagram to show the water cycle on the earth.</p>",
                    answer: `<p>Below diagram shows the water cycle on the earth:</p>`
                },
            ]
        },
        { 
            title: "Latitudes and Longitudes",
            questions: [
                { 
                    question: "<p>Why do we need to locate places on Earth?</p>",
                    answer: `<ol><li>It helps us to know the exact position of places.</li><li>It helps to find distance between two places if their exact positions are located.</li><li>It gives us an idea to locate physical features, villages, cities etc.</li><li>Finding location helps us to design various infrastructures like roads, railways.</li></ol>`
                },
                { 
                    question: "<p>What do you mean by the latitude of a place?</p>",
                    answer: `<p>The latitude of a place is the angular distance of that place North or South of the equator, as measured from the centre of the earth.</p>`
                },
                { 
                    question: "<p>What is meant by Prime Meridian?</p>",
                    answer: `<p>The 0° longitude passing through Greenwich near London is called the Prime meridian.</p>`
                },
                { 
                    question: "<p>Explain why there is no higher latitude other than 90 degrees North and South.</p>",
                    answer: `<p>There is no higher latitude other than 90 degrees North and South because lines of latitude start at 0° (equator) and end at 90° (poles). All planes make a maximum angle of 90° from the plane of the equator.</p>`
                },
                { 
                    question: "<p>Explain why Lines of Longitudes are called Meridian of Longitude.</p>",
                    answer: `<p>The Lines of Longitudes are called Meridian of Longitude because the word 'meridian' means \"midday\" and all the places on the same meridian have their noon at the same time.</p>`
                },
                { 
                    question: "<p>State two properties of the Lines of Latitude.</p>",
                    answer: `<ol><li>Each latitude is an imaginary circle parallel to the equator and centered on the polar axis.</li><li>The radius and the length of parallels gradually decrease from its maxima at the equator to its minima at the poles.</li></ol>`
                },
                { 
                    question: "<p>State two properties of Lines of Longitude.</p>",
                    answer: `<ol><li>All meridians are complete circles dividing the earth into Eastern and Western Hemisphere.</li><li>The longitudes are drawn 1° apart on the Equator, with 1° to 179° towards east and 1° to 179° towards west.</li></ol>`
                },
                { 
                    question: "<p>The distance between two successive parallels of latitudes is 111 km. Explain.</p>",
                    answer: `<p>The whole earth represents 360° and the circumference of the earth is approximately 40,000 km. Therefore, for each degree i.e. the difference between two latitudes is 111km (i.e 40000 divided by 360).</p>`
                },
                { 
                    question: "<p>What are the limits of two Temperate Zones?</p>",
                    answer: `<p>The limit of northern temperate zone is 23 1/2°N to 66 1/2°N and the limit of southern temperate zone is 23 1/2°S to 66 1/2°S. The temperate zone stretches from Torrid zone to Frigid zone.</p>`
                },
                { 
                    question: "<p>Why are the places in the Torrid Zone hotter than those in other zones?</p>",
                    answer: `<p>The Torrid zone or the tropical zones lie between the Tropic of cancer and Tropic of Capricorn which mark the limit of the overhead Sun to North and South of the Equator, i.e., 23 1/2°N to 23 1/2°S. This area receives direct sun rays and therefore, the places in the Torrid Zone are hotter than those in other zones.</p>`
                },
                { 
                    question: "<p>Why are the places in the Frigid Zone colder than in other zones?</p>",
                    answer: `<p>The Frigid Zone experiences colder temperatures compared to other zones due to the inclination of the earth's axis, which results in the area receiving oblique rays of sunlight during the winter.</p>`
                },
                { 
                    question: "<p>How is the Local Time of a place fixed?</p>",
                    answer: `<p>The Local Time of a place is fixed according to the central meridian of its country. When the sun is overhead on the central meridian it is 12 o'clock noon at that place. For example, the central meridian of India is 82 1/2° E and passes through Allahabad and it is the standard time line for the whole country.</p>`
                },
                { 
                    question: "<p>Is it correct to say that Local Time is the Sun Time?</p>",
                    answer: `<p>Yes, it is correct to say that Local Time is the Sun Time because local time is determined based on Sun's apparent movement.</p>`
                },
                { 
                    question: "<p>If the GMT at 0° longitude is 12 noon, find the local time of place A (30°E).</p>",
                    answer: `<p>The difference in longitude between 0° and place A is 30° towards east.</p>`
                },
                { 
                    question: "<p>Why is the Standard Time considered necessary?</p>",
                    answer: `<p>The Standard Time is considered necessary because if every city had to keep the time of their own meridian, there would have been much difference in the local time of one city and the other. This would have led to a lot of confusion.</p>`
                },
                { 
                    question: "<p>What are Time Zones? How many Time Zones do we have?</p>",
                    answer: `<p>A zone on terrestrial globe that is approximately 15° longitude wide with 1 hour difference in time from other zone, is called time zone. The world is divided into 24 time zones and India has one time zone.</p>`
                },
                { 
                    question: "<p>London experiences a lower temperature than Singapore throughout the year. Why?</p>",
                    answer: `<p>Singapore is situated in torrid zone, near the equator while London is located in temperate zone. Torrid zone receives direct sun rays and is hotter than the temperate zone which receives slant rays of the sun. Therefore, London experiences a lower temperature than Singapore throughout the year.</p>`
                },
                { 
                    question: "<p>What is International Date Line? Describe the use of this line.</p>",
                    answer: `<p>A zigzag line at roughly 180° meridian is the line which is internationally adopted as International Date Line or IDL. On either side of this line, a difference of whole day is observed. This is because the earth completes one rotation on its axis in 24 hours as it rotates from west to east.</p>`
                },
                { 
                    question: "<p>Why do some countries have many Time Zones?</p>",
                    answer: `<p>Some countries have large longitudinal extent and therefore they have many tine zones.</p>`
                },
                { 
                    question: "<p>What are Small Circles?</p>",
                    answer: `<p>The 0° latitude that circumscribes the earth around it's centre is called Equator or the great circle and all other circles that do not circumscribes the earth around it's centre are called small circles.</p>`
                },
                { 
                    question: "<p>Except for the Equator, other Parallels of Latitudes are not Great Circles. Why?</p>",
                    answer: `<p>The equator is the only latitude that circumscribes the earth around it's centre and therefore it is the largest latitude. All other latitudes, either in north or in south, are smaller than equator because they do not circumscribe the earth around its centre.</p>`
                },
                { 
                    question: "<p>How is the use of local time inconvenient in practical life?</p>",
                    answer: `<p>The use of local time is inconvenient in practical life because local time is different in different countries. For those travelling abroad, it is very confusing to have different time zones.</p>`
                },
                { 
                    question: "<p>What is a Grid?</p>",
                    answer: `<p>A grid is a network of evenly spaced horizontal and vertical lines used to identify locations on a map. Earth's grid refers to the network of latitudes and longitudes on the earth's surface.</p>`
                },
                { 
                    question: "<p>Who was the first mathematician to have measured the circumference of the Earth?</p>",
                    answer: `<p>The first mathematician to have measured the circumference of the Earth was Eratosthenes.</p>`
                },
                { 
                    question: "<p>Name the important Climatic Zones of the World.</p>",
                    answer: `<ol><li>The Torrid or tropical zones</li><li>Temperate zones</li><li>Frigid zones</li></ol>`
                },
                { 
                    question: "<p>State the rate of change of time with longitude.</p>",
                    answer: `<p>For every 1° change in longitude, the change in time is 4 minutes.</p>`
                },
                { 
                    question: "<p>Explain how latitudes and longitudes help to locate position of a place on a globe.</p>",
                    answer: `<p>The latitudes and longitudes are horizontal and vertical lines which form a grid on the globe or map. We can easily locate a place on the globe if we have its longitudinal and latitudinal positions. The point of intersection of its latitude and longitude will determine the position of that place on globe.</p>`
                },
                { 
                    question: "<p>Answer the following questions based on the below diagram:</p>",
                    answer: `<ol><li>What does PP' represent?</li><li>What does TT' represent?</li><li>What is the latitude of the place X?</li></ol>`
                },
                { 
                    question: "<p>Distinguish between:</p>",
                    answer: `<table><thead><tr><th>S.No.</th><th>Equator</th><th>Prime Meridian</th></tr></thead><tbody><tr><td>1.</td><td>It is the latitude of 0° that circumscribes the earth around its centre.</td><td>It is the longitude of 0° which passes through Greenwich near London.</td></tr><tr><td>2.</td><td>It divides the earth into Southern and Northern hemisphere.</td><td>It divides the earth into Eastern and Western hemisphere.</td></tr></tbody></table>`
                },
                { 
                    question: "<p>Latitude</p>",
                    answer: `<p>The latitude of a place is the angular distance of that place North or South of the equator, as measured from the centre of the earth.</p>`
                },
                { 
                    question: "<p>Longitude</p>",
                    answer: `<p>The longitude of a place is the angular distance of that place east or west of the prime meridian.</p>`
                },
                { 
                    question: "<p>Meridian</p>",
                    answer: `<p>The imaginary lines or semi-circles that connect north pole and south pole are called meridian.</p>`
                },
                { 
                    question: "<p>Prime Meridian</p>",
                    answer: `<p>Prime Meridian is the longitude of 0° which connects north and south poles and passes through Greenwich near London.</p>`
                },
                { 
                    question: "<p>Local time</p>",
                    answer: `<p>When the sun is overhead at a place, the local time is 12 o'clock noon at that place. Local time of a place can also be calculated if we know the longitude of the place.</p>`
                },
                { 
                    question: "<p>Standard time</p>",
                    answer: `<p>Standard time of a country is defined as the local time of its central meridian which is observed throughout the country.</p>`
                },
                { 
                    question: "<p>Indian Standard Time</p>",
                    answer: `<p>The longitude of 82 1/2°E is the central meridian of India which passes through Allahabad. Its local time is the standard time for the country and is known as Indian Standard Time.</p>`
                },
                { 
                    question: "<p>Greenwich Mean Time</p>",
                    answer: `<p>The local time of zero degree meridian which passes through the Royal Astronomical Observatory at Greenwich, is taken as international standard time and is known as Greenwich Mean Time.</p>`
                },
                { 
                    question: "<p>International Date Line</p>",
                    answer: `<p>The longitude at 180° is considered as International Date Line as on either side of this line, a difference of one whole day is observed.</p>`
                },
                { 
                    question: "<p>Great circle</p>",
                    answer: `<p>The latitude at 0° which circumscribe the earth around it's centre and divides the earth into two hemispheres is called Great circle.</p>`
                },
                { 
                    question: "<p>Small circle</p>",
                    answer: `<p>All other latitudes except the equator are smaller (than the equator) and are known as Small circles.</p>`
                },
                { 
                    question: "<p>Grid</p>",
                    answer: `<p>A grid is a network of evenly spaced horizontal lines (latitudes) and vertical lines (longitudes) used to identify locations on a map.</p>`
                },
                { 
                    question: "<p>Latitude and Longitude.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Great Circle and Small Circle</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Greenwich Mean Time and Indian Standard Time</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Latitudes and Longitudes are always expressed in angles.</p>",
                    answer: `<p>Latitudes and Longitudes are always expressed in angles because they represent angular distance as the earth is spherical in shape.</p>`
                },
                { 
                    question: "<p>Large countries have many time zones.</p>",
                    answer: `<p>Large countries have larger longitudinal extent and therefore they have many time zones. For example, Canada has 6 time zones.</p>`
                },
                { 
                    question: "<p>The international Date Line is not a straight line like other longitudes.</p>",
                    answer: `<p>The International Date Line is not a straight line like other longitudes because in order to avoid any landmass, deviation has been taken. It is done to avoid the confusion of having different dates in the same country.</p>`
                },
                { 
                    question: "<p>A person gains time as he travels towards east.</p>",
                    answer: `<p>A person gains time as he travels towards east because the earth rotates from west to east. Places on the east of a given point on the earth are ahead of time.</p>`
                },
                { 
                    question: "<p>The intervals between successive parallels are constant.</p>",
                    answer: `<p>The intervals between successive parallels are constant because the latitudes are parallel and equidistant to each other.</p>`
                },
                { 
                    question: "<p>A person travelling from Mumbai to London has to alter his watch.</p>",
                    answer: `<p>A person travelling from Mumbai to London has to alter his watch because both the cities are in different time zones.</p>`
                },
                { 
                    question: "<p>The difference between I.S.T. and the G.M.T. is 5 hours and 30 minutes.</p>",
                    answer: `<p>The difference between longitudes of I.S.T. and the G.M.T. = 82 1/2°</p>`
                },
                { 
                    question: "<p>Parallels are not equal in length.</p>",
                    answer: `<p>Since shape of the Earth is an oblate spheroid, the radius and length of parallels gradually decrease from its maxima at the Equator to its minima at the Poles. Hence, parallels are not equal in length.</p>`
                },
                { 
                    question: "<p>Navigators use Great circle route for navigation.</p>",
                    answer: `<p>Navigators use Great circle route for navigation because Great circle routes are the shortest distance between the two places on the Earth. They help to save fuel and time so they are very economical.</p>`
                },
                { 
                    question: "<p>Parallels of Latitude</p>",
                    answer: `<p>Below diagram shows Parallels of Latitude:</p>`
                },
                { 
                    question: "<p>Temperature Zones</p>",
                    answer: `<p>Below diagram shows Temperature Zones:</p>`
                },
                { 
                    question: "<p>Earth's Grid</p>",
                    answer: `<p>Below diagram shows Earth's Grid:</p>`
                },
                { 
                    question: "<p>Earth is divided into Northern &amp; Southern Hemisphere by which of the following imaginary line?</p>",
                    answer: `<p>Equator</p>`
                },
                { 
                    question: "<p>What is the latitude of Arctic Circle?</p>",
                    answer: `<p>66 1⁄2°N</p>`
                },
                { 
                    question: "<p>How many total lines of latitudes are there in the world?</p>",
                    answer: `<p>181</p>`
                },
                { 
                    question: "<p>Refer to the image and identify the climatic zone of the shaded region.</p>",
                    answer: `<p>Torrid Zone</p>`
                },
                { 
                    question: "<p>There is a time difference of ............... for every 1° of longitudinal difference.</p>",
                    answer: `<p>4 minutes</p>`
                },
                { 
                    question: "<p>What is the full form of IDL?</p>",
                    answer: `<p>International Date Line</p>`
                },
                { 
                    question: "<p>Which of the following meridian is called the Central Meridian of India?</p>",
                    answer: `<p>82 1⁄2°E</p>`
                },
                { 
                    question: "<p>USA has ............... time zones.</p>",
                    answer: `<p>6</p>`
                },
                { 
                    question: "<p>Which of the following statements related to lines of latitude is not correct?</p>",
                    answer: `<p>They are used to determine time</p>`
                },
                { 
                    question: "<p>Meridian means</p>",
                    answer: `<p>Midday</p>`
                },
                { 
                    question: "<p>The difference between IST &amp; GMT is</p>",
                    answer: `<p>5 hours 30 mins</p>`
                },
                { 
                    question: "<p>Which of the following statement about Great circle is true?</p>",
                    answer: `<p>Equator &amp; all the meridians are Great circles.</p>`
                },
                { 
                    question: "<p>World is divided into ............... time zones.</p>",
                    answer: `<p>24</p>`
                },
            ]
        },
        { 
            title: "Rotation and Revolution",
            questions: [
                { 
                    question: "<p>What is meant by the inclination of the Earth's Axis?</p>",
                    answer: `<p>The axis on which earth rotates makes an angle of 66 1⁄2° with the plane of ecliptic and is tilted 23 1⁄2° from a line perpendicular to that plane. This tilt of earth is called inclination of the Earth's Axis.</p>`
                },
                { 
                    question: "<p>Name an effect of the Earth's rotation.</p>",
                    answer: `<p>Occurrence of day and night is an effect of the Earth's rotation.</p>`
                },
                { 
                    question: "<p>State two effects of West to East rotation of the Earth.</p>",
                    answer: `<ol><li>It determines the direction in which the Sun, Moon and stars appear to rise and set.</li><li>Ocean currents and winds are deflected towards right in the Northern Hemisphere and towards the left in the Southern Hemisphere. This is called Coriolis Effect.</li></ol>`
                },
                { 
                    question: "<p>What causes slight flattening of the Earth at two Poles?</p>",
                    answer: `<p>The earth rotates about its axis and experiences a centrifugal force which cause a bulge at equator. To balance this bulge there is slight flattening of the Earth at two Poles.</p>`
                },
                { 
                    question: "<p>What is meant by the Revolution of the Earth? What are its effects?</p>",
                    answer: `<ol><li>Occurrence of different seasons.</li><li>It causes Spring and Autumn Equinoxes.</li><li>It causes Summer and Winter Solstice.</li><li>Changing altitude of the mid-day sun at different times of the year.</li></ol>`
                },
                { 
                    question: "<p>Explain the meaning of the terms 'Solstice' and 'Equinox'.</p>",
                    answer: `<p>The term 'Solstice' means an event occurring when the Sun appears to reach its most northerly or southerly excursion relative to the celestial equator on the celestial sphere. It occurs on June 21 (Summer Solstice) and December 22 (Winter Solstice).</p>`
                },
                { 
                    question: "<p>What causes the formation of day and night? Explain with the help of a neat diagram.</p>",
                    answer: `<p>The formation of the day and night is due to rotation of earth. While rotating, the part of earth facing the Sun has day and the other part has night. The Circle of Illumination is the imaginary line which shows clear demarcation between the phenomena of day and night on the Earth.</p>`
                },
                { 
                    question: "<p>When do Summer Solstice and Winter Solstice occur? How do they occur?</p>",
                    answer: `<p>The Summer Solstice occurs on June 21. It occurs when the Sun appears directly overhead Tropic of Cancer in the Northern Hemisphere.</p>`
                },
                { 
                    question: "<p>Which is the longest day in the Southern Hemisphere?</p>",
                    answer: `<p>The Winter Solstice (December 22) is the longest day in the Southern Hemisphere.</p>`
                },
                { 
                    question: "<p>Which is the longest day in the Northern Hemisphere?</p>",
                    answer: `<p>The Summer Solstice (June 21) is the longest day in the Northern Hemisphere.</p>`
                },
                { 
                    question: "<p>Name the path along which the Earth travels when moving round the Sun. How long does this movement take place?</p>",
                    answer: `<p>The path along which the Earth travels when moving round the Sun is called the orbit of earth. One complete revolution of the Earth takes approximately 365 1/4 days.</p>`
                },
                { 
                    question: "<p>On what date does the Arctic Circle experience 'Midnight Sun'?</p>",
                    answer: `<p>The Arctic Circle experience 'Midnight Sun' on June 21.</p>`
                },
                { 
                    question: "<p>What is a Leap Year?</p>",
                    answer: `<p>One complete revolution of earth takes 365 1/4 days. Every four years, the extra 1/4 day makes a complete day and the next year has 366 days. The year with 366 days instead of 365 days is called leap year and it comes after every four years.</p>`
                },
                { 
                    question: "<p>Give the dates when days and nights are of equal duration on the Equator.</p>",
                    answer: `<p>The dates when days and nights are of equal duration on the Equator are March 21 and September 23.</p>`
                },
                { 
                    question: "<p>Explain with the help of a diagram, the part played by the inclination of Earth's axis in causing day and night of varying duration, except at the Equator.</p>",
                    answer: `<ol><li>At the Equator, place C would be at position C' after 12 hours.<br/>Distance CO = Distance OC'<br/>∴ Length of day = Length of night.</li><li>On the Tropic of Cancer place B will be at B' after 12 hours.<br/>But distance BX is greater than B'X.<br/>∴ Days are longer than nights.</li><li>On the Arctic Circle place A will be at position A' after 12 hours. AA' remains in continuous light in summer for six months.<br/>∴ Day of 24 hours near the North Pole in summer.</li><li>Place D on the Tropic of Capricorn in the Southern Hemisphere will be at position D' after 12 hours.<br/>Distance DY (in light) is less than the distance D'Y (in darkness)<br/>∴ Days are shorter than nights.</li><li>Place E on the Antarctic Circle remains in continuous darkness in winter.<br/>∴ Night for 24 hours near the South Pole in winter for six months.</li></ol>`
                },
                { 
                    question: "<p>What do you mean by the Sidereal Day?</p>",
                    answer: `<p>The period of Rotation of the earth is 23 hr, 56 minutes, 4.09 sec. This period is termed as the Sidereal Day.</p>`
                },
                { 
                    question: "<p>What is Perihelion? Mention the date of this phenomenon.</p>",
                    answer: `<p>The Earth is said to be in perihelion when the distance between the Sun and the Earth is least or about 147 million km. The date of this phenomenon is 3rd January.</p>`
                },
                { 
                    question: "<p>Why does the speed of Earth’s revolution vary?</p>",
                    answer: `<p>The speed of Earth’s revolution varies according to the change in its distance from the Sun. The velocity is greatest at Perihelion and least at Aphelion. This variation in speed is due to effect of gravitational pull of the Sun.</p>`
                },
                { 
                    question: "<p>State the effect of speed of Earth's rotation.</p>",
                    answer: `<ol><li>All moving bodies on the Earth's surface, i.e. ocean currents and winds are deflected towards the right in the Northern Hemisphere and left in Southern Hemisphere.</li><li>Cyclones and anti-cyclones are deflected causing atmospheric changes.</li><li>It affects the pressure belts and movement of air on the surface of earth.</li><li>The bulging of the air at the Equator and flattening at the poles is also due to rotation.</li></ol>`
                },
                { 
                    question: "<p>State any two special features of Summer Solstice.</p>",
                    answer: `<ol><li>Daylight lasts for the entire 24 hours beyond the Arctic Circle towards the North Pole.</li><li>The length of day increases with increasing latitude North of the equator.</li></ol>`
                },
                { 
                    question: "<p>What are two motions of the Earth?</p>",
                    answer: `<p>The two motions of the Earth are rotation and revolution.</p>`
                },
                { 
                    question: "<p>What is an orbit?</p>",
                    answer: `<p>A definite path or a curved trajectory in which a planet revolves around the Sun is called its orbit.</p>`
                },
                { 
                    question: "<p>What is meant by the 'inclination of the Earth'?</p>",
                    answer: `<p>The axis on which the Earth rotates is tilted and makes an angle of 23 1⁄2° with the orbital plane. This is known as 'inclination of the Earth'.</p>`
                },
                { 
                    question: "<p>State two effects of West to East rotation of the Earth.</p>",
                    answer: `<ol><li>The rotation of earth from West to East determines the direction in which the Sun, Moon and stars appear to rise and set.</li><li>Ocean currents and winds are deflected towards right in the Northern Hemisphere and towards the left in the Southern Hemisphere. This is called Coriolis Effect.</li></ol>`
                },
                { 
                    question: "<p>Explain the term 'Equinox'. When does it occur?</p>",
                    answer: `<p>The time when the Sun crosses the plane of the Earth's Equator making length of the day equal to length of night all over the earth, is known as 'Equinox'. It occurs on March 21 and September 23.</p>`
                },
                { 
                    question: "<p>Earth’s Axis</p>",
                    answer: `<p>The axis on which the Earth rotates is called Earth's axis.</p>`
                },
                { 
                    question: "<p>Plane of Ecliptic</p>",
                    answer: `<p>The orbital plane of Earth around the Sun is called Plane of Ecliptic.</p>`
                },
                { 
                    question: "<p>Perihelion</p>",
                    answer: `<p>The Earth is said to be in perihelion when the distance between the Sun and the Earth is least or about 147 million km. It occurs on 3rd January.</p>`
                },
                { 
                    question: "<p>Aphelion</p>",
                    answer: `<p>The Earth is said to be in aphelion when the distance between the Sun and the Earth is maximum or about 152 million km. It occurs on July 4.</p>`
                },
                { 
                    question: "<p>Ferrell’s Law</p>",
                    answer: `<p>All the moving bodies on the Earth's surface like ocean currents and winds are deflected towards the right in the Northern Hemisphere and left in the Southern Hemisphere due to Coriolis Force created because of the rotation of Earth. This is called Ferrell’s Law.</p>`
                },
                { 
                    question: "<p>Rotation</p>",
                    answer: `<p>The movement of earth on its own axis is called Rotation.</p>`
                },
                { 
                    question: "<p>Solstice and Equinox</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Perihelion and Aphelion</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Rotation and Revolution</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Spring Equinox and Autumn Equinox</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Altitude of the Sun varies at a place according to seasons.</p>",
                    answer: `<p>Altitude of the Sun varies at a place according to seasons because when the Sun is overhead the Tropic of Cancer, the northern hemisphere experiences summer season and the southern hemisphere experiences winter season. When the Sun is overhead the Tropic of Capricorn, the northern hemisphere experiences winter season while the southern hemisphere experiences summer season.</p>`
                },
                { 
                    question: "<p>Seasons are reversed in Northern and Southern Hemisphere.</p>",
                    answer: `<p>Seasons are reversed in Northern and Southern Hemisphere because of inclination the Earth's axis. Because of its tilted position each hemisphere is inclined alternatively towards the Sun. The hemisphere that is near the Sun has summer and the hemisphere that is away from the Sun has winter.</p>`
                },
                { 
                    question: "<p>Days and nights are equal at all places on the Earth on March 21.</p>",
                    answer: `<p>Days and nights are equal at all places on the Earth on March 21 because the Sun is overhead at noon along the Equator. At this position, neither of the poles is tilted towards the sun.</p>`
                },
                { 
                    question: "<p>Winds get deflected from their normal path.</p>",
                    answer: `<p>Winds get deflected from their normal path because of the rotational movement of the Earth (Coriolis Effect). In Southern hemisphere the deflection is to the left while in Northern hemisphere the deflection is towards right.</p>`
                },
                { 
                    question: "<p>The region beyond Arctic Circle is known as the 'Land of Midnight Sun'.</p>",
                    answer: `<p>At the Arctic Circle (66 1⁄2°) during summer on June 21 the Sun never sets in mid-summer and there is daylight for 24 hours as North Pole is inclined towards the Sun. Therefore this region is known as the 'Land of Midnight Sun'.</p>`
                },
                { 
                    question: "<p>The speed of rotation at Singapore is much faster than that at Tokyo or Russia.</p>",
                    answer: `<p>Singapore is situated near the Equator and Tokyo and Russia are situated on higher latitudes. The speed of rotation at Singapore is much faster than that at Tokyo or Russia because as the latitude increases the speed of rotation decreases.</p>`
                },
                { 
                    question: "<p>Mid-day Sun can be seen overhead in Chennai twice a year, but not even once in Delhi. Why?</p>",
                    answer: `<p>Mid-day Sun can be seen overhead in Chennai twice a year, but not even once in Delhi because Delhi lies to the north of Tropic of Cancer which is the northern most limit of overhead Sun. Sun rays are never vertical beyond the tropics.</p>`
                },
                { 
                    question: "<p>The Poles experience six-month day and six-month night.</p>",
                    answer: `<p>The Poles experience six-month day and six-month night due to the inclination of the earth's axis. Because of its tilted position, each pole is inclined alternatively towards the Sun for six months resulting in continuous day and night, which last for 6 months.</p>`
                },
                { 
                    question: "<p>Noon is hotter than morning.</p>",
                    answer: `<p>Noon is hotter than morning because slanting rays of the sun are received in the morning. At noon, direct rays of the sun are received. Since slanting rays of the sun need to cover more distance than direct rays, they lose more heat.</p>`
                },
                { 
                    question: "<p>The variation in the lengths of day and night goes on increasing towards Poles.</p>",
                    answer: `<p>The variation in the lengths of day and night goes on increasing towards Poles because of the tilted axis of the Earth. As the latitude increases the variation in the lengths of night and days increases and finally at poles, there is continuous day or night for 6 months.</p>`
                },
                { 
                    question: "<p>The Sun is never overhead beyond the Tropics.</p>",
                    answer: `<p>The Tropic of Cancer in the Northern Hemisphere and Tropic of Capricorn in the Southern Hemisphere mark the limit of overhead sun. Beyond the Tropics, the Earth only receives slanting rays of the Sun because of the inclination of the Earth. Therefore the Sun is never overhead beyond the Tropics.</p>`
                },
                { 
                    question: "<p>The regions, near North Pole and South Pole, have six months of continuous day-light and darkness.</p>",
                    answer: `<p>The Poles experience six-month day and six-month night due to the inclination of the earth's axis. Because of its tilted position each pole is inclined alternatively towards the Sun for six months resulting in continuous day and night.</p>`
                },
                { 
                    question: "<p>Draw a labelled, self-explanatory diagram of the Summer Solstice in the Northern Hemisphere.</p>",
                    answer: `<p>Below diagram shows Summer Solstice in the Northern Hemisphere:</p>`
                },
                { 
                    question: "<p>Draw a diagram of the position of the Earth on December 22. Draw and name the Equator, Arctic Circle, and Antarctic Circle.</p>",
                    answer: `<p>Below diagram shows the position of the Earth on December 22 with Equator, Arctic Circle, and Antarctic Circle labelled:</p>`
                },
                { 
                    question: "<p>Draw a neat sketch to show the phenomenon of day and night.</p>",
                    answer: `<p>Below diagram shows the phenomenon of day and night:</p>`
                },
                { 
                    question: "<p>With the help of a diagram show how the tilt of the Earth's axis and the revolution causes (i) seasons: (ii) variation in the length of day and night.</p>",
                    answer: `<p>Below diagram shows the tilt of the Earth's axis and the revolution causing seasons and variation in the length of day and night:</p>`
                },
                { 
                    question: "<p>Draw self-explanatory diagram of Perihelion and Aphelion.</p>",
                    answer: `<p>Below diagram shows Perihelion and Aphelion:</p>`
                },
                { 
                    question: "<p>The Earth rotates on its axis from</p>",
                    answer: `<p>West to east</p>`
                },
                { 
                    question: "<p>Earth revolves around the sun on an ............... path.</p>",
                    answer: `<p>Elliptical</p>`
                },
                { 
                    question: "<p>One Sidereal Day is equal to ...............</p>",
                    answer: `<p>23 hr 56 minutes 4.09 sec</p>`
                },
                { 
                    question: "<p>Which of the following statements related to the coriolis force is correct?</p>",
                    answer: `<p>Coriolis force is generated due to earth’s rotation.</p>`
                },
                { 
                    question: "<p>Which of the following dates is known as Summer Solstice in Northern Hemisphere?</p>",
                    answer: `<p>21st June</p>`
                },
                { 
                    question: "<p>Which of the following dates is known as Winter Solstice in Northern Hemisphere?</p>",
                    answer: `<p>22nd December</p>`
                },
                { 
                    question: "<p>Which of the following dates is known as Autumnal Equinox in Northern Hemisphere?</p>",
                    answer: `<p>23rd September</p>`
                },
                { 
                    question: "<p>On which of the following dates Sun gives vertical ray on the Tropic of Capricorn?</p>",
                    answer: `<p>22nd December</p>`
                },
                { 
                    question: "<p>On which of the following dates is the day &amp; night equal?</p>",
                    answer: `<p>23rd September</p>`
                },
                { 
                    question: "<p>Identify the phenomena in Northern Hemisphere in this picture.</p>",
                    answer: `<p>Winter Solstice</p>`
                },
                { 
                    question: "<p>Which of the following country is known as 'the land of Midnight Sun'?</p>",
                    answer: `<p>Norway</p>`
                },
                { 
                    question: "<p>When earth is at its farthest point from the Sun, it is said to be,</p>",
                    answer: `<p>Aphelion</p>`
                },
            ]
        },
        { 
            title: "Earth's Structure",
            questions: [
                { 
                    question: "<p>What do you understand by lithosphere?</p>",
                    answer: `<p>Earth's outer shell of crystalline surface rock, ranging from 4 to 48 km in thickness from oceanic crust to mountain ranges is known as lithosphere.</p>`
                },
                { 
                    question: "<p>Name the two layers of lithosphere.</p>",
                    answer: `<ol><li>Sial</li><li>Sima</li></ol>`
                },
                { 
                    question: "<p>What do you understand by mantle?</p>",
                    answer: `<ol><li>the Upper Mantle or Asthenosphere</li><li>the Lower Mantle or the Mesosphere</li></ol>`
                },
                { 
                    question: "<p>What is the position of mantle in the Earth?</p>",
                    answer: `<p>The mantle layer lies between the crust and the core of earth. Its upper boundary is marked by Mohorovicic Discontinuity and its lower boundary is marked by the Gutenburg Discontinuity.</p>`
                },
                { 
                    question: "<p>What is core? By what other name is it known as?</p>",
                    answer: `<p>The central part of earth that probably consists of dense nickel and iron alloy (Nife) is known as core. It is also known as Barysphere. It's temperature is estimated to be about 2700°C.</p>`
                },
                { 
                    question: "<p>State the composition of the SIAL layer.</p>",
                    answer: `<p>SIAL layer consists of Silica and Aluminium.</p>`
                },
                { 
                    question: "<p>State the properties of the core of the Earth.</p>",
                    answer: `<ol><li>It consists of Nickel and Iron with a temperature estimated to be 2700°C.</li><li>The inner core also consists of nickel and iron and is forced into a solid state by increased pressure.</li></ol>`
                },
                { 
                    question: "<p>What is Earth’s crust composed of?</p>",
                    answer: `<p>The earth's crust consists of two layers - SIAL and SIMA.</p>`
                },
                { 
                    question: "<p>What does 'Nife' mean?</p>",
                    answer: `<p>'Nife' is the name given to the core of the Earth as it is rich in Nickel and Ferrous(iron) alloy.</p>`
                },
                { 
                    question: "<p>Why does the temperature increase as we go down(underground)?</p>",
                    answer: `<p>High pressure in the interior of the earth exercises a powerful influence on the temperature and physical state of the earth. The temperature of the earth's interior goes on increasing at the rate of 1°c for every 32 metres. Thus, the temperature increase as we go down(underground).</p>`
                },
                { 
                    question: "<p>How does Sial differ from Sima?</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Name the two most important and abundant chemical elements present in the Earth's crust.</p>",
                    answer: `<p>The two most important and abundant chemical elements present in the Earth's crust are Silica and Oxygen.</p>`
                },
                { 
                    question: "<p>What is meant by Mohorovicic Discontinuity?</p>",
                    answer: `<p>The contact zone of the Crust and Mantle is called Mohorovicic Discontinuity.</p>`
                },
                { 
                    question: "<p>What is mantle composed of?</p>",
                    answer: `<p>The Mantle layer is composed of magnesium and iron silicates, along with substantial quantities of sulphides in the upper mantle and nickel and iron in the lower mantle.</p>`
                },
                { 
                    question: "<p>Crust</p>",
                    answer: `<p>Earth's outer shell of crystalline surface rock, ranging from 4 to 48 km in thickness from oceanic crust to mountain ranges is called Crust.</p>`
                },
                { 
                    question: "<p>Mantle</p>",
                    answer: `<p>The layer of Iron and Magnesium silicate rock and nickel and iron alloy between the crust and the outer core with depth varying from 35 to 2900 km is called Mantle.</p>`
                },
                { 
                    question: "<p>Core</p>",
                    answer: `<p>The central part of earth that probably consists of dense nickel and iron alloy is known as core.</p>`
                },
                { 
                    question: "<p>Nife</p>",
                    answer: `<p>The core of earth consists of nickel and iron alloy and is known as Nife.</p>`
                },
                { 
                    question: "<p>Crust and Core</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Sial and Sima</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Draw a self-explanatory diagram showing the composition and structure of the Earth.</p>",
                    answer: `<p>Below diagram shows the composition and structure of the Earth:</p>`
                },
                { 
                    question: "<p>What is the rate of change of temperature inside the earth?</p>",
                    answer: `<p>Temperature increases at the rate of 1°C for every 32 metres of descent.</p>`
                },
                { 
                    question: "<p>Sial is the part of ............... .</p>",
                    answer: `<p>Crust</p>`
                },
                { 
                    question: "<p>Sima is composed of ............... &amp; ...............</p>",
                    answer: `<p>Silica &amp; Magnesium</p>`
                },
                { 
                    question: "<p>Gutenburg Discontinuity separates ............... &amp; ...............</p>",
                    answer: `<p>Core &amp; Mantle</p>`
                },
                { 
                    question: "<p>Mesosphere is another name of ............... .</p>",
                    answer: `<p>Mantle</p>`
                },
                { 
                    question: "<p>Asthenosphere remains in ............... state.</p>",
                    answer: `<p>Partially Molten</p>`
                },
                { 
                    question: "<p>Gutenburg Discontinuity is placed at ............... km depth from the surface.</p>",
                    answer: `<p>2900</p>`
                },
                { 
                    question: "<p>Which of the following layer is also called Barysphere?</p>",
                    answer: `<p>Core</p>`
                },
                { 
                    question: "<p>Inner core is solid due to</p>",
                    answer: `<p>high pressure</p>`
                },
                { 
                    question: "<p>Which of the following rocks and mineral composition is correct for Continental crust?</p>",
                    answer: `<p>Granite, Si, Al.</p>`
                },
                { 
                    question: "<p>The average density of earth expressed in g/cm<sup>3</sup> is</p>",
                    answer: `<p>5.5</p>`
                },
                { 
                    question: "<p>Which of the following discontinuity is found between crust &amp; mantle?</p>",
                    answer: `<p>Mohorovicic</p>`
                },
            ]
        },
        { 
            title: "Landforms of the Earth",
            questions: [
                { 
                    question: "<p>What are plains?</p>",
                    answer: `<p>Extensive flat areas of low altitude, a gently and undulating terrain without prominent hills or depressions are known as plains. For example, the Indo-Gangetic plains of North India.</p>`
                },
                { 
                    question: "<p>What is orogenesis?</p>",
                    answer: `<p>The process of mountain building on large scale leading to the formation of the intensely deformed belts which constitute mountain ranges is called orogenesis.</p>`
                },
                { 
                    question: "<p>Name the different types of mountains. Describe each of them with examples.</p>",
                    answer: `<ol><li><strong>Fold mountains</strong> — The fold mountains are formed by horizontal movements of the crust resulting in wrinkle like folds that vary greatly in size. The folds have arched or upraised parts known as anticlines and troughs known as synclines. Based on the period of their formation they are of two types — young fold mountains like the Himalayas and old fold mountains like the Aravalis.</li><li><strong>Block mountains</strong> — Sometimes movement of earth's crust causes cracks or faults, where such movement leaves a block of higher land standing between two areas of lower land, the highland is known as a 'Block Mountain' or Horst. For example — the Vosges and Black forest mountains, Satpura and Vindhya mountains in India.</li><li><strong>Residual mountains</strong> — When an area of highland remains standing above the general level after rivers and other natural agents have lowered the surface of the surrounding area, the term residual mountain is used. Sometimes such highlands are called mountains of denudation. For example — Helvellyn Residual Mountain</li></ol>`
                },
                { 
                    question: "<p>What are plateaus?</p>",
                    answer: `<p>Plateau is an elevated tract of relatively flat land, usually limited on at least one side by a steep slope falling abruptly to lower land. They are results of diastrophism (large scale deformation of the Earth's crust). They have been modified by various agents of erosion and in many cases by volcanism and minor Earth movements.</p>`
                },
                { 
                    question: "<p>Name the various types of plateaus with examples.</p>",
                    answer: `<ol><li>Intermontane Plateaus — Plateau of Tibet, The Mexican Plateau.</li><li>Volcanic Plateaus — Deccan Plateau, South African Plateau.</li></ol>`
                },
                { 
                    question: "<p>State a few advantages of plains.</p>",
                    answer: `<ol><li>Construction of settlements, rails, roads, etc. is easy on flat and soft surface.</li><li>Fertile soil of alluvial plains are granaries of the world. In arid areas, irrigation can be provided easily and economically.</li><li>Most of the big cities are situated in plains as most of the plains provide fine climate and fertile land. Movement is easy which encourages agricultural and industrial development.</li></ol>`
                },
                { 
                    question: "<p>Explain the types of depositional plains.</p>",
                    answer: `<ol><li><strong>Alluvial plains</strong> — These are formed by gradual accumulation of silt brought down by rivers. These deposits may be fan shaped known as Piedmont alluvial fan. When the river widens its valley or overflows its banks during floods, the clay and sand deposit to make flood plains. Examples are Indo-Gangetic plains, Plains of northern China and Mississippi plain. At the mouth of a river, triangular shaped plain of deposited material is called delta plain.</li><li><strong>Glacial deposits</strong> — When the glaciers melt, the debris are deposited. Such plains are extensive in size and very fertile. They have irregular surface. For example, Plains of Central North America and Western European plain.</li><li><strong>Aeolion Loess Plains or deposition by winds</strong> — These plains are formed by the materials which have been transported or deposited by winds. For example, Loess deposits of North China.</li><li><strong>Lacustrine plains or river deposits</strong> — These plains result from the fillings of lake beds. When rivers enter a lake, they are muddy. The plains of Hungary in Europe and the plains of surrounding great lakes of North America are examples.</li></ol>`
                },
                { 
                    question: "<p>State the main uses of mountains.</p>",
                    answer: `<ol><li>Mountains are effective climatic barriers.</li><li>The swift streams of mountains are frequently sources of hydro-electric power.</li><li>They have deposits of minerals. They provide gold, silver, copper, lead etc.</li><li>They provide scope for lumbering industries.</li><li>Agriculture has been developed on the slopes of mountains.</li><li>They provide pastures.</li><li>They act as natural barriers or borders between two countries.</li></ol>`
                },
                { 
                    question: "<p>Differentiate between intermontane plateau and volcanic plateaus.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Define a plain. How does it differ from a plateau?</p>",
                    answer: `<table><thead><tr><th>S.No.</th><th>Plains</th><th>Plateaus</th></tr></thead><tbody><tr><td>1.</td><td>They are low lying area.</td><td>They are relatively high areas.</td></tr><tr><td>2.</td><td>They are fertile.</td><td>They are relatively less fertile.</td></tr><tr><td>3.</td><td>They have gradual slope.</td><td>They have steep and abrupt slope.</td></tr><tr><td>4.</td><td>They have less mineral deposits.</td><td>They are rich in mineral deposits.</td></tr><tr><td>5.</td><td>Example, Gangetic Plains of Northern India</td><td>Example, Deccan Plateau of India</td></tr></tbody></table>`
                },
                { 
                    question: "<p>What are mountains?</p>",
                    answer: `<p>A portion of land surface rising considerably above the surrounding area, either as a single eminence, (generally above 600 mts), or as an extensive chain are called mountains.</p>`
                },
                { 
                    question: "<p>State any two characteristics of young fold mountains.</p>",
                    answer: `<ol><li>Ruggedness of relief and rounded contours of mountain areas.</li><li>Most of the active volcanoes are found in their neighbourhood.</li></ol>`
                },
                { 
                    question: "<p>How are the young fold mountains useful? State one example.</p>",
                    answer: `<p>Young fold mountains are useful as they act as climatic barriers. For example, the Himalayas prevent the cold Siberian winds from entering the Indian sub-continent. Also, these mountains provide us swift rivers to harness hydroelectricity, make perfect tourist destination and they are rich in mineral deposits.</p>`
                },
                { 
                    question: "<p>How are alluvial plains formed?</p>",
                    answer: `<p>Alluvial plains are formed by gradual accumulation of silt brought down by rivers. As a river leaves the mountains, the water spreads out and fast speed is arrested. The sluggish water deposits the material brought by it forming the plains. They mostly deposit the eroded materials along the foot of hills forming Piedmont Alluvial plain, like Bhabhar in India. Plains are also formed by the flooded rivers and are known as flood plains.</p>`
                },
                { 
                    question: "<p>Give an example each of the following :</p>",
                    answer: `<ol><li>Fold mountains</li><li>Block mountain</li><li>Volcanic plateau</li><li>Structural plain</li></ol>`
                },
                { 
                    question: "<p>Intermontane plateaus</p>",
                    answer: `<p>The plateaus that are surrounded by high hills and mountains on all sides are called intermontane plateaus. For example — Tibetan plateau.</p>`
                },
                { 
                    question: "<p>Block Mountains</p>",
                    answer: `<p>The mountains that are formed as a result of cracking of earth's crust or faulting, when there is deep submergence of a large landmass leaving behind raised platform, are called Block mountains. For example, the Vosges.</p>`
                },
                { 
                    question: "<p>Draw a neat diagram to show the formation of Block Mountains.</p>",
                    answer: `<p>Below diagram shows the formation of Block Mountains:</p>`
                },
                { 
                    question: "<p>With the help of a neat diagram show the formation of a floodplain.</p>",
                    answer: `<p>Below diagram shows the formation of a floodplain:</p>`
                },
                { 
                    question: "<p>Plateaus are called tablelands.</p>",
                    answer: `<p>As they have flat top with steep sided slope.</p>`
                },
                { 
                    question: "<p>Residual mountains are called the mountains of denudation.</p>",
                    answer: `<p>As these mountains are formed when surrounding area of a highland is lowered down by the long term denudation and erosion by various agents of gradation.</p>`
                },
                { 
                    question: "<p>The alluvial plains are called the granaries of the world.</p>",
                    answer: `<p>Alluvial plains are formed by the deposition of alluvium by the rivers; therefore they have fertile soil which enhances the cultivation of grains.</p>`
                },
                { 
                    question: "<p>The plateau of Tibet is called the intermontane plateau.</p>",
                    answer: `<p>As Tibet is enclosed by Kunlun Mountain from the north &amp; Himalayas from the south.</p>`
                },
                { 
                    question: "<p>The old fold mountains are much lower and less rugged than young fold mountains.</p>",
                    answer: `<p>The old fold mountains have been subjected to the forces of denudation (such as weather, rivers, glaciers, etc.) for long geological periods. Due to this, they are much lower and less rugged than young fold mountains.</p>`
                },
                { 
                    question: "<p>North western Deccan is an example of Lava plateau.</p>",
                    answer: `<p>The north western Deccan is an example of a lava plateau because it was formed by extensive volcanic activity that deposited large amounts of basaltic lava over the area millions of years ago, resulting in a broad, flat plateau with distinctive basaltic rock formations.</p>`
                },
                { 
                    question: "<p>The systematic study of the earth’s relief features is called</p>",
                    answer: `<p>Geomorphology</p>`
                },
                { 
                    question: "<p>The Vosges &amp; Black Forest Mountains are the examples of</p>",
                    answer: `<p>Block Mountain</p>`
                },
                { 
                    question: "<p>Which of the following mountains is called the mountain of denudation?</p>",
                    answer: `<p>Residual Mountain</p>`
                },
                { 
                    question: "<p>Identify the type of mountain in this Image marked as a, b, c.</p>",
                    answer: `<p>Block Mountain</p>`
                },
                { 
                    question: "<p>Which of the following is an example of Young Fold Mountain?</p>",
                    answer: `<p>Alps</p>`
                },
                { 
                    question: "<p>Which of the following is an example of intermontane plateau?</p>",
                    answer: `<p>Bolivian Plateau</p>`
                },
                { 
                    question: "<p>Which of the following is an example of structural plain?</p>",
                    answer: `<p>Great Plains of USA</p>`
                },
                { 
                    question: "<p>The plain which is formed at the foothills of mountain in the shape of fan is called</p>",
                    answer: `<p>Piedmont alluvial plain</p>`
                },
                { 
                    question: "<p>Refer to the image &amp; identity the relief feature.</p>",
                    answer: `<p>intermontane plateau</p>`
                },
                { 
                    question: "<p>Which of the following relief features is formed by the squeezing &amp; wrapping of the sediments of the crust?</p>",
                    answer: `<p>Fold Mountain</p>`
                },
                { 
                    question: "<p>The plain which is formed as a result of emergence of sea bottom near coast is called</p>",
                    answer: `<p>Structural plain</p>`
                },
                { 
                    question: "<p>Orogenesis is the process of</p>",
                    answer: `<p>Mountain building</p>`
                },
            ]
        },
        { 
            title: "Rocks and Rock Cycle",
            questions: [
                { 
                    question: "<p>Describe how Sedimentary Rocks and Igneous Rocks are formed. Give two examples of each.</p>",
                    answer: `<p>The Sedimentary rocks are formed from material derived from the pre-existing rocks and from organic sources by the process of denudation. The eroded materials brought by rivers, streams, glaciers or wind are deposited in layers to form Sedimentary rocks. For Example — Limestone, Sandstone.</p>`
                },
                { 
                    question: "<p>On the basis of occurrence, classify Igneous Rocks.</p>",
                    answer: `<ol><li><strong>Intrusive Igneous Rocks</strong> — The rocks that are formed due to solidification of magma below the Earth's surface and remains surrounded by pre-existing rock are called Intrusive Igneous Rocks.</li><li><strong>Extrusive Igneous Rocks</strong> — The rocks that are formed due to solidification of magma above the Earth's surface are called Extrusive Igneous Rocks.</li></ol>`
                },
                { 
                    question: "<p>Name the three rock forming minerals.</p>",
                    answer: `<p>Silicates, Quartz and Feldspars are three rock forming minerals.</p>`
                },
                { 
                    question: "<p>What are Batholiths?</p>",
                    answer: `<p>Large mass of igneous rocks (chiefly composed of Granite) covering about 100 sq km area is called Batholiths. Its floor is not visible.</p>`
                },
                { 
                    question: "<p>What is Metamorphism? What are its causes?</p>",
                    answer: `<ol><li>volcanic eruptions</li><li>the mountain building movements</li><li>endogenetic forces</li><li>contact with undergoing water</li></ol>`
                },
                { 
                    question: "<p>Name a few important characteristics of Sedimentary Rocks.</p>",
                    answer: `<ol><li>The material of sedimentary rock is derived from other pre-existing rocks.</li><li>They are layered rocks.</li><li>Sediments are deposited in sequential order i.e. size of sediments decrease from littoral margins to the centre of the water bodies.</li><li>Most of the sedimentary rocks are permeable and porous.</li><li>They contain fossils.</li></ol>`
                },
                { 
                    question: "<p>Name the various types of Metamorphic Rocks.</p>",
                    answer: `<p>The various types of metamorphic rocks are slate, schist, gneiss, quartzite, marble, amphibolite, meta-conglomerate and hornfel.</p>`
                },
                { 
                    question: "<p>Name the agents involved in the formation of sedimentary rocks.</p>",
                    answer: `<p>The agents involved in the formation of sedimentary rocks are rivers, lakes, sea, glaciers, wind and organic materials. The processes of erosion, weathering, dissolution and lithification lead to formation of sedimentary rocks.</p>`
                },
                { 
                    question: "<p>Give examples of metamorphic rocks formed from igneous and sedimentary rocks.</p>",
                    answer: `<p>Metamorphic rocks formed from igneous rocks — Gniess from granite, Schist from Basalt</p>`
                },
                { 
                    question: "<p>What are Sills and Dykes?</p>",
                    answer: `<p><strong>Sills</strong>— It is sheet like body of igneous rock. It is formed when magma forces its way between two layers of sedimentary rocks and cools and solidifies there.</p>`
                },
                { 
                    question: "<p>Why are sedimentary rocks also called stratified rocks?</p>",
                    answer: `<p>Sedimentary rocks are formed by deposition of sediments in layers in beds of water bodies or on earth's surface (if brought by wind). They are deposited in layers, therefore, they are known as stratified rocks and layers are called strata.</p>`
                },
                { 
                    question: "<p>What processes are involved in the formation of igneous rocks?</p>",
                    answer: `<p>The processes involved in the formation of igneous rocks are cooling, solidification and crystallization.</p>`
                },
                { 
                    question: "<p>What is a Rock Cycle?</p>",
                    answer: `<p>A cyclic process of changing the form of rocks is called Rock cycle. It represents the interrelationships among the three rock-forming processes: igneous, sedimentary and metamorphic.</p>`
                },
                { 
                    question: "<p>What are the rocks?</p>",
                    answer: `<p>An assemblage of minerals bound together that form a more or less definite unit of the Earth's crust is called rocks.</p>`
                },
                { 
                    question: "<p>Classify the rocks on the basis of their origin.</p>",
                    answer: `<ol><li>Igneous rocks</li><li>Sedimentary rocks</li><li>Metamorphic rocks</li></ol>`
                },
                { 
                    question: "<p>What is meant by intrusive rocks?</p>",
                    answer: `<p>Magma that solidifies below the Earth's surface and remains surrounded by the pre-existing rock is called intrusive rocks. For example, granite.</p>`
                },
                { 
                    question: "<p>What are extrusive rocks?</p>",
                    answer: `<p>The rocks that are formed due to solidification of magma on the Earth's surface are called Extrusive Igneous Rocks. For example, Obsidian.</p>`
                },
                { 
                    question: "<p>How important are rocks for industrial development?</p>",
                    answer: `<ol><li>Rocks contain minerals called ores which are used for extracting metals and non-metals.</li><li>They provide fossil fuels like coal and petroleum.</li><li>The entire automobile industry uses petrol for their transport system.</li><li>They provide raw material for manufacturing industries like limestone and gypsum are needed by cement industry.</li><li>They are used as building materials. For example, granite and marble.</li></ol>`
                },
                { 
                    question: "<p>Minerals</p>",
                    answer: `<p>Minerals are naturally occuring, homogenous solid inorganic substance that has a definite chemical composition with its own chemical and physical properties.</p>`
                },
                { 
                    question: "<p>Rocks</p>",
                    answer: `<p>Rock can be defined as an aggregate of minerals that form a more or less definite unit of the earth's crust.</p>`
                },
                { 
                    question: "<p>Sills</p>",
                    answer: `<p>A sheet like body of igneous rock that is formed when magma forces its way between two layers of sedimentary rocks and cools and solidifies there is called Sills.</p>`
                },
                { 
                    question: "<p>Dykes</p>",
                    answer: `<p>A sheet like body of intrusive igneous rock which is formed when magma is forced upwards, it fills vertical cracks or fissures in existing rock is called Dykes.</p>`
                },
                { 
                    question: "<p>Conglomerate</p>",
                    answer: `<p>Conglomerate is a type of mechanically formed sedimentary rock which consists of rounded or sub-rounded fragments, usually water-born cobbles, pebble and gravel, cemented together by a matrix of calcium carbonate, silica, etc.</p>`
                },
                { 
                    question: "<p>Rocks and Minerals</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Volcanic rocks and plutonic rocks</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Thermal and Dynamic Metamorphism</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Igneous rocks are also called primary rocks.</p>",
                    answer: `<p>Igneous rocks are also called primary rocks because they are formed when molten lava solidifies. Sedimentary rocks and metamorphic rocks are formed from other rocks.</p>`
                },
                { 
                    question: "<p>Extrusive igneous rocks have generally small crystals.</p>",
                    answer: `<p>Extrusive igneous rocks have generally small crystals because they cool rapidly to solidify. The crystals do not have much time to form until the rock cools all the way, which stops the crystal growth.</p>`
                },
                { 
                    question: "<p>Rocks are of great economic significance.</p>",
                    answer: `<p>Rocks are of great economic significance because they provide us with various minerals, fossil fuels, building materials and precious metals etc.</p>`
                },
                { 
                    question: "<p>Lithosphere is called rocksphere</p>",
                    answer: `<p>The Lithosphere is the outermost layer of the Earth's crust that is made up of solid rocks, including igneous and sedimentary rocks. In Greek, <em>Litho</em> means <em>rock</em> or <em>stone</em>. Thus, Lithosphere is also called rocksphere.</p>`
                },
                { 
                    question: "<p>No fossil is found in metamorphic rock.</p>",
                    answer: `<p>Metamorphic rocks are formed due to change in texture and mineral composition of the pre-existing rocks. During this process of change, the fossils of original sedimentary rocks are destroyed and therefore no fossil is found in metamorphic rocks.</p>`
                },
                { 
                    question: "<p>Draw a diagram of a volcano and igneous rocks.</p>",
                    answer: `<p>Below diagram shows a volcano and igneous rocks:</p>`
                },
                { 
                    question: "<p>Draw a neat diagram to show the rock cycle.</p>",
                    answer: `<p>Below diagram shows the rock cycle:</p>`
                },
                { 
                    question: "<p>Which of the following rocks is also called parent rock?</p>",
                    answer: `<p>Igneous Rock</p>`
                },
                { 
                    question: "<p>Which type of rock is Granite?</p>",
                    answer: `<p>Intrusive Igneous Rock</p>`
                },
                { 
                    question: "<p>Which of the following statements related to extrusive igneous rock is correct?</p>",
                    answer: `<p>They have fine crystals</p>`
                },
                { 
                    question: "<p>Which of the following is the metamorphosed form of Sandstone?</p>",
                    answer: `<p>Quartzite</p>`
                },
                { 
                    question: "<p>Which of the following rocks consists of rounded; fragments, formed by cementation process?</p>",
                    answer: `<p>Conglomerates</p>`
                },
                { 
                    question: "<p>Sedimentary rocks are called stratified rocks as</p>",
                    answer: `<p>They are deposited in layers</p>`
                },
                { 
                    question: "<p>Which of the following statements is correct?</p>",
                    answer: `<p>Sedimentary rocks have pore spaces.</p>`
                },
                { 
                    question: "<p>Which type of rock is chalk?</p>",
                    answer: `<p>Calcareous rocks</p>`
                },
                { 
                    question: "<p>Which factor is responsible for the formation metamorphic rocks?</p>",
                    answer: `<p>Heat &amp; pressure</p>`
                },
                { 
                    question: "<p>Which of following is the mechanically formed sedimentary rock?</p>",
                    answer: `<p>Sandstone</p>`
                },
                { 
                    question: "<p>The cyclic process of changing the form of rocks is called</p>",
                    answer: `<p>Rock cycle</p>`
                },
                { 
                    question: "<p>Which of the following is the example of metamorphic rock?</p>",
                    answer: `<p>Gneiss</p>`
                },
                { 
                    question: "<p>Which of the following process is responsible for the formation of igneous rocks?</p>",
                    answer: `<p>Cooling &amp; solidification of magma</p>`
                },
            ]
        },
        { 
            title: "Volcanoes",
            questions: [
                { 
                    question: "<p>What are volcanoes?</p>",
                    answer: `<p>A volcano is a vent or opening in the crust of the Earth, connected by a conduit to an underlying magma chamber from which molten lava, volcanic gases and steam are ejected. It is usually in the form of cone or dome according to the material ejected.</p>`
                },
                { 
                    question: "<p>How are volcanoes caused?</p>",
                    answer: `<ol><li>The temperature inside the earth's crust increases with increase in depth at the rate of 1°C per 32 metres. The high temperature and pressure inside the earth crust, which increases with increase in depth is responsible for instability. Molten magma under great pressure has the capacity to liquefy great volume of gases.</li><li>The surface water that percolates through the cracks and crevices, turns into steam due to hot rocks. The steam being very powerful, forces itself through the weak points of the earth crust along with molten material.</li><li>The plate tectonics or the movement and splitting of the major and minor plates of the earth cause cracks, crevices and fissures in the earth's crust through which the magma is poured out.</li></ol>`
                },
                { 
                    question: "<p>How can volcanoes be classified on the basis of their activity?</p>",
                    answer: `<ol><li><strong>Active volcanoes</strong> — They constantly eject lava, gases and ash. For example- Sabancaya in Peru.</li><li><strong>Dormant volcanoes</strong> — They remain dormant but can suddenly erupt and again go dormant. For example- The Vesuvius volcano in Italy.</li><li><strong>Extinct volcanoes</strong> — They are volcanoes where there has been no volcanic activity since long. Their crater usually developes into a lake. For example- For example- Arthur's seat at Edinburgh in Scotland.</li></ol>`
                },
                { 
                    question: "<p>Name the four major types of volcanic eruptions.</p>",
                    answer: `<ol><li>Ash and cinder cones</li><li>Lava Dome</li><li>Lava Shield</li><li>Composite or Strato cone</li></ol>`
                },
                { 
                    question: "<p>What is volcanicity?</p>",
                    answer: `<p>Volcanicity is a process which involves the intrusion of magma in the Earth's crust or the extrusion of such molten material on the Earth's surface. It gives rise to volcanic eruptions.</p>`
                },
                { 
                    question: "<p>What are the products of volcanic eruption?</p>",
                    answer: `<p>The volcanic eruption ejects molten lava, volcanic gases(mostly sulphur dioxide), ashes, cinder, pumice, steam etc.</p>`
                },
                { 
                    question: "<p>What is a crater?</p>",
                    answer: `<p>An abrupt circular depression formed by extrusion of volcanic material, by collapse or by impact of a meteorite is called crater. For example, sunset crater, Arizona.</p>`
                },
                { 
                    question: "<p>Name any four major types of landforms associated with volcanoes.</p>",
                    answer: `<ol><li>Volcanic Plateaus — The erupted lava spreads to form plateau. For example- Columbian plateau.</li><li>Volcanic Mountains — These mountains are formed from material ejected from the fissures of the Earth's crust. For Example- Mauna Loa in Hawaii.</li><li>Volcanic Plains — Sometimes lava forms a very fertile plain. For example- Plain in Java, Malwa region in Deccan plateau.</li><li>Caldera Lake — The crater forms large lakes that may serve as great source of perennial rivers. For example- Lake Nayos in South Africa.</li></ol>`
                },
                { 
                    question: "<p>State three beneficial effects of volcanoes.</p>",
                    answer: `<ol><li>They lead to formation of geysers and springs. These are important from health and medical point of view.</li><li>The crater lakes serve as great source of perennial rivers. For example, Lake Nyos in South Africa.</li><li>Precious stones are found in volcanoes. For example, diamonds in South Africa.</li></ol>`
                },
                { 
                    question: "<p>Mention three adverse effects of volcanoes.</p>",
                    answer: `<ol><li>They cause extreme destruction of forests, cities and lives in large area.</li><li>Volcanoes eject poisonous gases that cause pollution and health problems.</li><li>The volcanic area becomes inhabitable by man. The rain water is immediately percolated due to high porosity.</li></ol>`
                },
                { 
                    question: "<p>Describe the distribution of volcanoes.</p>",
                    answer: `<ol><li><strong>The Circum-Pacific Belt (Ring of Fire)</strong> — It extends through the Andes of South America, Central America, Mexico, the mountains of western U.S.A, the Aleutian Islands, Kamchatka, the Kurile Islands, Sakhalin, Japan, Philippines, Celebes, New guinea, the Solomon Islands, New Caladonia and New Zealand.</li><li><strong>Mid-Atlantic</strong>— This belt includes the volcanoes of Mid-Atlantic ridge. It also includes the volcanoes of Lesser Antilles, Southern Antilles, Azores, St. Helena, etc.</li><li><strong>Mid-Continental</strong>— This belt includes the Volcanoes of Alps Mountains, Mediterranean Sea (Stromboli, Vesuvius, Etna, etc.) and volcanoes of Aegean Sea, Mt. Ararat (Turkey), Elbrus, Hindukush and the Himalayas.</li></ol>`
                },
                { 
                    question: "<p>Explain in brief, how a volcanic eruption is caused.</p>",
                    answer: `<p>The temperature inside the Earth increases at the rate of 1° per 32 metres of descent. It results in very high temperature as well as extreme pressure deep inside the Earth. The molten lava inside the Earth, turns surface water (which percolates through the cracks and crevices to the interior) into steam. This steam and other compressed gases put pressure on the Earth's crust. The movement of Tectonic plates cause cracks, crevices and fissures in the Earth's crust through which the magma is poured out.</p>`
                },
                { 
                    question: "<p>Classify volcanoes on the basis of their intensity of eruption.</p>",
                    answer: `<ol><li><strong>Active volcanoes</strong> — They constantly eject lava, gases and ash. For example- Sabancaya in Peru.</li><li><strong>Dormant volcanoes</strong> — They remain dormant but can suddenly erupt and again go dormant. For example- The Vesuvius volcano in Italy.</li><li><strong>Extinct volcanoes</strong> — They are volcanoes where there has been no volcanic activity since long. Their crater usually developes into a lake. For example- For example- Arthur's seat at Edinburgh in Scotland.</li></ol>`
                },
                { 
                    question: "<p>Where do volcanic eruptions generally occur? Give one example.</p>",
                    answer: `<p>Volcanic eruptions generally occur in the weaker zone of the earth crust and are characterized by young fold mountains. For example - the Andes mountains are young mountains which are part of 'Ring of Fire'.</p>`
                },
                { 
                    question: "<p>Give one example of an active volcano.</p>",
                    answer: `<p>Sabancaya in Peru in South America is an example of an active volcano.</p>`
                },
                { 
                    question: "<p>Caldera</p>",
                    answer: `<p>It is a large, more or less circular depression or basin associated with a volcanic vent. Its diameter is many times greater than that of the included vents. Calderas are believed to result from subsidence or collapse and may or may not be related to explosive eruptions.</p>`
                },
                { 
                    question: "<p>Ring of Fire</p>",
                    answer: `<p>The Circum-Pacific belt of volcanoes is called as Ring of Fire. It is called so because the belt has numerous active volcanoes. It is the chain of volcanoes making up mountain belts and island arcs surrounding the pacific ocean basin.</p>`
                },
                { 
                    question: "<p>Magma</p>",
                    answer: `<p>A mobile silicate melt coming out at the time of volcanic eruption, which can contain suspended crystals and dissolved gases as well as liquid is known as magma. It is formed due to great pressure and temperature inside the Earth's crust. It contains a mixture of minerals, water vapour, sulphur and dissolved gases.</p>`
                },
                { 
                    question: "<p>Lava</p>",
                    answer: `<p>The magma that reaches the Earth's surface due to volcanic eruption is called lava. The molten lava solidifies as it reaches the surface of earth forming different structures.</p>`
                },
                { 
                    question: "<p>Lopoliths and Phacoliths</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Crater and Caldera</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Active and Extinct Volcano</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Draw a labelled self-explanatory diagram of a volcano.</p>",
                    answer: `<p>Labelled self-explanatory diagram of a volcano is given below:</p>`
                },
                { 
                    question: "<p>Earthquakes are closely associated with volcanic belts.</p>",
                    answer: `<p>Earthquakes are closely associated with volcanic belts because volcanic belts are usually situated around tectonic plates. Movement of tectonic plates results in earthquakes and also causes cracks, crevices and fissures in the Earth's crust through which the magma is poured out. Thus, an earthquake may trigger a volcano and vice versa.</p>`
                },
                { 
                    question: "<p>Lava activity is profitable to man.</p>",
                    answer: `<ol><li>Lava forms plains, which are very fertile. For example- plains in Java, Malwa region in Deccan plateau and parts of Brazil.</li><li>Lava activity also provides us precious stones, for example, diamonds in South Africa and nickel deposits of Sudbury in Canada.</li><li>Geysers and springs are formed due volcanic activity and are very useful from health point of view as they contain sulphur and other useful minerals.</li><li>The crater lakes are formed as result of volcanic eruption. They may serve as source of perennial rivers.</li><li>Volcanic spots become tourist spots.</li><li>Sometimes remnants of volcanoes after erosion help in preserving traces of old civilization.</li></ol>`
                },
                { 
                    question: "<p>The Circum-Pacific Belt is also called the 'Ring of Fire'.</p>",
                    answer: `<p>The Circum-Pacific Belt is also called the 'Ring of Fire' because this belt consist of numerous active volcanoes which keep erupting.</p>`
                },
                { 
                    question: "<p>Volcanos have constructive effects in favour of man.</p>",
                    answer: `<ol><li>Lava from plains are very fertile. For example- plains in Java, Malwa region in Deccan plateau and parts of Brazil.</li><li>Lava activity also provides us precious stones.</li><li>Geyser and springs are formed that provide water with health benefits.</li><li>Crater lakes are formed which serve as source of water to perennial rivers. For example-Lake Nyos.</li><li>Sometimes remnants of volcanoes after erosion helps in preserving traces of old civilization.</li></ol>`
                },
                { 
                    question: "<p>Mt. Vesuvius in Italy is a dormant volcano.</p>",
                    answer: `<p>Mt. Vesuvius in Italy is a dormant volcano because it first erupted in 79 AD. After that it remained dormant for over 1500 years and then suddenly erupted with great force in 1631 AD. Since then, it has erupted about 7 times with the last eruption seen in 1944.</p>`
                },
                { 
                    question: "<p>Identify the parts of volcano which are marked as 5 &amp; 7</p>",
                    answer: `<p>Vent &amp; caldera</p>`
                },
                { 
                    question: "<p>Hawaiian volcanoes are the excellent examples of</p>",
                    answer: `<p>Lava Shields</p>`
                },
                { 
                    question: "<p>Which of the following is an extinct volcano?</p>",
                    answer: `<p>Arthur’s Seat</p>`
                },
                { 
                    question: "<p>Which of the following belts of volcano is also known as the 'Ring of Fire'?</p>",
                    answer: `<p>Circum-Pacific belt</p>`
                },
                { 
                    question: "<p>It is an intrusive volcanic landform which is formed when the lava solidifies between the horizontal layers of parent rocks.</p>",
                    answer: `<p>Sills</p>`
                },
            ]
        },
        { 
            title: "Earthquakes",
            questions: [
                { 
                    question: "<p>Describe the distribution of Earthquakes in the world.</p>",
                    answer: `<ol><li>The Circum-Pacific Belt (Convergent Plate Boundaries) — It extends in west from Alaska to Kurile, Japan, Mariana and the Philippine trenches. Its one branch goes towards the Indonesian trench and the other goes towards the Kermadec-Tonga trench to the north-west of New Zealand. On the eastern side of the Pacific the earthquake zone follows the west coast of North America and continues southward along Pem and Chile trench on the west coast of South America.</li><li>The Mid-Atlantic Belt (Divergent Plate Boundaries) — This belt of earthquakes extends along the mid-oceanic ridges and several islands near the ridges of Atlantic Ocean. Earthquakes of moderate to mild intensity with shallow focus are recorded in this belt. The Rift Valley of East Africa and the Red Sea are considered as an extension of this belt.</li><li>The Mid-Continental Belt — This belt extends along the Alpine mountain system of Europe, Rocky mountains in North America, through Asia Minor, Caucasia, Iran, Afghanistan and Pakistan to the Himalayan mountain system including Tibet, the Pamir, Tien-Shan, Altai and the mountains of China, Myanmar and eastern Siberia. This zone is characterised by larger earthquakes of shallow origin and some of intermediate origin.</li></ol>`
                },
                { 
                    question: "<p>Name the instrument used to measure an earthquake.</p>",
                    answer: `<p>The instrument used to measure an earthquake is Seismograph or Seismometer.</p>`
                },
                { 
                    question: "<p>Give some examples of earthquakes of the world.</p>",
                    answer: `<ol><li>May 31, 1970 in Northern Peru</li><li>January 26, 2001 in Gujarat, India</li><li>July 28, 1976 in Tangshan (China)</li><li>December 26, 2004 in South Asia, off the West Coast of Sumatra, Indonesia</li></ol>`
                },
                { 
                    question: "<p>What is meant by Richter Scale?</p>",
                    answer: `<p>Richter scale is an open-ended, logarithmic scale that estimates earthquake magnitude, designed by Charles Richter in 1935.</p>`
                },
                { 
                    question: "<p>What is an Earthquake?</p>",
                    answer: `<p>An earthquake can be defined as a shock or series of shocks due to a sudden movement of crustal rocks generated at a point known as seismic focus within the crust or the mantle. It is a violent tremor or shaking of part of earth crust.</p>`
                },
                { 
                    question: "<p>What is meant by epicentre?</p>",
                    answer: `<p>The point of earth's surface directly above the focus of an earthquake is called epicentre. The intensity of earthquake is maximum at epicentre and it goes on decreasing as the distance from epicentre increases.</p>`
                },
                { 
                    question: "<p>What is Seismograph used for?</p>",
                    answer: `<p>A seismograph is a device that measures seismic waves of the energy transmitted throughout the earth's interior.</p>`
                },
                { 
                    question: "<p>State any two causes of earthquakes.</p>",
                    answer: `<ol><li>Volcanic eruptions — Volcanic earthquakes are caused by gas explosions. They occur either simultaneously with eruption or more commonly in the period preceding an eruption. For example - Krakatoa volcano caused a severe earthquake that its impact was experienced at Cape Horn (12,800 km away).</li><li>Plate Tectonics — Tectonic plates move and slide over each other and their edges produce faults along the line of weakness. This movement of plates causes earthquakes. For example - In 2001, a severe earthquake occurred in Bhuj, Gujarat in India because of the lowering of the Indian plate below the Asiatic plate.</li></ol>`
                },
                { 
                    question: "<p>Mention any two destructive effects of earthquakes.</p>",
                    answer: `<ol><li>Building Collapse — The earthquakes of high intensity lead to collapse of buildings. People can be trapped under the rubble. There is loss of lives and properties.</li><li>Landslides — Earthquake causes landslides in hilly areas or liquefaction of soils resulting in damage of properties and loss of lives and disturb the transport system. This occurs due to liquefaction of soils.</li></ol>`
                },
                { 
                    question: "<p>Mention two constructive effects of earthquakes.</p>",
                    answer: `<ol><li>Earthquakes may result in fissure opening causing a Geyser or hot spring which are useful from medicinal point of view as it contain sulphur.</li><li>Sometimes earthquakes result in formation of coastal submergence and changing the coastal forms, forming bays and may prove to be helpful in navigation.</li></ol>`
                },
                { 
                    question: "<p>What is a Tsunami?</p>",
                    answer: `<p>The seismic waves travelling through the ocean resulting in long wavelength shallow water wave caused by rapid displacement of water is called Tsunami. Its velocity can reach 800 km per hour. It causes massive destruction and flood in coastal areas.</p>`
                },
                { 
                    question: "<p>Fault</p>",
                    answer: `<p>A fracture in a rock along which there has been an observable amount of displacement is known as fault. Earthquakes occur when the movement of the Earth takes place along a line of fracture or faults. The San Andreas Fault of California is a typical example which led to earthquakes in 1906.</p>`
                },
                { 
                    question: "<p>Seismic Focus</p>",
                    answer: `<p>Seismic Focus refers to the exact point inside the Earth's crust from where the shock or sudden movement of earthquake is generated.</p>`
                },
                { 
                    question: "<p>Flash Floods</p>",
                    answer: `<p>Due to impact of severe earthquake, the dams and embankments develop fissures which become the cause of sudden floods known as flash floods. They cause severe loss of life and property.</p>`
                },
                { 
                    question: "<p>Landslides</p>",
                    answer: `<p>A landslide is defined as the movement of a mass of rock, debris, or earth down a slope. Earthquake causes landslides in hilly areas resulting in damage of properties and loss of lives.</p>`
                },
                { 
                    question: "<p>Most earthquakes are caused by tectonic movements.</p>",
                    answer: `<p>Most earthquakes are caused by tectonic movements because the tectonic plates are in continuous slow motion. There is friction on their edges due to which they are stuck. Whenever there is change in the pressure exerted by them, a sudden movement or overlapping of plates occur resulting in earthquakes. Faults &amp; folds are formed when the plates collide with each other or diverge or slide apart, the energy inside the earth crust is released in the form of vibration or earthquake along the fault line.</p>`
                },
                { 
                    question: "<p>Earthquake is associated with volcanism.</p>",
                    answer: `<p>Vibration or earthquake may happen due to large scale gaseous explosion during the volcanic eruption. Therefore, earthquakes are associated with volcanism.</p>`
                },
                { 
                    question: "<p>Earthquake can be proved to be helpful for Navigation.</p>",
                    answer: `<p>Sometimes earthquakes result in the formation of coastal submergence and changing the coastal forms, forming bays. This may prove to be helpful for Navigation. Also, by studying the seismic waves generated by earthquakes, scientists have been able to create detailed maps of the ocean floor. These maps help to create accurate navigational charts for ships and submarines.</p>`
                },
                { 
                    question: "<p>Japan is an earthquake and Tsunami prone area.</p>",
                    answer: `<p>Japan is situated at the intersection of four tectonic plates, the North American Plate, the Eurasian Plate, the Pacific Plate, and the Philippine Plate. The country sits on the Pacific Ring of Fire which is characterized by high volcanic and seismic activity. Due to these reasons, Japan is an earthquake and Tsunami prone area.</p>`
                },
                { 
                    question: "<p>The science of earthquake is known as</p>",
                    answer: `<p>Seismology</p>`
                },
                { 
                    question: "<p>The magnitude of an earthquake is measured by</p>",
                    answer: `<p>Richter scale</p>`
                },
                { 
                    question: "<p>Which of the following statements related to earthquake focus is not true?</p>",
                    answer: `<p>it is situated on the surface of the earth &amp; from here the earthquake waves spread horizontally on the earth surface.</p>`
                },
                { 
                    question: "<p>Which of the following plates were responsible for Nepal earthquake on 25th April, 2015?</p>",
                    answer: `<p>Indo- Australian &amp; Eurasian Plate</p>`
                },
                { 
                    question: "<p>What is the name of this instrument?</p>",
                    answer: `<p>Seismometer</p>`
                },
                { 
                    question: "<p>Which of the following is not a cause of earthquake?</p>",
                    answer: `<p>Tsunami</p>`
                },
            ]
        },
        { 
            title: "Weathering and Denudation",
            questions: [
                { 
                    question: "<p>Define weathering. How does it occur?</p>",
                    answer: `<p>Weathering is a static process and it is defined as disintegration and decomposition of rocks due to several agents like wind, rain, animals, plants etc. It leads to formation of soil.</p>`
                },
                { 
                    question: "<p>Name the three types of weathering.</p>",
                    answer: `<ol><li>Physical weathering</li><li>Chemical weathering</li><li>Biological weathering</li></ol>`
                },
                { 
                    question: "<p>What do you understand by denudation?</p>",
                    answer: `<p>Denudation is the long-term effect of processes that cause the wearing away of the earth's surface leading to a reduction in elevation and relief of landforms and landscapes. Denudation is two fold process, i.e. both destructive and constructive. It causes wearing away of rocks and then deposition of the eroded materials forming plains.</p>`
                },
                { 
                    question: "<p>How does frost cause weathering of rocks?</p>",
                    answer: `<p>When water enters the rocks and turns into ice, its volume increases. During the day, the ice thaws and the water enters deep into rocks. When temperature drops during the night, the water again freezes, widening th cracks. This repeated freezing and thawing results in breaking up of rocks or weathering of rocks.</p>`
                },
                { 
                    question: "<p>In what ways does atmosphere assist in weathering?</p>",
                    answer: `<ol><li>Temperature - The diurnal and seasonal variation of temperature weakens the rocks and finally leads to their weathering by peeling.</li><li>Air(wind) - Winds as source of weathering are more effective in arid areas. The sand particles strike the soft rocks and slowly erode them.</li><li>Rain and flowing water - Water and frost are also important agents of mechanical weathering. The atmosphere contains oxides of sulphur and nitrogen that result in acid rain. Rain water dissolves some of minerals and cause chemical weathering.</li></ol>`
                },
                { 
                    question: "<p>What is meant by chemical weathering?</p>",
                    answer: `<p>The decomposition of rocks is called chemical weathering. It involves the breaking down of rocks by altering or dissolving the rock minerals due to chemical action or changes. It is more common in hot and humid regions. It is a slow and gradual process which occurs due to exposure to air and water. For example, the iron present in rocks reacts with oxygen and water to form rust.</p>`
                },
                { 
                    question: "<p>How does biological weathering take place?</p>",
                    answer: `<ol><li>The roots of plants grow into cracks and crevices in search of water and nourishment. As they grow, they exert great pressure on the rocks and break them.</li><li>Sometimes the roots produce carbonic acid which changes the composition of minerals.</li><li>Animals also loosen and weaken the rocks by burrowing.</li><li>Human activities such as road construction, mining, farming and deforestation cause weathering and disintegration of rocks.</li></ol>`
                },
                { 
                    question: "<p>What do you understand by mechanical weathering? How does it take place?</p>",
                    answer: `<p>The breakdown of rocks into smaller fragments by physical processes such as frost wedging is called mechanical weathering or disintegration.</p>`
                },
                { 
                    question: "<p>How does composition of rock affect mechanical weathering?</p>",
                    answer: `<p>The rocks are of different types. They may be hard or soft, soluble or insoluble. The mechanical weathering takes place in the softer or soluble rocks more easily than in the hard and insoluble rocks. Therefore, composition of rock affects mechanical weathering.</p>`
                },
                { 
                    question: "<p>Name the factors responsible for chemical weathering.</p>",
                    answer: `<ol><li>Oxidation</li><li>Hydration</li><li>Carbonation</li><li>Solution</li></ol>`
                },
                { 
                    question: "<p>What is carbonation? Give two examples of the landforms formed by carbonation?</p>",
                    answer: `<p>Carbonation is the reaction of carbonic acid on minerals. Rainwater, when mixed with carbon dioxide in the atmosphere forms a weak solution of carbonic acid. It acts on rocks containing limestone and chalk. The reaction results in calcium bicarbonate which is highly soluble and gets easily washed away in solution as it flows through the rocks or seeps below the surface.</p>`
                },
                { 
                    question: "<p>How does chemical weathering take place?</p>",
                    answer: `<p>The decomposition of rocks is called chemical weathering. It involves the breaking down of rocks by altering or dissolving the rock minerals due to chemical action or changes. It involves processes like oxidation, hydration, carbonation and solution. It is more common in hot and humid regions. It is a slow and gradual process which occurs due to exposure to air and water.</p>`
                },
                { 
                    question: "<p>Oxidation</p>",
                    answer: `<p>The process by which oxygen present in air and water react with minerals in the rock is called oxidation. For example, most rocks contain a certain amount of iron. Oxygen and water react with iron to form iron oxide which easily erodes in comparison to iron. As it is removed the rocks are weakened and thus decomposed.</p>`
                },
                { 
                    question: "<p>Solution</p>",
                    answer: `<p>The process by which the rainwater reacts with minerals present in the rock and dissolves them causing decomposition of rock is called solution. For example, rock salt dissolves in water.</p>`
                },
                { 
                    question: "<p>Exfoliation</p>",
                    answer: `<p>Exfoliation is the weathering process by which concentric shells, slabs, sheets, or flakes are successively broken loose and stripped away from a rock mass.</p>`
                },
                { 
                    question: "<p>Weathering</p>",
                    answer: `<p>Weathering is defined as disintegration and decomposition of rocks due to several agents like wind, rain, animals and plants. It leads to formation of soil.</p>`
                },
                { 
                    question: "<p>Denudation</p>",
                    answer: `<p>All processes that cause degradation of landscape, weathering mass movement, erosion and deposition of eroded material are called denudation.</p>`
                },
                { 
                    question: "<p>Chemical and Mechanical Weathering</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Weathering and Denudation</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Wind is the main agent of gradation in the arid &amp; semi arid regions.</p>",
                    answer: `<p>Due to the absence of vegetation the soil is loose &amp; exposed to the action of winds &amp; scanty rainfall.</p>`
                },
                { 
                    question: "<p>Erosion is the main work of river in the upper course.</p>",
                    answer: `<p>In the upper course of the river the gradient of the slope is high, therefore, the velocity of the river is also high. So the main action of the river is erosion.</p>`
                },
                { 
                    question: "<p>Weathering is a static phenomenon.</p>",
                    answer: `<p>As there is no involvement in the movement of disintegrated rock fragments in weathering process.</p>`
                },
                { 
                    question: "<p>Deposition is the main work of river in the lower course.</p>",
                    answer: `<p>As the river approaches the sea or ocean, its gradient decreases, resulting in a reduction of the river's velocity. It's volume also increases due to addition of tributaries along the way. Due to this, it cannot carry the sand particles any more and deposits all the sediments at its mouth. Hence, deposition is the main work of river in the lower course.</p>`
                },
                { 
                    question: "<p>Chemical weathering may affect the farming activities.</p>",
                    answer: `<p>Chemical weathering may dissolve the soluble components of the soils. This may cause the soils to become infertile and may affect farming activities.</p>`
                },
                { 
                    question: "<p>Which of following work is active in the lower course of a river?</p>",
                    answer: `<p>Deposition</p>`
                },
                { 
                    question: "<p>Identify the landform by studying the image.</p>",
                    answer: `<p>Barchans</p>`
                },
                { 
                    question: "<p>Through which of the following chemical weathering Feldsper is converted to Keolin?</p>",
                    answer: `<p>Hydration</p>`
                },
                { 
                    question: "<p>Which of the following weathering is also known as Onion Peeling?</p>",
                    answer: `<p>Exfoliation</p>`
                },
                { 
                    question: "<p>Which of the following weathering is also known as Mechanical weathering?</p>",
                    answer: `<p>Physical weathering</p>`
                },
                { 
                    question: "<p>Which of the following landforms is formed in the lower course of the river?</p>",
                    answer: `<p>Delta</p>`
                },
                { 
                    question: "<p>Which of the following is the erosional landform of wind?</p>",
                    answer: `<p>Deflation Hollows</p>`
                },
                { 
                    question: "<p>In which course of the river the river erosion is more lateral than vertical?</p>",
                    answer: `<p>Middle course</p>`
                },
                { 
                    question: "<p>The limestone topography is affected through which of the following weathering process?</p>",
                    answer: `<p>Carbonation</p>`
                },
                { 
                    question: "<p>Which of the following features is observed in this image?</p>",
                    answer: `<p>Meander</p>`
                },
                { 
                    question: "<p>Waterfall is formed due to the erosion by which of the following agent?</p>",
                    answer: `<p>River</p>`
                },
                { 
                    question: "<p>Which of the following is a static process?</p>",
                    answer: `<p>Weathering</p>`
                },
            ]
        },
        { 
            title: "Tides and Ocean Currents",
            questions: [
                { 
                    question: "<p>What are currents? What causes these currents?</p>",
                    answer: `<ol><li>Planetary winds</li><li>Variation in sea water temperature</li><li>Variation in sea water salinity</li><li>Rotation of the earth</li><li>Configuration of Coastlines</li></ol>`
                },
                { 
                    question: "<p>What is Coriolis Force?</p>",
                    answer: `<p>A deflective force that, as a result of the earth's rotation, deflects moving object to the right in the northern hemisphere and in the left in the southern hemisphere is called coriolis force. It affects the movement of ocean currents and winds.</p>`
                },
                { 
                    question: "<p>What happens when warm and cold currents meet? How is it important for fisheries?</p>",
                    answer: `<p>When warm and cold currents meet very dense fog conditions are created. Such conditions are very dangerous for navigation.</p>`
                },
                { 
                    question: "<p>What leads to the variation in the salinity of the sea water?</p>",
                    answer: `<ol><li>Salinity increases in area where evaporation is more.</li><li>Salinity increases in land locked areas.</li><li>Salinity decreases if there is more rainfall.</li><li>Salinity is less where the river meets the sea.</li><li>Salinity is less where the ice melts in large quantity.</li></ol>`
                },
                { 
                    question: "<p>Name a few important currents of the Pacific Ocean.</p>",
                    answer: `<ol><li>Kuroshio Current (a warm current of North Pacific Ocean)</li><li>Tsushima current (An offshoot of Kuroshio current)</li><li>Kurile or Oyashio Current (Cold current originating from Bering strait)</li></ol>`
                },
                { 
                    question: "<p>What is Gulf Stream famous for?</p>",
                    answer: `<p>The Gulf Stream is the largest of the western boundary currents of the North Atlantic Ocean. It affects the temperature of east coast of North America and west coast of Europe. Without this warm current, UK and other places in Europe would be as cold as Canada at the same latitude.</p>`
                },
                { 
                    question: "<p>Name a few minor currents of the world.</p>",
                    answer: `<ol><li>Canary Current(cold)</li><li>Brazil Current(warm)</li><li>South Atlantic Drift(cold)</li><li>Falkland Current (cold)</li><li>Benguela Current (cold)</li><li>Tsushima current(warm)</li></ol>`
                },
                { 
                    question: "<p>What effect do the ocean currents have on the climate of a place?</p>",
                    answer: `<ol><li>Wind passing over the warm current pick up the moisture and bring rain to the adjoining areas. For example, British Columbia, British Isles and Japan get extra rain because of warm current. Eastern margins of Australia and Africa have heavy rainfall.</li><li>Winds passing over cold currents do not rain. For example, Kalahari and Atacama desert are drier because of cold currents.</li><li>The cold current bring elongated winter season. For example, Labrador currents bring nearly nine months of winter and bad weather in Labrador.</li><li>The eastern part of ocean in lower latitudes record relatively low temperature making the climate cold and foggy.</li></ol>`
                },
                { 
                    question: "<p>What are the effects of tides?</p>",
                    answer: `<ol><li>Tides help to remove the debris from the seashore.</li><li>Strong tidal currents help ships to enter in shallow harbours, e.g., Kolkata</li><li>In certain harbours tidal basins are constructed to store tidal water, e.g., London harbour.</li><li>Tidal energy can be harnessed to generate electricity, e.g., UK and Australia.</li><li>Tides help in producing salt in the coastal area as water is collected/flooded by high tide in the low lying areas.</li><li>Tides govern the schedule of fishermen. They sail out and in with the tide.</li><li>In cold countries, tides prevent the ports from getting frozen as they keep coastal waters in constant motion.</li><li>Strong tidal waves help rivers in building their own flood plains, e.g., Thames in England.</li><li>The sea creatures which are attached to rocks depend on tides to bring their food along with the tidal waters.</li></ol>`
                },
                { 
                    question: "<p>What is the time difference between two tides?</p>",
                    answer: `<p>The time difference between two tides is 12 hours and 26 minutes.</p>`
                },
                { 
                    question: "<p>Explain by reference to actual examples the effects of ocean currents on climate, economy and fishing.</p>",
                    answer: `<ol><li>Newfoundland where Gulf Stream and Labrador current (cold and warm currents) meet is very important fishing ground as it provides ideal temperature conditions for the life of ﬁsh.</li><li>In 1912 Titanic, the largest ship of its time, off Newfoundland where Labrador Current (cold) meets Gulf Stream (warm), struck a huge iceberg and took less than 3 hours to sink. It happened due to extreme fog.</li><li>Winds passing over the warm currents pick up the moisture and give extra rains to British Columbia, British Isles, Japan and Queensland. Eastern margins of Australia and Africa have heavy rainfall due to the same reason.</li><li>Winds passing over cold currents do not rain at all, e.g., Kalahari and Atacama deserts are much drier because of cold currents.</li><li>The cold Labrador currents bring nearly nine months of winter and bad weather conditions prevailing over the rest of the year in Labrador and Eastern Newfoundland.</li><li>North Atlantic drift (warm current) has moderating effect on climate of western Europe.</li><li>Warm water of Tsushima current keeps the west coast of Japan warm.</li><li>Kuroshio current keeps the eastern coast of Japan warm.</li><li>Oyashio current has impact on the climate of the Russia Far East i.e. Kamchatka and Chukotka. It also forms one of the richest fishery in the world.</li></ol>`
                },
                { 
                    question: "<p>How are tides caused?</p>",
                    answer: `<ol><li>Gravitational Force — The gravitational pull of Moon and the Sun cause the tides. The influence of moon is more due to its proximity to earth.</li><li>Influence of the Moon — The Earth is pulled towards the Moon more than the water on the other side of earth therefore, water lags behind and rises up on the side facing the moon, forming high tide.</li><li>The rotation of the Earth — It results in every meridian coming into the position of two high tides and two low tides nearly every 24 hours. Since Moon travels in its orbits in the same direction as the Earth is rotating it takes about 24 hours and 52 minutes or one lunar day for the sequence of two high and two low tides to be completed.</li></ol>`
                },
                { 
                    question: "<p>Name the two movements in the ocean.</p>",
                    answer: `<ol><li>Horizontal (ocean current)</li><li>Vertical (the rising and sinking of water)</li></ol>`
                },
                { 
                    question: "<p>Name any two warm currents of the Atlantic Ocean.</p>",
                    answer: `<ol><li>Brazil Current</li><li>Gulf Stream Current</li></ol>`
                },
                { 
                    question: "<p>The famous fishing grounds of the world are located where warm and cold currents meet.</p>",
                    answer: `<p>The famous fishing grounds of the world are located where warm and cold currents meet because the mixing of the cold and warm currents provides ideal temperature conditions for the life of fish. The chief food of fish (plankton) is found in abundance in cold water. For example, Newfoundland where Gulf stream (warm) and Labrador (cold) currents meet.</p>`
                },
                { 
                    question: "<p>Winds have the main influence on the circulation of ocean currents.</p>",
                    answer: `<p>Most of the ocean currents of the world follow the direction of prevailing permanent or planetary winds because most of the earth's surface energy is concentrated in each Hemisphere's trade winds. Currents are also affected by coriolis effect. Many of the ocean current are drifts caused by the friction between the winds and the surface water.</p>`
                },
                { 
                    question: "<p>The tidal range differs from sea to sea.</p>",
                    answer: `<p>The tidal range differs from sea to sea. In the open oceans such as Atlantic, tides flow and ebb regularly twice a day. The difference in height between high and low tides, may be only a half metre, but in shallow marginal sea, it can increase upto 10 metres. In the enclosed seas or sheltered seas such as Mediterranean or Baltic, the range may be very small.</p>`
                },
                { 
                    question: "<p>The tides help in navigation.</p>",
                    answer: `<ol><li>It raises the water level close to the shore and help the ship to enter and leave shallow harbours.</li><li>Occurrence of tides are predictable. Therefore, it help navigators and fishermen to plan their activities.</li></ol>`
                },
                { 
                    question: "<p>The time difference between two tides is approximately 12 hours 26 minutes.</p>",
                    answer: `<p>The time difference between two tides is approximately 12 hours 26 minutes because the revolution of the Moon around the Earth is in the same direction as Earth's rotation, i.e., from West to East. If the Moon had remained stationary then each place on Earth's surface would have come under its influence after 24 hours and each tide would have occurred at the interval of 24 hours.</p>`
                },
                { 
                    question: "<p>The ocean currents flow from the Atlantic Ocean to the Mediterranean Sea surface.</p>",
                    answer: `<p>Ocean currents on the water surface are generated from the areas of low salinity to the areas of high salinity. There is a marked variation in the salinity of the Atlantic ocean and the Mediterranean Sea. Because of this variation, the ocean current flows from the Atlantic Ocean to the Mediterranean Sea surface.</p>`
                },
                { 
                    question: "<p>Ocean currents helps in maintaining the earth's heat balance.</p>",
                    answer: `<p>Ocean currents helps in maintaining the earth's heat balance by transferring heat from lower to higher latitudes.</p>`
                },
                { 
                    question: "<p>Spring Tide</p>",
                    answer: `<p>When the Sun, Earth and Moon are in straight line, as they are at Full Moon and New Moon, the gravitational force is at its greatest because of combined force of Sun and Moon as they are pulling together. At this time the high tide is very high and low tide is very low. These type of tides are called Spring Tides.</p>`
                },
                { 
                    question: "<p>Neap Tide</p>",
                    answer: `<p>When the Sun, Earth and Moon are not in a straight line, the Sun and Moon are not exerting combined force, i.e., they are not ‘pulling’ together and the gravitational pull is much less. At half moon, that is, when the Sun and Moon are ‘pulling’ at right angles, the force exerted is at its least and the difference between high and low tide is not large. These tides are called Neap Tides.</p>`
                },
                { 
                    question: "<p>Gulf Stream</p>",
                    answer: `<p>The Gulf Stream is the largest of the western boundary currents of the North Atlantic Ocean. This is a warm water current which originates in the Gulf of Mexico around 20° N and moves in a north- easterly direction along the eastern coast of North America. It influences the climate of eastern coast of North America. Water within the current is usually warm and blue, often depleted of nutrients and incapable of supporting much life.</p>`
                },
                { 
                    question: "<p>Labrador Current</p>",
                    answer: `<p>The Labrador, an important cold water current of the North Atlantic Ocean, has its origin in the Arctic Ocean which flows from North to South between the Greenland and the Baffin Islands (Davis Strait). Passing southward, it merges with the Gulf Stream near Newfoundland. The average speed of the current is about 28 km per day. It brings down huge icebergs from the Arctic Ocean to the eastern coast of Canada which are hazardous to navigation.</p>`
                },
                { 
                    question: "<p>The mass of water which is occupying the greatest part of the Earth’s surface is called</p>",
                    answer: `<p>Hydrosphere</p>`
                },
                { 
                    question: "<p>The general movement of a mass of oceanic water in a definite direction is called</p>",
                    answer: `<p>Ocean current</p>`
                },
                { 
                    question: "<p>Which of the following factors does not affect the movement of ocean currents directly?</p>",
                    answer: `<p>Difference in rainfall amount</p>`
                },
                { 
                    question: "<p>Which of the following ocean current flows in north east direction throughout the year under the influence of westerlies?</p>",
                    answer: `<p>Gulf stream</p>`
                },
                { 
                    question: "<p>Which of the following is a cold ocean current?</p>",
                    answer: `<p>Oyashio current</p>`
                },
                { 
                    question: "<p>Dense fog is formed near Newfoundland due to</p>",
                    answer: `<p>the meeting of Gulf Stream &amp; Labrador current</p>`
                },
                { 
                    question: "<p>Which of the following ocean currents is responsible for moderating the climate of Western Europe?</p>",
                    answer: `<p>North Atlantic current</p>`
                },
                { 
                    question: "<p>Which of the following statements is not correct about Spring tide?</p>",
                    answer: `<p>It happens when Sun; Moon and Earth are not in straight line.</p>`
                },
                { 
                    question: "<p>What happens when cold &amp; warm ocean currents meet?</p>",
                    answer: `<p>All the above</p>`
                },
                { 
                    question: "<p>Which of following reason is responsible for 12 hours 26 minutes time difference between each tide?</p>",
                    answer: `<p>Moon is revolving round the earth.</p>`
                },
                { 
                    question: "<p>Which of the following current is formed in Atlantic Ocean?</p>",
                    answer: `<p>Gulf stream</p>`
                },
                { 
                    question: "<p>Which of the following ocean current flows under the influence of trade winds?</p>",
                    answer: `<p>Equatorial current</p>`
                },
            ]
        },
        { 
            title: "Composition and Structure of the Atmosphere",
            questions: [
                { 
                    question: "<p>What is the significance of atmosphere?</p>",
                    answer: `<ol><li>It maintains the temperature of the Earth by trapping the heat of solar radiation.</li><li>It provides us important gases like Oxygen (needed for respiration), Nitrogen (cell formation) and Carbon dioxide (keeps the earth warm).</li><li>It absorbs ultraviolet solar radiation.</li><li>Atmosphere exerts pressure which balances internal pressure of organisms.</li></ol>`
                },
                { 
                    question: "<p>State the components of the atmosphere.</p>",
                    answer: `<p>The Earth's atmosphere consists of a mixture of various gases surrounding the Earth. It is an envelope of gases encompassing the Earth, held by gravity. Two main gases Nitrogen (78%) and Oxygen (21%), make up about 99% of the clean dry air. The remaining gases are almost inert and constitute about 1% of atmosphere. Out of this 1%, Argon is 0.93%. The atmosphere also contains water vapour and dust particles.</p>`
                },
                { 
                    question: "<p>Name the different layers of the atmosphere.</p>",
                    answer: `<ol><li>Troposphere</li><li>Stratosphere</li><li>Ionosphere</li><li>Exosphere</li></ol>`
                },
                { 
                    question: "<p>State the important characteristics of each of the layers.</p>",
                    answer: `<ol><li>It is the outermost layer of atmosphere.</li><li>It contains rarified Hydrogen and Helium gases.</li></ol>`
                },
                { 
                    question: "<p>What is the significance of atmosphere for the Earth?</p>",
                    answer: `<ol><li>It maintains the temperature of the Earth by trapping the heat, due to presence of Carbon dioxide.</li><li>It provides us important gases like Oxygen (needed for respiration), Nitrogen (cell formation) and Carbon dioxide (keeps the earth warm).</li><li>It absorbs ultraviolet solar radiation due to presence of Ozone layer.</li><li>All the weather phenomena occurs in atmosphere.</li><li>It is responsible for cloud formation and precipitation which is necessary for water cycle.</li><li>The Earth is protected by falling meteors as they burn to ash due to friction in atmosphere.</li></ol>`
                },
                { 
                    question: "<p>What are the properties of Troposphere?</p>",
                    answer: `<ol><li>It is lower most atmospheric layer with 14 km average thickness. Its thickness is about 8 km at poles and 18 km at equator.</li><li>All weather phenomena like cloud formation, precipitation, fog etc. takes place in this layer.</li><li>In addition to pure air, it contains water vapour and dust.</li><li>There is decrease in temperature with increasing altitude at the rate of 6.4° C per km in troposphere.</li><li>Tropopause marks the upper boundary of troposphere.</li></ol>`
                },
                { 
                    question: "<p>What is Tropopause?</p>",
                    answer: `<p>It is the boundary in the Earth's atmosphere between the troposphere and the stratosphere. It lies, on an average, at 17 kilometres above equatorial regions, and about 9 kilometres over the poles.</p>`
                },
                { 
                    question: "<p>What is the significance of ozone in the atmosphere?</p>",
                    answer: `<p>The significance of ozone layer is that it absorbs the harmful ultraviolet rays coming from the Sun. In the absence of the ozone layer found in the atmosphere and in the event of ultraviolet rays reaching Earth's surface, our planet would have been unfit for human habitation and other living beings.</p>`
                },
                { 
                    question: "<p>What are the causes of destruction of ozone layer?</p>",
                    answer: `<ol><li>Emission of Nitrogen Oxide by a large number of supersonic transport aeroplanes may cause deterioration of ozone layer.</li><li>Release of synthetic chemicals primarily Chlorofluoro-carbons(CFCs) into the atmosphere is the most important cause of destruction of ozone layer.</li></ol>`
                },
                { 
                    question: "<p>Give the properties of Ionosphere.</p>",
                    answer: `<ol><li>Ionosphere extends from 80 km to about 480 km.</li><li>The ionisation of molecules and atoms occurs mainly as a result of ultraviolet rays, X-rays and gama rays.</li><li>The temperature decreases with increase in elevation until a low point of -100℃ is reached. Beyond this level, the temperature increases again as a result of absorption of short wave solar radiation by the atoms of oxygen.</li><li>Nitrogen in the extremely rarified air of ionosphere.</li><li>Ionosphere reflects low frequency radio waves, but absorbs medium and high frequency waves, making it especially important in long distance radio communication.</li></ol>`
                },
                { 
                    question: "<p>What is meant by Greenhouse Effect?</p>",
                    answer: `<p>The process whereby radioactively active gases absorb and delay the loss of heat to space, thus keeping the lower troposphere moderately warmed throughout the radiation and radiation of infrared wavelengths is called Greenhouse Effect. It leads to gradual increase in the temperature of Earth. The gases responsible for Greenhouse effect are carbon dioxide, methane and CFCs.</p>`
                },
                { 
                    question: "<p>What do you mean by 'Global Warming'? What are the consequences of 'Global Warming'?</p>",
                    answer: `<ol><li>Melting of ice caps and Glaciers.</li><li>Increasing sea temperature is wiping out coral reefs. These are breeding ground for many marine species and they protect coastlines from storms.</li><li>It may pose serious threat to the ecological balance.</li><li>Higher wildlife extinction rates.</li><li>Higher sea levels.</li><li>Global warming is increasing the frequency and severity of many types of disasters, including storms, heat waves, floods, and droughts.</li></ol>`
                },
                { 
                    question: "<p>Name a few measures that could be taken to save the ozone layer.</p>",
                    answer: `<ol><li>Minimize the emission of gases that are harmful for Ozone layer such as nitrogen oxide.</li><li>Minimize the continuous release of synthetic chemicals primarily chlorofluoro-carbons (CFCs) into the atmosphere.</li><li>Worldwide awareness against the use of Chloro-fluoro carbons.</li><li>Illegal trade of harmful chemicals must be controlled.</li><li>Finally, take strict decision to ban the use of harmful chemicals and invent alternatives to these chemicals.</li></ol>`
                },
                { 
                    question: "<p>Name the three realms of the Earth.</p>",
                    answer: `<ol><li>Lithosphere</li><li>Hydrosphere</li><li>Atmosphere.</li></ol>`
                },
                { 
                    question: "<p>State the composition of the Earth's atmosphere.</p>",
                    answer: `<p>The atmosphere consists of a number of gases like Nitrogen (78%), Oxygen (21%), Carbon dioxide and other gases like Argon, Helium etc. In addition to these gases, the atmosphere also contains water vapour and dust particles.</p>`
                },
                { 
                    question: "<p>How can we reduce global warming?</p>",
                    answer: `<ol><li>Conservation of forests and control indescriminate felling of trees</li><li>Reduce industrial and vehicular pollution.</li><li>Control the release of chlorofluoro carbons in atmosphere.</li><li>Reduce the release of green house gases like carbon dioxide and methane.</li><li>Create awareness regarding pollution and its effects.</li></ol>`
                },
                { 
                    question: "<p>Troposphere</p>",
                    answer: `<p>The lowest and densest layer of Earth's atmosphere, with average height of 14 km, which is the site of all weather phenomena is known as troposphere.</p>`
                },
                { 
                    question: "<p>Stratosphere</p>",
                    answer: `<p>The layer of atmosphere extending from 18 to 80 km, which lies between troposphere and mesosphere is called Stratosphere.</p>`
                },
                { 
                    question: "<p>Weather</p>",
                    answer: `<p>The atmospheric conditions like temperature, precipitation, wind and humidity etc. prevailing at a particular place and time is known as weather.</p>`
                },
                { 
                    question: "<p>Greenhouse Effect</p>",
                    answer: `<p>The process whereby radioactively active gases absorb and delay the loss of heat to space, thus keeping the lower troposphere moderately warmed throughout the radiation and radiation of infrared wavelengths is called Greenhouse Effect.</p>`
                },
                { 
                    question: "<p>CFC</p>",
                    answer: `<p>CFC stands for Chlorofluorocarbons. They are synthetic industrial chemical compounds containing Chlorine, Fluorine and Carbon atoms. These are used as cleaning agents, in refrigerators, fire extinguishing fluids, spray, car propellants and insulating foams. They cause deterioration of Ozone layer.</p>`
                },
                { 
                    question: "<p>Thermosphere</p>",
                    answer: `<p>Atmospheric layer, about 400 km thick, lying above mesosphere which has upwardly increasing temperature is called thermosphere.</p>`
                },
                { 
                    question: "<p>Troposphere and Stratosphere</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Stratosphere and Thermosphere</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The layer of the atmosphere become thinner with altitude.</p>",
                    answer: `<p>The gravity causes the upper layers of atmosphere to exert pressure on the lower ones due to their weight. This causes the air to compress and hence the air become dense at the lower level while upper layers are thinner.</p>`
                },
                { 
                    question: "<p>The Earth does not experience extremes of temperature.</p>",
                    answer: `<p>The earth does not experience extremes of temperature as on other planets due to the presence of the atmosphere. The atmosphere absorbs some of the heat of the sun, and the remainder is reflected back. As a result, the planet doesn't become too heated during the day or too cold during the night.</p>`
                },
                { 
                    question: "<p>Solid particles play an important role in the atmosphere.</p>",
                    answer: `<p>Solid particles are important because they serve as nuclear centres around which the water vapour condenses to form cloud particles.</p>`
                },
                { 
                    question: "<p>The presence of ozone layer is a boon to human being.</p>",
                    answer: `<p>The ozone layer plays a crucial role in protecting the Earth and its inhabitants from harmful ultraviolet (UV) radiation from the sun. Without the protective barrier provided by the ozone layer, the amount of UV radiation reaching the Earth's surface would be much higher and our planet would have been unfit for human habitation and other living beings. Thus, presence of ozone layer is a boon to human beings.</p>`
                },
                { 
                    question: "<p>Ionosphere is important in long distance radio-wave communication.</p>",
                    answer: `<p>Ionosphere reflects low frequency radio waves but absorbs medium and high frequency waves. Thus, this layer is especially important in long distance radio-wave communication.</p>`
                },
                { 
                    question: "<p>Draw a self-explanatory diagram illustrating the structure of the atmosphere.</p>",
                    answer: `<p>Below diagram illustrates the structure of the atmosphere:</p>`
                },
                { 
                    question: "<p>The proportion of Nitrogen in the atmosphere is about</p>",
                    answer: `<p>78%</p>`
                },
                { 
                    question: "<p>Which of the following is the lower most layer of the atmosphere?</p>",
                    answer: `<p>Troposphere</p>`
                },
                { 
                    question: "<p>In which of the following layers in atmosphere, Normal lapse rate of temperature is observed?</p>",
                    answer: `<p>Troposphere</p>`
                },
                { 
                    question: "<p>Which of the following layers of atmosphere absorbs the ultra violet rays of Sun?</p>",
                    answer: `<p>Ozonosphere</p>`
                },
                { 
                    question: "<p>............... layer of atmosphere is important for long distance radio communication.</p>",
                    answer: `<p>Ionosphere</p>`
                },
                { 
                    question: "<p>Which of the following chemical is responsible for the depletion of ozone?</p>",
                    answer: `<p>CFC</p>`
                },
                { 
                    question: "<p>Which one is the outermost layer of atmosphere?</p>",
                    answer: `<p>Exosphere</p>`
                },
                { 
                    question: "<p>Which of the following is not a reason for global warming?</p>",
                    answer: `<p>Rise in the sea level</p>`
                },
                { 
                    question: "<p>Different types of weather phenomena is seen in</p>",
                    answer: `<p>Troposphere</p>`
                },
                { 
                    question: "<p>Which of the following is not a greenhouse gas?</p>",
                    answer: `<p>Helium</p>`
                },
            ]
        },
        { 
            title: "Insolation",
            questions: [
                { 
                    question: "<p>What is insolation? State its importance.</p>",
                    answer: `<ol><li>It provides energy in the form of heat and light.</li><li>It maintains the temperature of Earth.</li><li>It help plants make their food.</li></ol>`
                },
                { 
                    question: "<p>State the three processes by which the air gets heated. Explain in brief.</p>",
                    answer: `<ol><li><p><strong>Convection</strong> — Convection occurs when heat is transferred through a gas or liquid. It is transmission of heat from one part of a liquid or gas to other part by upward movement of its particles.</p></li><li><p><strong>Conduction</strong> — (b)Conduction is a process in which heat is transferred directly through matter from a point of high temperature. Conduction transfers heat between adjacent molecules till the temperature is equal.</p></li><li><p><strong>Radiation</strong> — It is the mode of transfer of heat which does not require any medium. It is a process by which a body emits radiant energy. It leads to loss of heat and leads of cooling.</p></li></ol>`
                },
                { 
                    question: "<p>State the various factors influencing the temperature of a place.</p>",
                    answer: `<ol><li>Latitude — Places near equator are hotter than places near poles.</li><li>Altitude — Temperature decreases with increasing altitude at a rate of 1°C per 166 metre.</li><li>Distance from sea — Places near sea have equable climate i.e. low diurnal and annual range of temperature than the places situated away from the sea.</li><li>Ocean currents — Ocean currents along the coast make the coastal areas warmer and cold currents make it cooler than usual.</li><li>Clouds and rainfall — Absence of clouds results in high temperature while presence of clouds moderates the temperature.</li><li>Slope of land — South facing slopes in Northern hemisphere are warmer.</li><li>Vegetation — Vegetation checks radiation from upper layer of soil. They have moderating effect on temperature.</li><li>Nature of soil — The more the water soil can retain, the less rapidly it heats or cools. Dark coloured soil absorbs more heat and gives rise to differences in temperature.</li></ol>`
                },
                { 
                    question: "<p>Why does only 51% of the insolation reach the Earth surface?</p>",
                    answer: `<p>Only 51% of the insolation reaches the surface of the Earth as out of the remaining 49%, 35% of the insolation is directly reflected back to space by the atmosphere and the rest of the 14% is absorbed by the ozone layer.</p>`
                },
                { 
                    question: "<p>What is the effect of latitude on temperature?</p>",
                    answer: `<p>Places close to the Equator have higher temperature and are warmer than places away from the equator. This is because, at the low latitude the Sun's rays are direct and have to travel a lesser extent through the atmosphere. Hence, the heat of these rays is more intense. But at higher latitudes, the Sun's rays are slanting and have to pass through a greater extent of atmosphere, due to which they lose their heat and are not intense.</p>`
                },
                { 
                    question: "<p>What causes the differential heating of land and water?</p>",
                    answer: `<p>The land absorbs more solar radiation and reflects a little while water reflects more solar radiation and absorbs a little. The differential heating of land and water is caused because of different rates at which the land and water are heated up. Land gets heated faster than the water. Therefore, during day land gets hotter than water. This results in wind blowing from sea to land during day. During night the land cools down faster and becomes cooler than the water bodies. This results in wind blowing from land towards the sea.</p>`
                },
                { 
                    question: "<p>What is inversion of temperature? Under what conditions does it apply?</p>",
                    answer: `<p>The temperature decreases at the rate of about 1°C per 166 m or 6°C per 1,000 metres of altitude. There are, however some places where certain special conditions in the lower atmosphere produce a reversal of the normal lapse rate so that temperature actually increases with an increase in altitude. This is known as temperature inversion.</p>`
                },
                { 
                    question: "<p>Which zone will have a higher range of temperature? Why?</p>",
                    answer: `<p>The Torrid zone will have a higher range of temperature because it lies between temperate zone and equator. The equatorial region is warm throughout the year but as we move northward or southward the range of temperature i.e. difference between highest and lowest temperature, goes on increasing.</p>`
                },
                { 
                    question: "<p>Name the factors that affect the insolation over the Earth's surface.</p>",
                    answer: `<ol><li>Latitude</li><li>Altitude</li><li>Winds</li><li>Clouds and rainfall</li><li>Slope of the land</li><li>Vegetation</li></ol>`
                },
                { 
                    question: "<p>Why are the slanting rays less effective than the vertical rays in heating the atmosphere?</p>",
                    answer: `<p>The slanting rays are less effective than the vertical rays in heating the atmosphere because slanting rays travel a longer distance through the atmosphere where much of its heat is absorbed by clouds, water vapour and dust particles while vertical rays travel through a shorter distance in the atmosphere and the loss of heat is less.</p>`
                },
                { 
                    question: "<p>How do winds modify the temperature of a place?</p>",
                    answer: `<p>Winds blowing from hotter area increase the temperature while winds blowing from cold areas decrease the temperature. For example, a cold wind blowing from the interior of the continent during winter further reduces the temperature of the places along their path, e.g., China. Generally, the winds blowing from the lower latitudes to higher ones increase the temperature of the higher latitudes.</p>`
                },
                { 
                    question: "<p>What does Normal Lapse Rate mean?</p>",
                    answer: `<p>With the increase of height, temperature decreases at the rate of 1°C for every 166 metres. This is known as Normal Lapse Rate.</p>`
                },
                { 
                    question: "<p>Explain with the help of a diagram how the slope of the land affects the temperature.</p>",
                    answer: `<ol><li>Presence of mountains acting as a barrier — For example, Himalayas prevent the monsoon winds from leaving India and also prevent the cold winds from China to enter India.</li><li>Inversion of temperature — Steep slopes experience more changes in temperature than a gentle slope because of reversal of Normal Lapse Rate of temperature.</li><li>Direction of slope of the land — Slopes facing south in the northern hemisphere are warmer than those facing north because the sun rays strike the south facing slopes at a steeper angle than they do at the north facing slopes which are also called Sheltered slopes.</li></ol>`
                },
                { 
                    question: "<p>Why does the temperature of a place in the atmosphere decrease with height?</p>",
                    answer: `<p>The temperature of a place in the atmosphere generally decreases with altitude because the gases absorb very little of the incoming solar radiation but the ground absorbs this radiation and then heats the tropospheric air by convection and radiation. Therefore, atmosphere is hotter near the surface and get cooler as the height increases.</p>`
                },
                { 
                    question: "<p>What causes equable climate?</p>",
                    answer: `<p>Equable climate refers to a climate with low range of annual and diurnal temperature. It is experienced in areas situated close to sea. The sea has a moderating effect on the climate of coastal areas which causes the equable climate.</p>`
                },
                { 
                    question: "<p>State the reason for the differential heating of land and water?</p>",
                    answer: `<p>The land absorbs more solar radiation and reflects a little while water reflects more solar radiation and absorbs a little. The differential heating of land and water is caused because of different rates at which the land and water are heated up. Land gets heated faster than the water. Therefore, during day land gets hotter than water. This results in wind blowing from sea to land during day. During night the land cools down faster and becomes cooler than the water bodies. This results in wind blowing from land towards the sea.</p>`
                },
                { 
                    question: "<p>Insolation</p>",
                    answer: `<p>The solar radiation intercepted by the Earth is known as insolation.</p>`
                },
                { 
                    question: "<p>Conduction</p>",
                    answer: `<p>Transfer of heat from an areas of more heat to areas of less heat by direct contact or directly through the matter is called conduction.</p>`
                },
                { 
                    question: "<p>Normal Lapse Rate</p>",
                    answer: `<p>The normal lapse rate refers to decrease in temperature at the rate of 1°C per 166 metres increase in altitude.</p>`
                },
                { 
                    question: "<p>Inversion of Temperature</p>",
                    answer: `<p>Reversal of Normal Lapse Rate of temperature in special conditions where temperature actually increases with an increase in altitude, is called temperature inversion.</p>`
                },
                { 
                    question: "<p>Insolation and Terrestrial Radiation</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Convection and Radiation</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Land gets heated faster than the sea</p>",
                    answer: `<p>Land surfaces absorb much more solar radiation than water. Therefore, it gets heated faster than the sea.</p>`
                },
                { 
                    question: "<p>The climate of continental interiors is of comparatively extreme type</p>",
                    answer: `<p>The continental interiors are away from any water bodies so they cannot experience the moderating effect of the water bodies. Therefore, the climate of continental interiors is of comparatively extreme type.</p>`
                },
                { 
                    question: "<p>Coastal climates are equable</p>",
                    answer: `<p>The sea has a moderating effect on the climate of coastal areas due to sea and land breezes. Coastal areas generally have more moderate temperatures than inland areas because of the heat capacity of the ocean. Therefore, coastal climates are equable.</p>`
                },
                { 
                    question: "<p>South-facing slopes are much warmer than north facing slopes in the Northern Hemisphere.</p>",
                    answer: `<p>Slopes facing south in the northern hemisphere are warmer than those facing north because the sun rays strike the south facing slopes at a steeper angle than they do at the north facing slopes.</p>`
                },
                { 
                    question: "<p>The amount of insolation received on the earth surface is not uniform.</p>",
                    answer: `<p>The amount of insolation received is maximum at the equator. It goes on reducing with higher latitudes and is minimum at the poles. This is because in low latitudes the Sun's rays are direct and have to travel a lesser extent through the atmosphere. Hence, the heat of these rays is more intense. But in higher latitudes, the Sun's rays are slanting and have to pass through a greater extent of atmosphere. Hence, these rays lose heat on the way and minimum insolation occurs at the poles.</p>`
                },
                { 
                    question: "<p>Hill stations are cooler even in the summer.</p>",
                    answer: `<p>With the increase of height, temperature decreases. For every 166 metres the temperature falls by 1°C. This is known as Normal Lapse Rate. This is the reason why hill stations are cooler even in the summer.</p>`
                },
                { 
                    question: "<p>Draw a neat diagram showing the Effect of Latitude on Solar Insolation.</p>",
                    answer: `<p>Below diagram shows the Effect of Latitude on Solar Insolation:</p>`
                },
                { 
                    question: "<p>The process through which heat passes from warmer to colder substances as long as temperature difference exists, is known as</p>",
                    answer: `<p>Conduction</p>`
                },
                { 
                    question: "<p>Which of the following reasons is responsible for the minimum insolation at Poles?</p>",
                    answer: `<p>All the above</p>`
                },
                { 
                    question: "<p>Which of the following factor/factors are responsible for unequal temperature distribution on the earth?</p>",
                    answer: `<p>All the above</p>`
                },
                { 
                    question: "<p>'Latitude is one of the important factors for controlling the temperature' — which of the following fact justifies this statement?</p>",
                    answer: `<p>The areas in the high latitude are not very hot as compared to the equatorial regions.</p>`
                },
                { 
                    question: "<p>'Forest areas are cooler than the open places'- which of the following reasons is responsible for it?</p>",
                    answer: `<p>The vegetation cover checks the radiation from the upper layer of the soil.</p>`
                },
                { 
                    question: "<p>'Tropical Deserts have high diurnal range temperature'— which of the following factor/factors responsible for it?</p>",
                    answer: `<p>All the above</p>`
                },
                { 
                    question: "<p>Inversion of temperature mainly occurs during which season?</p>",
                    answer: `<p>Winter</p>`
                },
                { 
                    question: "<p>The surface temperature of Sun is approximately</p>",
                    answer: `<p>6000°c</p>`
                },
                { 
                    question: "<p>Mumbai enjoys equable climate but Nagpur experiences extreme climate. Which of the following factor is responsible for this fact?</p>",
                    answer: `<p>Mumbai is nearer to the coast than Nagpur.</p>`
                },
                { 
                    question: "<p>Solar radiation provides more than ............... of energy that heats the Earth.</p>",
                    answer: `<p>99.9%</p>`
                },
            ]
        },
        { 
            title: "Atmospheric Pressure and Winds",
            questions: [
                { 
                    question: "<p>What do you mean by the term 'Atmospheric Pressure'?</p>",
                    answer: `<p>Atmospheric pressure refers to the force per unit area exerted against a surface by the weight of the air above that surface.</p>`
                },
                { 
                    question: "<p>Name the instrument used to measure atmospheric pressure.</p>",
                    answer: `<p>The atmospheric pressure is measured with the help of Mercury Barometer or aneroid barometer.</p>`
                },
                { 
                    question: "<p>How is the atmospheric pressure caused?</p>",
                    answer: `<p>The atmosphere can be considered as a closed container bounded by land and sea from below and force of gravity from above that does not allow air to escape to outer space. The gas molecules are in a constant state of collision and move freely. These molecules exert pressure which causes atmospheric pressure.</p>`
                },
                { 
                    question: "<p>Name the factors which affects the atmospheric pressure of a place.</p>",
                    answer: `<ol><li>Altitude</li><li>Temperature</li><li>Earth rotation</li></ol>`
                },
                { 
                    question: "<p>Name the world's pressure belts.</p>",
                    answer: `<ol><li>Equatorial low pressure belt (5°N to 5°S)</li><li>Sub-tropical high pressure belt (25° to 35° N and S)</li><li>Sub-polar low pressure belt (55° to 60° N and S)</li><li>Polar high pressure belt (75° to 90° N and S)</li></ol>`
                },
                { 
                    question: "<p>What is Ferrel's Law?</p>",
                    answer: `<p>Any object or fluid moving in the northern hemisphere tends to be deflected to the right of its path of motion. In the southern hemisphere, a similar deflection is toward the left of the path of motion. This is known as Ferrel's law. It states the direction of action of the Coriolis Force.</p>`
                },
                { 
                    question: "<p>What are Doldrums? Why are they called so?</p>",
                    answer: `<p>A zone of low pressure lying parallel to equator where North-East and South-East trade winds converge and meet each other, producing calm and light surface winds and a strong upward movement of air is called Doldrums.</p>`
                },
                { 
                    question: "<p>What are Trade Winds? How are they caused?</p>",
                    answer: `<p>Trade Winds are the surface winds which move from the Horse Latitudes to the Doldrums. In the Northern Hemisphere, they are the North-East trades and in the Southern Hemisphere, the South-East trades.</p>`
                },
                { 
                    question: "<p>What are Westerlies?</p>",
                    answer: `<p>The Westerly Winds which blow with great frequency and regularity in regions lying on the poleward sides of the subtropical high pressure areas or Horse latitudes are known as the Anti-Trade Winds or Westerlies.</p>`
                },
                { 
                    question: "<p>What are Roaring Forties and Furious Fifties? Why are they called so?</p>",
                    answer: `<p>The Roaring Forties and Furious Fifties are westerlies blowing in Southern Hemisphere. They are called so because they blow from 40° and 50° S with great strength. Their strength is because of obstruction-less path.</p>`
                },
                { 
                    question: "<p>Name the Planetary Winds.</p>",
                    answer: `<p>Permanent Winds blowing regularly in the same direction, in certain latitudinal zones, corresponding to the major pressure belts of the world are known as Planetary winds. They are also known as prevailing winds.</p>`
                },
                { 
                    question: "<p>What does Pressure Gradient mean?</p>",
                    answer: `<p>The decrease of pressure between two points along a line perpendicular to the isobars divided by the distance between the points is called the Pressure Gradient. It can be defined as the rate of change of pressure per unit horizontal distance.</p>`
                },
                { 
                    question: "<p>What are causes and consequences of shifting of pressure belts?</p>",
                    answer: `<ol><li>It influences the areas of Mediterranean climate and the position of Westerlies which move northward in the summer season (June) and southward in the winter (December) season.</li><li>The summer and winter monsoons and the complete reversal of wind direction are also the results of shift of pressure belts.</li><li>Both the trade and anti-trade winds are thus closely influenced by the shift of belts.</li></ol>`
                },
                { 
                    question: "<p>What is Coriolis Force? What is its result?</p>",
                    answer: `<p>If the earth did not rotate on its axis, winds would follow the direction of the pressure gradient. Instead, Earth's rotation produces a force which tends to turn the flow of air. This force is called the Coriolis Force. It results in the deflection of air towards the right in the Northern Hemisphere and toward the left in the Southern Hemisphere.</p>`
                },
                { 
                    question: "<p>What are Local Winds? Give examples.</p>",
                    answer: `<p>Local winds are types of winds which blow for a short duration and affect the climate of the region where they blow. These winds originate in a specific region and blow over a limited area. Local winds are generally known by a common name throughout the world. Some examples of local winds are Loo, Mistral, Fohn, Chinook, Norwester, etc.</p>`
                },
                { 
                    question: "<p>What are Land and Sea breezes?</p>",
                    answer: `<p>The Land Breeze is a diurnal wind blowing from the land out to sea. It is caused by the differential cooling of land and sea. The cooling of the air over the land by radiation during the night causes the air to descend and flow seawards. Therefore during the night there is low pressure over the heated sea and high pressure over land. Hence, winds blow from land to sea during night.</p>`
                },
                { 
                    question: "<p>Explain a sea breeze with the help of a diagram.</p>",
                    answer: `<p>The Sea Breeze develops along sea coasts or large inland water bodies in summer when the land heats much faster than the water on a clear day and a low pressure develops over the land. Under these conditions, in the day, the air blows from the sea to the land as there is high pressure on the sea and low pressure over the land. Below diagram explains the formation of sea breeze:</p>`
                },
                { 
                    question: "<p>What are Monsoon Winds? How are they caused?</p>",
                    answer: `<p>Monsoon Winds are periodic winds like the sea and land breezes on a large scale. The monsoon is a seasonal wind which blows in a fixed direction during a particular season. It reverses its direction as the season changes. They are divided into two Wind Systems the Summer Monsoon and the Winter Monsoon.</p>`
                },
                { 
                    question: "<p>In what way does the land and sea breeze affect the temperature of a place?</p>",
                    answer: `<p>Land and sea breezes are local periodic winds on a diurnal basis. They develop due to differential heating of land and sea. These winds have a moderating effect on the temperature of the place. The temperature is neither too hot nor too cold. These winds also provide relief from the stagnant humid heat.</p>`
                },
                { 
                    question: "<p>What is a Cyclone? How is it caused?</p>",
                    answer: `<p>Cyclones are strong winds which can blow at any place and time. These are the centres of low pressure which may last for a few days or few hours. Cyclone winds blow with great speed towards the centre which is the eye of the storm. The movement of wind is anticlockwise in northern hemisphere and clockwise in southern hemisphere.</p>`
                },
                { 
                    question: "<p>Describe the weather conditions associated with the cyclones.</p>",
                    answer: `<p>Due to extreme heat, there is development of a strong low pressure area which initiates cyclones. They are generally associated with heavy rains, cloudy skies and stormy weather. After cyclones, the weather becomes pleasant.</p>`
                },
                { 
                    question: "<p>What are Anticyclones? How are they caused?</p>",
                    answer: `<p>Anticyclone is a high pressure area. In the Northern Hemisphere, winds blow in a clockwise spiral away from the centre while in Southern Hemisphere, winds blow in an anti-clockwise spiral.</p>`
                },
                { 
                    question: "<p>What are Tropical and Temperate Cyclones?</p>",
                    answer: `<p>Tropical Cyclone — Tropical Cyclones start within 8° and 15° - 20° North and South of the Equator where surface-sea temperature reaches 27°C. The air above the warm sea is heated and rises up. This causes the low pressure. The weather system produces heat which increases the wind speed. These cause the tropical cyclones to sustain itself. These cyclones are associated with high temperature and high humidity.</p>`
                },
                { 
                    question: "<p>What are Variable Winds?</p>",
                    answer: `<p>Variable winds are those winds which blow in a small area and are related to the pressure systems. They are known as variable winds as they do not blow in a definite direction and their speed and velocity varies with the pressure system. Two main kinds of variable winds are cyclones and anti-cyclones.</p>`
                },
                { 
                    question: "<p>Name two special types of tropical cyclones and for each name the specific region where it is experienced.</p>",
                    answer: `<ol><li>Typhoons in South China Sea.</li><li>Hurricanes in Caribbean Sea.</li></ol>`
                },
                { 
                    question: "<p>Name an area where typhoons are experienced.</p>",
                    answer: `<p>Typhoons are experienced in South China Sea.</p>`
                },
                { 
                    question: "<p>What are lsobars?</p>",
                    answer: `<p>Isobars are imaginary lines drawn on a map to join places having same or equal mean atmospheric pressure reduced to a sea level.</p>`
                },
                { 
                    question: "<p>Name the factors affecting air pressure.</p>",
                    answer: `<ol><li>Altitude.</li><li>Temperature.</li><li>Earth rotation.</li></ol>`
                },
                { 
                    question: "<p>How are Weather Maps important for us?</p>",
                    answer: `<p>Weather maps consist of isobars to show atmospheric pressure because different pressure systems cause different types of weathers. The spacing of isobars indicate the pressure gradient. The closer the isobars, the steeper the gradient of pressure and the weather is stormy. When isobars are far apart from each other, there is a little difference in the atmospheric pressure. Therefore, weather maps are important in weather forecasting.</p>`
                },
                { 
                    question: "<p>List the main causes for development of local winds.</p>",
                    answer: `<ol><li>Unequal heating of land and sea resulting into land and sea breezes.</li><li>Heating and cooling of the mountain slopes.</li><li>Local winds originating because of the deformation of air currents, crossing of mountain ranges and physical barriers.</li><li>Convectional Local Winds caused by steep pressure gradients and steep variations in local temperatures.</li></ol>`
                },
                { 
                    question: "<p>What are Jet Streams?</p>",
                    answer: `<p>Jet streams are fast flowing narrow air current found in the upper atmosphere or in troposphere of some planets including earth. The main jet streams are located near the altitude of the tropopause.</p>`
                },
                { 
                    question: "<p>What is wind? How does it originate?</p>",
                    answer: `<p>The horizontal movement of air relative to earth's surface, produced essentially by air pressure differences from place to place and also influenced by the Coriolis force and surface friction is called Wind.</p>`
                },
                { 
                    question: "<p>Name the important pressure belts of the Earth.</p>",
                    answer: `<ol><li>Equatorial low pressure belt (5°N to 5°S)</li><li>Sub-tropical high pressure belts (25° to 35° N and S)</li><li>Sub polar low pressure belts (55° to 60° N and S)</li><li>Polar high pressure belts (75° to 90° N and S)</li></ol>`
                },
                { 
                    question: "<p>What is meant by permanent winds? How do they derive their name?</p>",
                    answer: `<p>The winds that blow constantly throughout the year in a particular area, due to general pressure belts, are called permanent winds. For example: Trade winds, anti-trade winds and polar easterlies.</p>`
                },
                { 
                    question: "<p>What are Trade Winds called in the Northern Hemisphere?</p>",
                    answer: `<p>Trade Winds are called the North-east Trades in the Northern Hemisphere.</p>`
                },
                { 
                    question: "<p>What are Trade Winds called in the Southern Hemisphere?</p>",
                    answer: `<p>Trade Winds are called South-east trades in the Southern Hemisphere.</p>`
                },
                { 
                    question: "<p>What are periodic winds? How are they caused?</p>",
                    answer: `<p>Periodic winds are the seasonal winds that reverse their direction with season. These winds blow at regular intervals or in regular cycles.</p>`
                },
                { 
                    question: "<p>Name any two local winds.</p>",
                    answer: `<ol><li>Chinook</li><li>Loo</li></ol>`
                },
                { 
                    question: "<p>Name the two wind systems of Monsoons. Where are they experienced?</p>",
                    answer: `<ol><li>The Summer Monsoon</li><li>The Winter Monsoon</li></ol>`
                },
                { 
                    question: "<p>Winds</p>",
                    answer: `<p>The horizontal movements of air relative to earth's surface, produced essentially by air pressure differences from place to place, also influenced by the Coriolis force and surface friction are called Winds.</p>`
                },
                { 
                    question: "<p>Atmospheric Pressure</p>",
                    answer: `<p>The force exerted on the surface by the air above it as gravity pulls it towards earth is called atmospheric pressure.</p>`
                },
                { 
                    question: "<p>Cyclones</p>",
                    answer: `<p>A dynamically or thermally caused low pressure area of converging and ascending air flows is known as Cyclone.</p>`
                },
                { 
                    question: "<p>El Nino</p>",
                    answer: `<p>El Nino is a warm ocean current that occasionally appears off the coast of Peru in South America as a temporary replacement of the Cold Peru Current.</p>`
                },
                { 
                    question: "<p>Isobars</p>",
                    answer: `<p>Isobars are imaginary lines drawn on a map to join places having same or equal mean atmospheric pressure reduced to a sea level.</p>`
                },
                { 
                    question: "<p>Coriolis Effect</p>",
                    answer: `<p>The Coriolis Effect is the effect produced by a coriolis force, namely, the tendency of all particles of matter in motion on the earth's surface to be deflected to the right in the Northern Hemisphere and to the left in the Southern Hemisphere.</p>`
                },
                { 
                    question: "<p>Summer and Winter Monsoons</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Cyclone and Anticyclone</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Tropical and Temperate Cyclones</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Trade and Anti-Trade Winds</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Permanent and Seasonal Winds</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Mountain Breeze and Valley Breeze</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Land and Sea Breeze</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>As we go higher upwards, the atmospheric pressure decreases.</p>",
                    answer: `<p>As we go higher upwards, the atmospheric pressure decreases at an average rate of 34 millibars per 300 metres of latitude. It is because the air in the lower layer of the atmosphere is denser than at higher level. The air is denser in lower layer because of gravitational pull of earth and also because the lower layers are compressed under upper layers of atmosphere.</p>`
                },
                { 
                    question: "<p>The Tropical Belt of Calms is also known as the Horse Latitude.</p>",
                    answer: `<p>The Tropical Belt of Calms is also known as the Horse Latitude because in olden days because of the absence of surface winds ships had to unload the cargo to make them lighter. At times the cargo used to be horses.</p>`
                },
                { 
                    question: "<p>The winds are deflected to the right in the Northern Hemisphere.</p>",
                    answer: `<p>The rotation of the earth produces another force other than the pressure force which is called the Coriolis force. It causes deflection of winds. Thus, the winds are directed to the right of their flow in the Northern Hemisphere due to Coriolis effect.</p>`
                },
                { 
                    question: "<p>The Roaring Forties and Furious Fifties are found in the Southern Hemisphere.</p>",
                    answer: `<p>The Roaring Forties and Furious Fifties are found in the Southern Hemisphere as they do not face any obstruction to lower their strength and speed.</p>`
                },
                { 
                    question: "<p>There is seasonal shifting in pressure belts.</p>",
                    answer: `<ol><li>At the time of Summer solstice the Sun is vertical over the Tropic of Cancer and therefore, all the pressure belts except the northern polar high pressure belt shift northward.</li><li>After Autumn Equinox, there is southward migration of the Sun which becomes vertically over the Tropic of Capricorn at the time of Winter Solstice and therefore the pressure belt shifts southward except in the southern polar high pressure belt.</li><li>The Sun is overhead over the Equator at the time of Spring Equinox and Autumn Equinox and hence all the pressure belts occupy their normal positions.</li></ol>`
                },
                { 
                    question: "<p>Cyclones cause heavy damage to life and property.</p>",
                    answer: `<p>Cyclone Winds blow at a great speed, usually at a rate of 100 - 150 km/h, and form a fierce storm. Therefore, they cause heavy damage to life and property.</p>`
                },
                { 
                    question: "<p>High pressure prevails over landmasses during winter.</p>",
                    answer: `<p>During winters the land masses are cooler than the water bodies. The air over the land masses is cooler and condensed, creating high pressure over the land masses while the air over water bodies becomes heated and rises up creating low pressure over the water bodies.</p>`
                },
                { 
                    question: "<p>Cyclones mostly occur in summer in tropical regions.</p>",
                    answer: `<p>Cyclones require a sea surface temperature of about 26°C to develop and maintain their strength. They also need a high relative humidity. These conditions are only found in the tropical oceans during summer. Therefore, cyclones mostly occur in summer in the tropical regions.</p>`
                },
                { 
                    question: "<p>Chinooks are called snow eater.</p>",
                    answer: `<p>The word Chinook means snow eater. Chinooks are warm, dry, South-Westerly adiabatic winds which blows down the slopes of the Rockies in parts of U.S.A. They are known for their ability to rapidly melt snow and ice. This can lead to a rapid disappearance of snow cover, even in the middle of winter. Hence they are called snow eater.</p>`
                },
                { 
                    question: "<p>Equatorial region has low pressure belt.</p>",
                    answer: `<p>The equatorial region has a low-pressure belt because the Sun's rays strike the equator almost directly, leading to intense heating of the air near the surface. As pressure is inversely proportional to temperature, hence due to high temperatures in equatorial region, low pressure belt develops there.</p>`
                },
                { 
                    question: "<p>Draw a diagram to show the planetary wind system showing the direction of winds.</p>",
                    answer: `<p>Below is the diagram of the planetary wind system showing the direction of winds:</p>`
                },
                { 
                    question: "<p>Draw a diagram showing the formation of land and sea breeze.</p>",
                    answer: `<p>Below diagram shows formation of land breeze:</p>`
                },
                { 
                    question: "<p>Draw a labelled diagram of cyclone in both the Hemispheres.</p>",
                    answer: `<p>Below is the labelled diagram of a cyclone in Northern Hemisphere:</p>`
                },
                { 
                    question: "<p>Draw a labelled diagram of an anticyclone in the Northern Hemisphere.</p>",
                    answer: `<p>Below is the labelled diagram of an anticyclone in Northern Hemisphere:</p>`
                },
                { 
                    question: "<p>Draw a labelled diagram to show the Major Pressure Belts of the World.</p>",
                    answer: `<p>Below diagram shows the Major Pressure Belts of the World:</p>`
                },
                { 
                    question: "<p>Air pressure decreases with</p>",
                    answer: `<p>Increasing temperature &amp; altitude</p>`
                },
                { 
                    question: "<p>............... are the imaginary lines drawn on the weather map to join the places having same or equal mean atmospheric pressure reduced to sea level.</p>",
                    answer: `<p>Isobars</p>`
                },
                { 
                    question: "<p>Which of the following forces is responsible for deflecting the winds from their original position?</p>",
                    answer: `<p>Coriolis force</p>`
                },
                { 
                    question: "<p>Which of the following pressure belts lies between 55° - 60° North &amp; South latitude?</p>",
                    answer: `<p>Sub polar low pressure belt</p>`
                },
                { 
                    question: "<p>Which of the following permanent winds blows from subtropical high pressure belt to equatorial low pressure belt in Northern Hemisphere?</p>",
                    answer: `<p>North east trade winds</p>`
                },
                { 
                    question: "<p>Which of the following statements is not correct?</p>",
                    answer: `<p>Land breeze &amp; sea breeze are the phenomena of interior parts of the country.</p>`
                },
                { 
                    question: "<p>In which of the following areas the tropical cyclone is known as Willy Willies?</p>",
                    answer: `<p>Australia</p>`
                },
                { 
                    question: "<p>Which of the following difference between the cyclone &amp; anti cyclone is correct?</p>",
                    answer: `<p>Cyclone is associated with cloudy skies &amp; very heavy rainfall but anti-cyclone is associated with clear skies &amp; dry conditions.</p>`
                },
                { 
                    question: "<p>Which of the following is not a local wind?</p>",
                    answer: `<p>Trade wind</p>`
                },
                { 
                    question: "<p>El Nino occurs at the coast of</p>",
                    answer: `<p>Peru</p>`
                },
                { 
                    question: "<p>Summer monsoon blows from which direction?</p>",
                    answer: `<p>South west</p>`
                },
                { 
                    question: "<p>Which of the following wind is extremely important for the aviation process?</p>",
                    answer: `<p>Jet stream</p>`
                },
            ]
        },
        { 
            title: "Humidity",
            questions: [
                { 
                    question: "<p>What is evaporation? Name the factors that affect evaporation.</p>",
                    answer: `<ol><li>Air Pressure — The rate of evaporation is higher when pressure is low.</li><li>Availability of water — The rate of evaporation is higher where large water bodies are present.</li><li>Temperature — Evaporation is directly proportional to temperature. The warmer the evaporating surface, the higher the rate of evaporation.</li><li>Relative humidity — The rate of evaporation in dry air is more than in moist air.</li><li>Area of evaporating surface — Larger area of evaporating surface offers more evaporation.</li></ol>`
                },
                { 
                    question: "<p>What is condensation? When does condensation occur?</p>",
                    answer: `<ol><li>Air is cooled below its dew point.</li><li>The dew point temperature falls below the freezing point.</li></ol>`
                },
                { 
                    question: "<p>How does air gets cooled?</p>",
                    answer: `<p>As the warm air rises up, the air pressure drops and the gases expand. The temperature is lower at higher altitudes which results in cooling of the air as well.</p>`
                },
                { 
                    question: "<p>Name the different forms of condensation.</p>",
                    answer: `<ol><li>Clouds</li><li>Dew</li><li>Frost</li><li>Fog</li><li>Mist</li></ol>`
                },
                { 
                    question: "<p>What is precipitation?</p>",
                    answer: `<p>Precipitation is defined as water in liquid or solid forms falling to the Earth. It can be termed as deposition of atmospheric moisture and is the most important phase of the hydrological cycle. Precipitation cam be in the form of hail, snow, sleet,rain etc.</p>`
                },
                { 
                    question: "<p>State the conditions necessary for precipitation.</p>",
                    answer: `<ol><li>The air must be saturated.</li><li>The temperature at which condensation takes place must be present.</li><li>The air must contain small particles of matter such as dust around which droplets form.</li><li>The air must be cooled below its dew point.</li></ol>`
                },
                { 
                    question: "<p>What are the different forms of precipitation?</p>",
                    answer: `<ol><li>Rain</li><li>Snow</li><li>Hail</li><li>Sleet</li><li>Drizzle</li><li>Fog</li><li>Frost</li></ol>`
                },
                { 
                    question: "<p>How is the convectional rainfall caused?</p>",
                    answer: `<p>The two factors necessary to cause convectional rainfall are intense heating of the surface and abundant supply of moisture. Due to intense heating of the surface, the surface air gets heated and expands and rises up conventionally holding moisture. It is thereby cooled adiabatically and its temperature falls below the dew point, forming Cumulonimbus cloud, which gives heavy rains. Such rainfall is called convectional rainfall.</p>`
                },
                { 
                    question: "<p>Give two characteristic features of the convectional rainfall. Name an area which commonly experiences this type of rainfall.</p>",
                    answer: `<ol><li>This type of rain mostly occurs at 4 o'clock in the afternoon.</li><li>It is always torrential accompanied by lightening and thunder.</li></ol>`
                },
                { 
                    question: "<p>What are the chief requirements for orographic rainfall to be experienced? Name a region which experiences it.</p>",
                    answer: `<ol><li>moisture laden winds.</li><li>mountain in the path of the winds.</li></ol>`
                },
                { 
                    question: "<p>What is the meaning of rain shadow? Give an example.</p>",
                    answer: `<p>The area on the leeward slope of a mountain range, in the shadow of the mountain where precipitation is greatly reduced compared to windward slope is called rain shadow.</p>`
                },
                { 
                    question: "<p>What is cyclonic rainfall? Name a region which experiences this type of rainfall.</p>",
                    answer: `<p>Cyclonic rainfall occurs when a mass of warm air (warm front) meets the mass of cold air (cold Front). The warm air is forced up above the cooler air as it is lighter. Condensation takes place and clouds are formed, followed by rains.</p>`
                },
                { 
                    question: "<p>State the principal factors affecting the distribution of rainfall. Which are the wettest regions of the world?</p>",
                    answer: `<ol><li>Latitude — The latitudinal belt of maximum precipitation is found in the equatorial Zone between 8° N to 8° S along with the seasonal shifting of the Doldrums. The shifting of the Trade Winds causes winter rains in Mediterranean Regions in Europe. Trade Winds blow from North-East to South-West, they shed maximum moisture on the Eastern margins of the continents and western margins are left dry.</li><li>Continents and oceans — Places which are located away from the sea in the interior receive relatively less precipitation than coastal areas.</li><li>Mountain Barriers — They play a great role in the world distribution of precipitation by causing orographic rainfall on the windward side while the leeward side remains dry or receives little rainfall.</li></ol>`
                },
                { 
                    question: "<p>Give two examples of the areas that receive less than 25 cm of rainfall.</p>",
                    answer: `<ol><li>Desert area of Rajasthan</li><li>Desert of China(Gobi desert).</li></ol>`
                },
                { 
                    question: "<p>Explain the process of precipitation in brief.</p>",
                    answer: `<p>The formation of clouds by condensation takes place due to adiabatic cooling. For precipitation air must be saturated and temperature below dew point must be reached. The air must contain small particles of matter such as dust around which droplets form. When the cold droplets, ice pellets or ice crystals grow to such a large size as to overcome the normal buoyancy, precipitation occurs.</p>`
                },
                { 
                    question: "<p>Name the three types of rainfall.</p>",
                    answer: `<ol><li>Convectional rainfall</li><li>Orographic rainfall</li><li>Cyclonic Rainfall</li></ol>`
                },
                { 
                    question: "<p>Mention two characteristics of orographic rainfall.</p>",
                    answer: `<ol><li>When the moisture laden wind rises along the windward slope of the mountain the pressure on it decreases, the air expands and cools.</li><li>On the leeward side, as the dry air descends the mountain slopes, the pressure decreases.</li></ol>`
                },
                { 
                    question: "<p>Name the factors necessary for convectional rainfall.</p>",
                    answer: `<ol><li>Intense heating of the surface.</li><li>Abundant supply of moisture</li></ol>`
                },
                { 
                    question: "<p>Where does cyclonic rainfall occur?</p>",
                    answer: `<p>Cyclonic rainfall is common throughout the Doldrums where the Trade winds meet.</p>`
                },
                { 
                    question: "<p>Absolute Humidity</p>",
                    answer: `<p>Absolute humidity is the actual amount of water vapour present in a given volume of air, regardless of temperature. It is expressed as grams of moisture per cubic metre of air (g/m<sup>3</sup>). The amount of absolute humidity decreases as we go towards the poles. The maximum absolute humidity of warm air at 30°C is approximately 30 gm of water vapour — 30 g/m<sup>3</sup>.</p>`
                },
                { 
                    question: "<p>Relative Humidity</p>",
                    answer: `<p>Relative Humidity refers to the water vapour present in the air as compared to the maximum amount of water the air can hold at that temperature. It can be defined as \"a ratio between the actual amount of water vapour present in the air and the maximum amount of water the air can hold at that temperature.\" It is always expressed in percentage.</p>`
                },
                { 
                    question: "<p>Dew Point</p>",
                    answer: `<p>The temperature at which the air becomes fully saturated with water vapour is known as dew point. It is used to predict dew, frost, fog etc. It can also indicate air's actual water vapour content.</p>`
                },
                { 
                    question: "<p>Clouds</p>",
                    answer: `<p>Clouds are defined as a visible aggregation of minute water droplets/ice particles in the air above ground level. Clouds can be classified into Low, medium and High, on the basis of their height.</p>`
                },
                { 
                    question: "<p>Hail</p>",
                    answer: `<p>Hailstones are small round balls of ice with frosted appearance. They are formed of concentric layers of ice much like an onion. Hail stones range from 0.5 to 5 cm in diameter. They occur only from the Cumulonimbus cloud type.</p>`
                },
                { 
                    question: "<p>Fog</p>",
                    answer: `<p>Condensation of water vapour near the surface of earth leads to formation of fog. Fog is formed when warm and moist air moves along the surface of colder region. It affects visibility. Fog makes it difficult to see the objects beyond 1 km.</p>`
                },
                { 
                    question: "<p>Snow</p>",
                    answer: `<p>Snow consists of crystals or grains of ice which grow directly from the water vapour. Snow displays beautiful forms of symmetrical patterns that are formed in winter when the temperature is below freezing point.</p>`
                },
                { 
                    question: "<p>Precipitation</p>",
                    answer: `<p>Precipitation is defined as water in liquid or solid forms falling to the Earth. Hail, snow, sleet, rain and drizzle are the common forms of precipitation.</p>`
                },
                { 
                    question: "<p>Evaporation and Condensation</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Convectional Rainfall and Orographic Rainfall</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Dew and Frost</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Convectional currents generally lead to rain.</p>",
                    answer: `<p>Convectional currents generally lead to rain as due to intense heating of the surface, the surface air gets heated and expands and rises up conventionally holding moisture. It is thereby cooled adiabatically and its temperature falls below the dew point, forming Cumulonimbus clouds, which give heavy rains.</p>`
                },
                { 
                    question: "<p>The rain-shadow areas are generally dry.</p>",
                    answer: `<p>The sudden ascent of warm moist air on the windward sides causes cooling of air, leading to condensation and precipitation.<br/>On the contrary, on descending the leeward slope, a decrease in altitude increases both the pressure and the temperature, leading the air to get compressed and warm. Consequently, the relative humidity drops and there is evaporation and little or no precipitation in the rain shadow area. Hence, a rain shadow area is generally dry.</p>`
                },
                { 
                    question: "<p>Cyclonic rainfall is common in the belt of westerlies.</p>",
                    answer: `<p>Cyclonic rainfall occurs when cold and warm fronts meet. The westerlies blow from the the warmer Horse latitudes towards the poles in north and south hemisphere. This results in the convergence of warm westerlies and cold polar winds, which further results in Cyclonic rainfall. Therefore, Cyclonic rainfall is common in the belt of westerlies.</p>`
                },
                { 
                    question: "<p>Areas of trade winds are usually dry.</p>",
                    answer: `<p>Areas of trade winds are usually dry because trade winds blow from dry mainland. They lack moisture and hence cannot cause rainfall.</p>`
                },
                { 
                    question: "<p>Cyclonic rainfall is also known as frontal rainfall.</p>",
                    answer: `<p>Cyclonic rainfall is also known as frontal rainfall because it occurs when warm front (a mass of warm air) meets the cold front (a mass of cold air).</p>`
                },
                { 
                    question: "<p>Convectional rainfall is also called 4 o'clock shower.</p>",
                    answer: `<p>Convectional rainfall is called 4 o'clock rainfall as this type of rainfall occurs in the afternoon at about 4 o'clock. This is because the heat from the sun builds up throughout the day, causing the air to rise and form clouds that eventually bring rain in late afternoon around 4 o'clock.</p>`
                },
                { 
                    question: "<p>Draw self-explanatory diagrams to show all the three types of rainfall.</p>",
                    answer: `<p>Below diagram shows Convectional rainfall:</p>`
                },
                { 
                    question: "<p>Which of the following statements related to Relative humidity is not correct?</p>",
                    answer: `<p>Relative humidity is measured in degrees.</p>`
                },
                { 
                    question: "<p>The process through which the water changes from its liquid state into water vapour is called</p>",
                    answer: `<p>Evaporation</p>`
                },
                { 
                    question: "<p>Which of the following factor controls the condensation?</p>",
                    answer: `<p>All the above</p>`
                },
                { 
                    question: "<p>Identify the type of rainfall seen here.</p>",
                    answer: `<p>Orographic</p>`
                },
                { 
                    question: "<p>Which of the following regions receive convectional type of rainfall?</p>",
                    answer: `<p>Equatorial zone</p>`
                },
                { 
                    question: "<p>Which of the following regions receive cyclonic type of rainfall?</p>",
                    answer: `<p>All the above</p>`
                },
                { 
                    question: "<p>The principal factors controlling the distribution of precipitation are</p>",
                    answer: `<p>All the above</p>`
                },
                { 
                    question: "<p>Which of the following is not a form of condensation?</p>",
                    answer: `<p>Rain</p>`
                },
                { 
                    question: "<p>The temperature at which air gets saturated is called</p>",
                    answer: `<p>Dew point</p>`
                },
                { 
                    question: "<p>Which of the following is the component of hydrological cycle?</p>",
                    answer: `<p>All of the above</p>`
                },
                { 
                    question: "<p>Fog, dew, mist are mainly formed in</p>",
                    answer: `<p>Winter season</p>`
                },
                { 
                    question: "<p>Which of the following is a low cloud?</p>",
                    answer: `<p>Stratus</p>`
                },
            ]
        },
        { 
            title: "Pollution — Types and Sources",
            questions: [
                { 
                    question: "<p>Define pollution. How is it caused?</p>",
                    answer: `<p>The presence or introduction of a substance in the environment that is harmful or has poisonous effects, is called pollution.</p>`
                },
                { 
                    question: "<p>State the causes of soil pollution.</p>",
                    answer: `<ol><li>Industrial waste — Industrial waste containing chemicals, when dumped in soil, cause soil pollution. For example, fly ash from thermal plants, chemicals from oil refineries pollute the soil.</li><li>Urban commercial and domestic waste — Non-biodegradable wastes like glass, plastic, polythene bags remain in the soil for a long time.</li><li>Chemical fertilizers — Chemical fertilizers when used, disturb the natural balance of the soil. They also contaminate water bodies through run-off.</li><li>Biomedical waste — Hospital wastes containing plastics, metal needles and chemicals are dumped in soil.</li><li>Pesticides — Pesticides show permanent accumulation in soil in the form of nitrates and phosphates.</li></ol>`
                },
                { 
                    question: "<p>State the effects of freshwater pollution. How is it caused?</p>",
                    answer: `<ol><li>Natural sources — The natural sources are gases, animal waste, living organisms, soil, minerals (Nickel, Cobalt, Lead and Mercury etc.) and other pollutants soluble in water.</li><li>Human sources — Urbanization and industrialization have resulted in excessive water pollution. The human sources of water pollution can be classified as follows:<ol><li>Sewage and Domestic waste</li><li>Industrial waste</li><li>Agricultural waste</li><li>Nuclear waste</li><li>Oil spills</li><li>Thermal pollution</li></ol></li></ol>`
                },
                { 
                    question: "<p>How do agricultural wastes harm the environment?</p>",
                    answer: `<ol><li>Agriculture, including commercial livestock and poultry farming is a source of many organic and inorganic pollutants in surface waters. Pesticides from agricultural run off, metals and processed chemicals are also major sources of water pollutants.</li><li>Excess nitrates and phosphates lead to Eutrophication in water bodies which leads to loss of aquatic life and disturbs the aquatic ecosystem.</li><li>Chemical fertilizers and pesticides contain potassium and phosphorus, which are released in water bodies through run-off water. This also affects the plant growth in the soil.</li><li>Radio-tracers extensively used in agriculture are also responsible for radiation pollution.</li></ol>`
                },
                { 
                    question: "<p>Which gases are emitted through refrigerators and air-conditioners?</p>",
                    answer: `<p>Chlorofluorocarbons are the gases emitted through refrigerators and air-conditioners.</p>`
                },
                { 
                    question: "<p>Differentiate between the natural and man-made sources of pollution.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>What is meant by oil spill?</p>",
                    answer: `<p>The leakage or spill of oil in the sea causes a layer of oil spreading on the surface of water. This is known as oil spill. The oil spread on the sea water acts as an insulating layer between air and water. Due to this, the oxygen of the atmosphere cannot mix with water and causes death of aquatic creatures.</p>`
                },
                { 
                    question: "<p>How is oil spill caused?</p>",
                    answer: `<ol><li>waste oil from city drains, tankers</li><li>leakage from oil well</li><li>accidental oil spill</li></ol>`
                },
                { 
                    question: "<p>What is meant by radiation?</p>",
                    answer: `<p>The emission of highly charged particles and electro-magnetic rays from radioactive substances is called radiation.</p>`
                },
                { 
                    question: "<p>Explain the following terms :</p>",
                    answer: `<p><strong>(a) Smog</strong> — The term 'smog' is derived from 'smoke + fog' as it is formed due to mixing of smoke and fog. Smog is common in industrial areas in winters. It reduces visibility and is unhealthy for humans, animals and plants.</p>`
                },
                { 
                    question: "<p>Write a short note on the following:</p>",
                    answer: `<p><strong>(a) Radiation</strong> — Radiation pollution is the emission of highly charged particles and electromagnetic rays entering the earth's atmosphere. Sources of radiation may be natural or man-made. Natural sources consist of cosmic rays that pollute the life supporting systems like air, water and soil. Man-made sources consist of waste water from nuclear power stations, mining of uranium ore, waste from hospitals where isotopes are used for diagnostic and therapeutic purposes etc.</p>`
                },
                { 
                    question: "<p>What kind of pollution is produced by brick kilns?</p>",
                    answer: `<p>Brick Kilns cause air pollution. They use coal to burn the bricks. They give out huge quantity of carbon dioxide and particulate matter such as smoke, dust which is very harmful. Brick kilns cause air pollution.</p>`
                },
                { 
                    question: "<p>How is thermal pollution caused?</p>",
                    answer: `<p>The thermal power plants release the recycled hot water into the rivers and sea causing water pollution. The hot water raises the temperature of the water body. It can lead to a decrease in the dissolved oxygen level in the water while also increasing the biological demand of aquatic organisms for oxygen.</p>`
                },
                { 
                    question: "<p>What are the sources of radioactive pollution?</p>",
                    answer: `<ol><li>Natural sources — Cosmic rays emit radioactive radiation.</li><li>Man-made sources — waste water from nuclear power plant, waste generated during nuclear weapon testing, mining and processing of uranium ore, waste generated from hospitals and research laboratories where radioisotopes are used, X-ray waste, nuclear accidents, nuclear bomb explosions and use of radio tracers.</li></ol>`
                },
                { 
                    question: "<p>How do water bodies get contaminated by the effluents from industries?</p>",
                    answer: `<ol><li>Waste and sewage generated by industry can get into the water supply, introducing large organic pollutants into the ecosystem.</li><li>Most of the industries release chemicals like chlorides, sulphides, carbonates, nitrates and metal like Zinc, Mercury, Copper, Chromium, etc. in the rivers or their water sources.</li><li>Water can become contaminated with toxic or radioactive material from the industry, mine sites and abandoned hazardous waste sites.</li><li>Air pollution leads to acid precipitation which lower the overall pH of lakes, streams, and ponds; thereby killing vital plants and affecting the whole food chain.</li><li>Leaching of heavy metals from soil into the water bodies leads to contamination of water. It may kill aquatic flora and fauna.</li></ol>`
                },
                { 
                    question: "<p>What is the difference between primary and secondary pollutants?</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>How does urbanization cause air pollution?</p>",
                    answer: `<ol><li>The burning of fuels in automobiles and aeroplanes lead to emission of Carbon Monoxide and Hydrocarbons containing nitrogen and oxygen.</li><li>Industries like il refinery discharge Ammonia, Hydrocarbons and Sulphur oxide. Aluminium plants release fluoride dust.</li><li>Burning of garbage lead to emission of carbon dioxide and nitrogen oxide.</li><li>Brick kilns also release huge quantity of Carbon dioxide and particulate matters.</li><li>Use of cleansing agents release chemicals like Ammonia and chlorine, causing indoor pollution.</li><li>Use of radioactive elements or use of isotopes(medical purpose) lead to emission of α, β and γ radiations.</li></ol>`
                },
                { 
                    question: "<p>Which of the following reasons is the main cause of Acid rain?</p>",
                    answer: `<p>Due to the emission of Sulphur dioxides in the air</p>`
                },
                { 
                    question: "<p>Which of the following is a secondary pollutant?</p>",
                    answer: `<p>Ozone</p>`
                },
                { 
                    question: "<p>SPM stands for</p>",
                    answer: `<p>Suspended Particulate Matter</p>`
                },
                { 
                    question: "<p>Which of the following chemical compounds is mainly responsible for the formation of ozone hole?</p>",
                    answer: `<p>CFC</p>`
                },
                { 
                    question: "<p>Study the picture and identify which type of environmental pollution is mainly caused by the activity practised here.</p>",
                    answer: `<p>Air pollution</p>`
                },
                { 
                    question: "<p>Study the picture and identify the type of environmental pollution caused here.</p>",
                    answer: `<p>Water pollution</p>`
                },
                { 
                    question: "<p>Thermal pollution is caused by</p>",
                    answer: `<p>The discharge of hot water of thermal power plants in the water body</p>`
                },
                { 
                    question: "<p>Which of the following is not a man-made source of radioactive pollutant?</p>",
                    answer: `<p>Cosmic rays</p>`
                },
                { 
                    question: "<p>Study the picture and identify the type of environmental pollution caused here.</p>",
                    answer: `<p>Noise pollution</p>`
                },
                { 
                    question: "<p>Which of the following is not a man-made source of air pollution?</p>",
                    answer: `<p>Forest fires</p>`
                },
                { 
                    question: "<p>Noise pollution is prevalent in towns and cities.</p>",
                    answer: `<p>Noise pollution is prevalent in towns and cities due to a large number of vehicles on the roads, numerous construction projects like building of flyovers, metro and high rises, aeroplanes, trains, loud-speakers, high-pitched music, fire crackers, etc. Moreover, often no noise barriers or sound-absorbing materials are installed in public spaces and noise limits are not enforced.</p>`
                },
                { 
                    question: "<p>Acid rainfall is a result of the burning of fossil fuel.</p>",
                    answer: `<p>Acid rainfall is a result of the burning of fossil fuels because the combustion of these fuels releases pollutants such as sulphur dioxide (SO<sub>2</sub>) and nitrogen dioxide (NO<sub>2</sub>) into the atmosphere. These pollutants can react with water, oxygen, and other chemicals in the atmosphere to form acidic compounds, such as sulphur acid (H<sub>2</sub>SO<sub>4</sub>) and nitric acid (HNO<sub>3</sub>). These acidic compounds then mix with precipitation, such as rain or snow, and fall to the ground as acid rainfall.</p>`
                },
            ]
        },
        { 
            title: "Effects of Pollution",
            questions: [
                { 
                    question: "<p>What is the effect of air pollution on life in general?</p>",
                    answer: `<ol><li>Breathing polluted air puts us at a higher risk of asthma.</li><li>When exposed to ground ozone for 6 to 7 hours, people suffer from respiratory inflammation.</li><li>Air pollutants are mostly carcinogens, and living in a polluted area can put people at risk of cancer.</li><li>Damages the immune system, endocrine and reproductive systems.</li><li>High level of air pollution has been associated with higher incidents of heart problems.</li><li>The toxic chemicals released into the air settle into plants and water sources. Animals eat the contaminated plants and drink the water. The poison then travels up the food chain.</li></ol>`
                },
                { 
                    question: "<p>Name any three human activities which cause pollution of air.</p>",
                    answer: `<ol><li>Release of harmful gases and smoke from the industries.</li><li>Burning of fossil fuels in industries and vehicles.</li><li>Mining and construction activities release dust and other toxic particulate matter.</li></ol>`
                },
                { 
                    question: "<p>How does increase in carbon dioxide affect atmosphere?</p>",
                    answer: `<p>The increase in carbon dioxide in the atmosphere leads to increase in global temperature as it traps heat. This phenomenon of increasing global temperature is called global warming. It can affect us in many ways as it leads to climate change, frequent forest fires, melting of polar ice caps and rise in sea level, etc. It can also lead to extinction of many species.</p>`
                },
                { 
                    question: "<p>What are the adverse effects of radiation on human beings?</p>",
                    answer: `<ol><li>It causes radiation sickness or acute radiation syndrome.</li><li>It can cause Leukaemia, abnormalities, carcinogenesis and shortening of life.</li><li>It causes genetic diseases due to chromosomal mutation and point mutation.</li><li>The genetic mutation continues for many generations causing diseases and disabilities.</li></ol>`
                },
                { 
                    question: "<p>What is meant by Global warming?</p>",
                    answer: `<p>The gradual increase in overall temperature of the earth's atmosphere due to increase in amount of green house gases like carbon dioxide and methane, is called global warming.</p>`
                },
                { 
                    question: "<p>How does soil pollution affect human life?</p>",
                    answer: `<ol><li>Chemical fertilizers do damage when they are washed into waterways, lakes and underground aquifers. They also poison water supplies.</li><li>Chemical fertilisers destroy the soil's natural balance. It makes the soil vulnerable to erosion, leaves it lacking in nutrients and starts a vicious cycle in which more fertilisers must be used to make up for soil depletion.</li><li>Arsenic, lead, pesticides and chromium are most harmful substances which are hazardous to humans and lead to diseases.</li></ol>`
                },
                { 
                    question: "<p>Suggest two ways by which atmosphere is affected by industrial pollution.</p>",
                    answer: `<ol><li>They release gases like carbon dioxide which causes global warming.</li><li>They release gases like Nitrogen oxide and sulphur oxide which causes acid rain.</li></ol>`
                },
                { 
                    question: "<p>State the effects of chemicals on human beings.</p>",
                    answer: `<ol><li>Fluorides cause fluorosis, humped back, stiffness of bone joints and dental fluorosis.</li><li>Lead is toxic to both central and peripheral nervous system. It can accumulate in the bones.</li><li>Pesticides can cause tumours, chromosomal alterations, brain damage, impotency and cancer.</li><li>Arsenic, in mild doses, causes nausea, vomiting and stomach burns. In lethal doses, it may cause death due to shock and vascular failure.</li><li>Mercury damages the nervous system. It may lead to insomnia, tremors, bleeding of gums etc. Methyl mercury may cause Minamata disease which is a form of mercury poisoning.</li><li>High nitrate content of water is associated with methemoglobinemia. Infants are most susceptible to Nitrate content.</li></ol>`
                },
                { 
                    question: "<p>What does Eutrophication mean?</p>",
                    answer: `<p>Due to use of excess fertilizers, there is leaching of nitrates and phosphates into water bodies. This causes overgrowth of aquatic plants, covering the surface of water body which further leads to increased biological oxygen demand. Therefore, the process by which the excess phosphates and nitrates lead to overgrowth of algae and other aquatic plants in a water body, depleting the oxygen of water, is called Eutrophication. It leads to death of aquatic animals due to oxygen deficiency.</p>`
                },
                { 
                    question: "<p>Write short notes on:</p>",
                    answer: `<ol><li>Bhopal gas tragedy</li><li>Chernobyl disaster</li></ol>`
                },
                { 
                    question: "<p>Why do people in cities suffer from viral fever, allergies etc.?</p>",
                    answer: `<p>The cities are more polluted due to urbanization. Industries and vehicles release a lot of smoke into the atmosphere. Construction activities lead to dust in the atmosphere. All this makes the air polluted which further leads to fever, allergies and breathing problems etc.</p>`
                },
                { 
                    question: "<p>What are the effects of Noise pollution?</p>",
                    answer: `<ol><li>Sleep disturbances</li><li>Hearing loss</li><li>Interference with communication</li><li>Annoyance</li><li>Headache</li><li>General fatigue</li><li>Rise in blood pressure</li><li>Problems with the digestive system.</li></ol>`
                },
                { 
                    question: "<p>Which of the following substances is responsible for causing fluorosis?</p>",
                    answer: `<p>Fluorides</p>`
                },
                { 
                    question: "<p>Which of the following substances is responsible for causing Minamata disease?</p>",
                    answer: `<p>Mercury</p>`
                },
                { 
                    question: "<p>Which of the following processes is responsible for the depletion of oxygen from the water body?</p>",
                    answer: `<p>Eutrophication</p>`
                },
                { 
                    question: "<p>Bhopal Gas Tragedy(3rd December,1984) was caused by the release of</p>",
                    answer: `<p>MIC</p>`
                },
                { 
                    question: "<p>Chernobyl disaster occurred on April 26, 1986 due to</p>",
                    answer: `<p>Meltdown in a nuclear reactor</p>`
                },
                { 
                    question: "<p>The process by which the toxic compound enter a food chain &amp; increases its concentration in the tissues of organism as it travels up the food chain is called</p>",
                    answer: `<p>Biomagnification</p>`
                },
            ]
        },
        { 
            title: "Pollution — Preventive Measures",
            questions: [
                { 
                    question: "<p>State the advantages of carpooling</p>",
                    answer: `<ol><li>Carpooling reduces each person’s costs, tolls and stress of driving.</li><li>Carpooling is more environment friendly and sustainable way to travel as sharing journeys reduces carbon emissions, traffic congestion on roads and the need for parking spaces.</li></ol>`
                },
                { 
                    question: "<p>Name the alternative modes of transport. In what way does it help to reduce air pollution?</p>",
                    answer: `<p>Use of public transport rather than private vehicles, offers alternative modes of transport.</p>`
                },
                { 
                    question: "<p>How are smoking zones useful in prevention of air pollution?</p>",
                    answer: `<p>Smoking zones is designated area in which smoking is permitted. It helps to keep the work place and public place air clean and protects people from effects of passive smoking.</p>`
                },
                { 
                    question: "<p>Explain the term organic farming. How does organic farming help in saving the soil quality?</p>",
                    answer: `<ol><li>Natural balance of soil is maintained. Chemical fertilizers change the balance of soil.</li><li>Soil texture is improved with use of FYM and compost.</li></ol>`
                },
                { 
                    question: "<p>State any three characteristic features of organic farming.</p>",
                    answer: `<ol><li>Indirect provision of crop nutrients using soil micro-organisms.</li><li>Nitrogen fixation in soils using legumes.</li><li>Weed and pest control based on crop rotation, biological diversity, natural predators and organic manures.</li></ol>`
                },
                { 
                    question: "<p>Refer to the image &amp; identify the type of preventive measure taken to reduce pollution.</p>",
                    answer: `<p>Car pooling</p>`
                },
                { 
                    question: "<p>Which of the following preventive measures is depicted by the symbol?</p>",
                    answer: `<p>No smoking zone</p>`
                },
                { 
                    question: "<p>NEERI stands for</p>",
                    answer: `<p>National Environmental Engineering Research Institute.</p>`
                },
                { 
                    question: "<p>Which of the following statements related to organic farming is not correct?</p>",
                    answer: `<p>It involves the use of chemical fertilizer.</p>`
                },
                { 
                    question: "<p>The following symbol is used in aircraft for</p>",
                    answer: `<p>No smoking</p>`
                },
                { 
                    question: "<p>Car pooling is often encouraged by the authorities.</p>",
                    answer: `<p>Carpooling is more environment friendly and sustainable way to travel as sharing journeys reduces carbon emissions, traffic congestion on roads and the need for parking spaces. Hence, authorities often encourage car pooling especially during period of high pollution or high fuel prices.</p>`
                },
                { 
                    question: "<p>The use of renewable energy resources is of prime importance.</p>",
                    answer: `<p>Renewable energy resources are environment friendly, do not cause pollution, do not contribute to global warming and are available in infinite supply. Hence their use is of prime importance for our future sustainable living.</p>`
                },
            ]
        },
        { 
            title: "Natural Regions of the World",
            questions: [
                { 
                    question: "<p>What do you mean by the term 'Climate'?</p>",
                    answer: `<p>The average weather conditions of temperature and rainfall at a specific place or a region over a period of several decades (around 30 years) is known as climate.</p>`
                },
                { 
                    question: "<p>How have the climatic regions of the world been classified?</p>",
                    answer: `<p>The world climate types have been classified on the basis of world's climate/temperature zones based on latitudes. It is observed that all the places located on a particular latitude would experience similar type of climate, although the local factors may play a certain role.</p>`
                },
                { 
                    question: "<p>Name the different climatic regions of the world.</p>",
                    answer: `<ol><li>Equatorial region</li><li>Tropical grasslands</li><li>Tropical desert</li><li>Tropical monsoon</li><li>Mediterranean</li><li>Temperate grasslands</li><li>Taiga</li><li>Tundra</li></ol>`
                },
                { 
                    question: "<p>Describe the location of the equatorial regions.</p>",
                    answer: `<p>The Equatorial type of climatic regions are found along the Equator at 5°N to 5° on either side. The typical Equatorial climatic features are experienced between 2° and 8°N while swinging of Pressure Belt affects the Rain Belt between 10°N to 10°S.</p>`
                },
                { 
                    question: "<p>Give the other names by which equatorial regions are also popular.</p>",
                    answer: `<p>Equatorial regions are also known as Tropical Wet Climate or Tropical Rainforest Climate.</p>`
                },
                { 
                    question: "<p>Why has the Equatorial Region the hottest climate?</p>",
                    answer: `<p>The Equatorial Region receives maximum amount of incoming radiation (insolation). Therefore, the Equatorial Region has the hottest climate.</p>`
                },
                { 
                    question: "<p>What type of rainfall occurs in Equatorial Regions and why?</p>",
                    answer: `<p>The equatorial areas are known for heavy convectional rainfall. Torrential rain is experienced in the afternoon at 4 o'clock and is generally accompanied by lightning and thunder. Many areas receive about 200 cm rainfall annually.</p>`
                },
                { 
                    question: "<p>State in brief the characteristic features of the Equatorial Type of Climate.</p>",
                    answer: `<ol><li>Equatorial type regions do not experience any season as there is very little change in range of temperature.</li><li>These regions experience average temperature of 26°C throughout the year with a belt of low pressure. Extensive cloud cover and heavy rainfall prevent any rise in temperature.</li><li>The annual range of temperature is about 3°C and diurnal temperature range is about 6°C to 8°C.</li><li>These areas experience heavy torrential rainfall accompanied by lightning and thunder often in the afternoons.</li><li>Humidity is always high.</li></ol>`
                },
                { 
                    question: "<p>Name a few important trees found in the equatorial region. How important are these trees?</p>",
                    answer: `<p>A few important trees found in the equatorial region are mahagony, ebony, rosewood and greenheart. They are used for expensive furniture. Beside this cinchona, banana, bam leaves, coconut and palm trees are also found there. They are of great commercial value</p>`
                },
                { 
                    question: "<p>Why is the diurnal range of temperature low in the equatorial regions?</p>",
                    answer: `<p>Diurnal range of temperature is low in the equatorial region because this region receives 12 hours insolation causing equal daylight and darkness. It results in little temperature variation.</p>`
                },
                { 
                    question: "<p>Give the location of the Tropical Monsoon lands.</p>",
                    answer: `<p>The tropical monsoon type of climate is found in the tropical regions where due to differential heating of land and water, a complete seasonal reversal of winds takes place. The location lies between 10° and 30° on either side of the equator.</p>`
                },
                { 
                    question: "<p>Name all the areas which experience tropical monsoon type of climate.</p>",
                    answer: `<p>The most clearly defined monsoon climates are located in the coastal areas of the South-East Asia, India, Myanmar, Bangladesh, Indonesia, South China and Philippines. Other areas outside Asia which experience seasonal reversal of winds are said to have only monsoonal tendencies. Such areas exist in the tropical Africa along the South-west coast. Equatorial East Africa including areas of Malaysia are found to have monsoonal character. Part of North-East Latin America, north coast of Puerto Rico and the Dominican Republic in the Caribbean Islands have a mild monsoonal effect.</p>`
                },
                { 
                    question: "<p>How do the South-West Monsoon Winds originate?</p>",
                    answer: `<p>The monsoon lands are greatly affected by the differential heating of land and water. During summer, the landmass of Asia is heated and low pressure is created. Winds from surrounding seas and oceans start blowing from sea to land carrying a lot of moisture, as they cross the water bodies. This is how South-West monsoon winds originate.</p>`
                },
                { 
                    question: "<p>State in brief a few important features of the Tropical Monsoon Climate.</p>",
                    answer: `<ol><li>Seasonal reversal of winds is the chief feature.</li><li>Temperature ranges from 15°C in the cool season to 32°C in hot season.</li><li>Three distinct seasons can be identified<ol><li>Cool dry season (Nov to Feb)</li><li>Hot dry season (March to May)</li><li>Hot wet season (June to Oct)</li></ol></li><li>Great variation in rain takes place from 12 cm in the deserts to 200 cm in coastal regions</li></ol>`
                },
                { 
                    question: "<p>What are the characteristics of the climate of hot deserts?</p>",
                    answer: `<ol><li>Rain rarely falls and average rainfall is usually below 12 cm. Sometimes there may be a sudden torrential downpour due to depression which may cause temporary floods.</li><li>The average temperature varies from 29°C in hot season to 10°C in cool season.</li><li>Absence of clouds causes temperature to rise upto 49°C at times. At night, because of clear skies, radiation is rapid and temperature may fall to 15°C to 5°C. Thus diurnal range of temperature is very high.</li></ol>`
                },
                { 
                    question: "<p>What type of natural vegetation is found in hot deserts?</p>",
                    answer: `<p>The natural vegetation found in hot deserts are mostly xerophytic plants. These are cacti, thorny bushes and coarse grasses. These plants have long roots to absorb water deep from ground and needle shaped leaves to reduce transpiration. Leaves are modified into spines.</p>`
                },
                { 
                    question: "<p>Why are the major hot deserts of the world found on the Western margins of the continents?</p>",
                    answer: `<p>The major hot deserts of the world are found on the Western margins of the continents because the trade winds blowing from North-East to South-West shed their moisture on the Eastern side.</p>`
                },
                { 
                    question: "<p>Point out the important features of the Tropical Deserts.</p>",
                    answer: `<ol><li>Annual range of temperature is high as summers are hot and winters are moderate.</li><li>Rainfall is scanty (less than 12 cm).</li><li>Relative humidity is only 10%</li><li>Days are hot and nights are cold. Therefore, the diurnal range of temperature is high.</li><li>Vegetation consists of xerophytic plants.</li></ol>`
                },
                { 
                    question: "<p>Point out the location of the hot desert region of the world naming the areas and the countries.</p>",
                    answer: `<p>Most of the world's hot deserts lie within the latitude 15°N to 30°N and south. They occupy the western margins of the continent in the belt of permanent trade winds. The only exception is Sahara desert, which extends from west coast right across the continent into South-West Asia.</p>`
                },
                { 
                    question: "<p>What is the main feature of the Mediterranean climate?</p>",
                    answer: `<p>The warm dry summer and mild rainy winters with plenty of rainfall is the main feature of the Mediterranean climate.</p>`
                },
                { 
                    question: "<p>When do Mediterranean regions receive their rain and why?</p>",
                    answer: `<p>In the northern hemisphere the maximum rainfall is received during winter between December to March while in southern hemisphere the rainy season last from May to August. This is because of the shifting of the pressure belts. These regions come under the influence of Westerlies. Onshore Westerly winds blow in the winter bringing cyclonic rain.</p>`
                },
                { 
                    question: "<p>What are the main characteristics of Mediterranean vegetation?</p>",
                    answer: `<ol><li>The plants in this region have adapted themselves to the summer drought, by storing water obtained from winter rains, e.g. bulbous roots.</li><li>They have thick wooden stem with thick needle type of spiny leaves to cut down rate of transpiration.</li><li>Other plants have tap roots which can reach down to the moist rock layer below the surface.</li><li>In the drier forests the vegetation is scrub like and consists of sweet smelling herbs and shrubs such as Oleander, Rosemary, Thyme and Lavender.</li><li>In the wetter parts, coniferous trees are found on mountain slopes.</li></ol>`
                },
                { 
                    question: "<p>Name the principal trees which grow on lands.</p>",
                    answer: `<p>Some trees that grow on land are teak, sheesham, oak, eucalyptus and fruit trees like mango, guava etc.</p>`
                },
                { 
                    question: "<p>Name a few local winds found in Mediterranean region.</p>",
                    answer: `<ol><li>Sirocco — a hot dusty, dry wind which blows from Sahara desert during summer across Mediterranean.</li><li>Mistral — intensely strong cold wind which blows in winter down the Rhone valley from the North reaching Mediterranean coast.</li><li>Bora — winter wind blowing south across Yugoslavia to the Adriatic Sea.</li></ol>`
                },
                { 
                    question: "<p>Name any four climate types of the world.</p>",
                    answer: `<ol><li>Mediterranean type</li><li>Temperate grasslands</li><li>Tropical desert</li><li>Tropical monsoon</li></ol>`
                },
                { 
                    question: "<p>Give an account of the winter conditions in the Taiga region.</p>",
                    answer: `<p>The winters are very long in the Taiga region. Long and cold winters last from October to April and temperature can fall to -30°C or less. During winter precipitation is received in the form of snowfall because winter temperatures are generally below freezing point.</p>`
                },
                { 
                    question: "<p>What is the location of the Tundra region?</p>",
                    answer: `<p>The Tundra lies beyond the Arctic circle (66°30'N) in the Northern Hemisphere. There is no tundra region in he Southern Hemisphere as there is no landmass present within this latitude. Northern coastal region of Alaska and Canada are in Tundra region.</p>`
                },
                { 
                    question: "<p>Describe the summer conditions in Tundra region.</p>",
                    answer: `<p>Summers in Tundra region are cool and short. They last only for about 2-3 months and the temperature may vary between 2°C to 10°C. Although during summer the area is blessed with continuous sunlight, the sun hardly comes up the horizon.</p>`
                },
                { 
                    question: "<p>What is meant by the term Aurora Borealis? Where do they occur?</p>",
                    answer: `<p>Aurora Borealis or polar lights are a natural phenomenon in which there is a display of brilliant colors in sky. They occur when charged particles from the sun interact with gases in the earth's atmosphere. They occur in latitudes beyond 65° in northern hemisphere.</p>`
                },
                { 
                    question: "<p>On an outline map of the world, mark the following regions:</p>",
                    answer: `<p><strong>Equatorial Regions</strong></p>`
                },
                { 
                    question: "<p>Equatorial regions are economically backward.</p>",
                    answer: `<p>Equatorial regions are economically backward as they are mostly inhabited by the primitive people who are primarily gatherers &amp; hunters. Farming is also primitive type.</p>`
                },
                { 
                    question: "<p>The diurnal range of temperature is generally low in the equatorial region.</p>",
                    answer: `<p>The extensive cloud cover and heavy rainfall prevents the rise and fall of temperature during day and night respectively. Therefore, the diurnal range of temperature is low.</p>`
                },
                { 
                    question: "<p>The major hot deserts of the world are generally found on the western margins of the continent.</p>",
                    answer: `<p>The major hot deserts of the world are generally found on the western margins of the continent because the trade winds blow from north east to south west, shed their moisture in the east and the western margins are left dry. Trade winds are offshore in this region.</p>`
                },
                { 
                    question: "<p>It is very difficult to adapt a permanent settled life in Central Asia.</p>",
                    answer: `<p>The region has rugged topography, climate, soil &amp; vegetation is also varied &amp; complex. In summer the region experiences acute scarcity of water. Therefore, it is very difficult to adapt a permanent settled life in this region.</p>`
                },
                { 
                    question: "<p>Mediterranean climate is called resort climate.</p>",
                    answer: `<p>Mediterranean climate is called resort climate because it is characterised by mild, comfortable temperatures year-round, making it an ideal climate for vacationing and leisure activities.</p>`
                },
                { 
                    question: "<p>There is no vegetation found in Southern Hemisphere beyond 66.5°S.</p>",
                    answer: `<p>Due to extreme weather conditions characterised by very low beyond freezing point temperatures, strong winds, a polar desert environment and presence of a very small landmass, the Antarctic region beyond 66.5°S in Southern Hemisphere has no vegetation.</p>`
                },
                { 
                    question: "<p>Identify the type of climate found in the shaded region.</p>",
                    answer: `<p>Equatorial region</p>`
                },
                { 
                    question: "<p>Ebony, Mahagony, Rosewood are the trees found in</p>",
                    answer: `<p>Tropical/Equatorial Rainforest</p>`
                },
                { 
                    question: "<p>Campos is the name of tropical grassland in</p>",
                    answer: `<p>Brazil</p>`
                },
                { 
                    question: "<p>Dust storms are the major phenomena of which of the following regions?</p>",
                    answer: `<p>Tropical deserts</p>`
                },
                { 
                    question: "<p>Which of the following climate regions is famous for the warm, dry summer &amp; mild rainy winters?</p>",
                    answer: `<p>Mediterranean climate</p>`
                },
                { 
                    question: "<p>Which of the following climate regions experiences both hot local wind like Sirocco &amp; cold local winds like mistral &amp; Bora?</p>",
                    answer: `<p>Mediterranean climate</p>`
                },
                { 
                    question: "<p>Match the following &amp; choose the proper option.</p>",
                    answer: `<p>(i) (d); (ii) (c); (iii) (b); (iv) (a)</p>`
                },
                { 
                    question: "<p>Which type of climate is experienced by the Northern Scandinavia Island of Europe?</p>",
                    answer: `<p>Tundra</p>`
                },
                { 
                    question: "<p>These types of trees are found in</p>",
                    answer: `<p>Taiga region</p>`
                },
                { 
                    question: "<p>Mediterranean region is famous for the cultivation of</p>",
                    answer: `<p>Citrus fruits</p>`
                },
                { 
                    question: "<p>Tropical rainforest is found in which of the following region?</p>",
                    answer: `<p>Equatorial region</p>`
                },
                { 
                    question: "<p>Which of the following climate is found in India, Bangladesh, and Sri Lanka?</p>",
                    answer: `<p>Tropical Monsoon climate</p>`
                },
            ]
        },
        { 
            title: "Specimen Paper",
            questions: [
                { 
                    question: "<p>Why are the lines of longitudes also called meridians?</p>",
                    answer: `<p>The lines of longitudes are called Meridians of Longitude because the word 'meridian' means \"midday\" and all the places on the same meridian have their noon at the same time.</p>`
                },
                { 
                    question: "<p>(i) What is meant by the inclination of the Earth's axis?</p>",
                    answer: `<p>(i) The axis on which earth rotates makes an angle of 66 1⁄2° with the plane of ecliptic and is tilted 23 1⁄2° from a line perpendicular to that plane. This tilt of earth is called inclination of the Earth's axis.</p>`
                },
                { 
                    question: "<p>State two differences between rocks and minerals.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Explain the following terms:</p>",
                    answer: `<ol><li>Exfoliation</li><li>Oxidation</li></ol>`
                },
                { 
                    question: "<p>(i) What are the Jet streams?</p>",
                    answer: `<p>(i) Jet streams are fast flowing narrow air currents found in the upper atmosphere or in troposphere of some planets including earth. The main jet streams are located near the altitude of the tropopause.</p>`
                },
                { 
                    question: "<p>Draw a well labelled diagram of orographic rainfall.</p>",
                    answer: `<p>Below diagram shows Orographic rainfall:</p>`
                },
                { 
                    question: "<p>(i) Name any two sources of soil pollution.</p>",
                    answer: `<ol><li>Industrial waste — Industrial waste containing chemicals, when dumped in soil, causes soil pollution. For example, fly ash from thermal plants, chemicals from oil refineries pollute the soil.</li><li>Urban commercial and domestic waste — Non-biodegradable wastes like glass, plastic, polythene bags etc, either remain in the soil and take a long time to decompose.</li></ol>`
                },
                { 
                    question: "<p>With reference to the natural regions of the world answer the following:</p>",
                    answer: `<ol><li>Why does the Taiga region not exist in the Southern hemisphere?</li><li>Prairies are called the granaries of the world.</li></ol>`
                },
                { 
                    question: "<p>How does the rotation of the earth influence the direction of the ocean currents?</p>",
                    answer: `<p>The Earth rotates on its axis from west to east. This rotation is the cause of a deflective force known as Coriolis Force which deflects the general direction of the ocean currents. For example, the currents flowing from Equator towards the North and South Poles are deflected to their right in the Northern Hemisphere and towards their left in the Southern Hemisphere.</p>`
                },
                { 
                    question: "<p>(i) What is Coriolis Effect?</p>",
                    answer: `<p>(i) A deflective force that, as a result of the earth's rotation, deflects moving objects to the right in the northern hemisphere and in the left in the southern hemisphere is called coriolis force. This deflective effect of coriolis force is called coriolis effect. It affects the movement of ocean currents and winds.</p>`
                },
                { 
                    question: "<p>(i) Why are latitudes also called parallels of latitude?</p>",
                    answer: `<p>(i) Latitudes are known as parallels of latitude as each of them is an imaginary circle parallel to the Equator and centered on the polar axis.</p>`
                },
                { 
                    question: "<p>State two effects of the rotation of the earth.</p>",
                    answer: `<ol><li>Occurrence of day and night</li><li>Ocean currents and winds are deflected towards right in the Northern Hemisphere and towards the left in the Southern Hemisphere.</li></ol>`
                },
                { 
                    question: "<p>Give a geographical reason for each of the following:</p>",
                    answer: `<ol><li>Great circles are the shortest routes between two places.</li><li>The Earth is a habitable planet.</li><li>Kuala Lumpur rotates faster on the Earth's axis than London.</li></ol>`
                },
                { 
                    question: "<p>Draw a neat labelled diagram showing the position of the Earth during solstice.</p>",
                    answer: `<p>Below diagram shows Summer Solstice in the Northern Hemisphere:</p>`
                },
                { 
                    question: "<p>What is the composition of the Earth's crust?</p>",
                    answer: `<p>The earth's crust consists of two layers - SIAL and SIMA.</p>`
                },
                { 
                    question: "<p>(i) Differentiate between intermontane plateau and volcanic plateau.</p>",
                    answer: `<table><thead><tr><th>Intermontane plateau</th><th>Volcanic plateau</th></tr></thead><tbody><tr><td>These are surrounded by hills and mountains on all the sides. They are formed along with fold mountains.</td><td>They are formed by numerous volcanic eruptions that slowly build up over time.</td></tr><tr><td>For example - the Tibetan plateau</td><td>For example - the Peninsular plateau of India</td></tr></tbody></table>`
                },
                { 
                    question: "<p>Draw a neat well labelled diagram of the Rock Cycle.</p>",
                    answer: `<p>Below diagram shows the rock cycle:</p>`
                },
                { 
                    question: "<p>Give a reason for each of the following:</p>",
                    answer: `<ol><li>Igneous rocks are also called Primary rocks</li><li>Fossils are present in Sedimentary rocks</li><li>The core of the Earth is in a semi—solid state</li></ol>`
                },
                { 
                    question: "<p>Name any two types of volcanoes giving one example of each type.</p>",
                    answer: `<ol><li><strong>Active volcanoes</strong> — They constantly eject lava, gases and ash. For example- Sabancaya in Peru.</li><li><strong>Dormant volcanoes</strong> — They remain dormant but can suddenly erupt and again go dormant. For example- The Vesuvius volcano in Italy.</li></ol>`
                },
                { 
                    question: "<p>Define the following terms:</p>",
                    answer: `<ol><li>Epicentre</li><li>Focus</li></ol>`
                },
                { 
                    question: "<p>Give a geographical reason for each of the following:</p>",
                    answer: `<ol><li>Volcanic activity and earthquakes occur in the same belt.</li><li>All rivers do not form a delta.</li><li>Chemical weathering is more common in the tropical regions.</li></ol>`
                },
                { 
                    question: "<p>Explain the meaning of the following terms:</p>",
                    answer: `<ol><li>Deflation hollow</li><li>River meander</li><li>Block disintegration.</li></ol>`
                },
                { 
                    question: "<p>State four factors that affect the movement of ocean currents.</p>",
                    answer: `<ol><li>Variation in Sea Water Temperature</li><li>Variation in Sea water Salinity</li><li>Configuration of Coastline</li><li>Planetary winds</li></ol>`
                },
                { 
                    question: "<p>Draw a neat and well labelled diagram showing the two types of tides.</p>",
                    answer: `<p><strong>Spring Tide</strong></p>`
                },
                { 
                    question: "<p>Give a reason for each of the following:</p>",
                    answer: `<ol><li>Newfoundland has rich fishing grounds.</li><li>The eastern coast of Japan is warm even in January.</li><li>The tidal range differs from one water body to the other.</li></ol>`
                },
                { 
                    question: "<p>State three ways in which oceans are important to us.</p>",
                    answer: `<ol><li>They regulate our climate.</li><li>They provide most of the oxygen.</li><li>They provide food in the form of fish and other sea foods.</li></ol>`
                },
                { 
                    question: "<p>\"Higher the latitude, lower is the temperature.\"<br/>Justify the statement with an example.</p>",
                    answer: `<p>At higher latitude, the sun rays have to cover more distance to reach the earth's surface due to spherical shape of earth. Thus, they lose more heat as compared to the lower latitudes which receive direct rays of the sun. For example, the area around equator (0°) is hot and humid while the areas near arctic circle (66 1/2°) is cold throughout the year.</p>`
                },
                { 
                    question: "<p>(i) What is the cause of ozone depletion?</p>",
                    answer: `<ol><li>It will allow excessive radiation of ultraviolet rays which can render man and animal blind.</li><li>It can cause crop damage,</li><li>It can burn skin and cause cancer.</li></ol>`
                },
                { 
                    question: "<p>Give a reason for each of the following:</p>",
                    answer: `<ol><li>The snow on the slope of Himalayas in Nepal melt faster than that on the slope towards Tibet.</li><li>Tropical deserts have a large annual range of temperatures.</li><li>Equatorial regions have low atmospheric pressure throughout the year.</li></ol>`
                },
                { 
                    question: "<p>Draw a neat labelled diagram of the structure of the Earth's atmosphere.</p>",
                    answer: `<p>Below diagram illustrates the structure of the atmosphere:</p>`
                },
                { 
                    question: "<p>Name the region in which the following local winds blow:</p>",
                    answer: `<ol><li>Chinook</li><li>Mistral</li><li>Foehn</li><li>Loo</li></ol>`
                },
                { 
                    question: "<p>(i) What are 'doldrums'?</p>",
                    answer: `<p>(i) A zone of low pressure lying parallel to equator where North-East and South-East trade winds converge and meet each other, producing calm and light surface winds and a strong upward movement of air is called Doldrums.</p>`
                },
                { 
                    question: "<p>Draw a well labelled diagram of sea breeze.</p>",
                    answer: `<p>Below diagram shows formation of sea breeze:</p>`
                },
                { 
                    question: "<p>Give a reason for each of the following:</p>",
                    answer: `<ol><li>Roaring forties and furious fifties are found in the Southern hemisphere.</li><li>Low atmospheric pressure prevails over the circum polar region.</li><li>Cyclones are always followed by anticyclones</li></ol>`
                },
                { 
                    question: "<p>Distinguish between absolute and relative humidity.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>(i) Name the type of rainfall experienced in the Equatorial region.</p>",
                    answer: `<ol><li>This type of rain mostly occurs at 4 o'clock in the afternoon.</li><li>It is always torrential accompanied by lightening and thunder.</li></ol>`
                },
                { 
                    question: "<p>(i) How is dew formed?</p>",
                    answer: `<p>(i) When cold air holding moisture, comes in contact with objects like flowers, leaves, glass particles etc., it condenses to form tiny drops called dew.</p>`
                },
                { 
                    question: "<p>Give a reason for each of the following:</p>",
                    answer: `<ol><li>A rain shadow area is generally dry.</li><li>Frontal rain is common in mid latitudes.</li><li>Convectional rainfall is also called 4 o'clock rainfall.</li></ol>`
                },
                { 
                    question: "<p>Explain how industrial waste and vehicular emission act as the source of air pollution.</p>",
                    answer: `<p>Industries release different toxic gases into atmosphere. Burning of fossil fuels releases sulphur dioxide, carbon dioxide and carbon particles. Vehicles also release carbon particles, carbon monoxide and smoke into the atmosphere. They also release unburnt hydrocarbons. Therefore, industries and vehicles are the most important cause of air pollution.</p>`
                },
                { 
                    question: "<p>Which health hazards are associated with radioactive waste?</p>",
                    answer: `<p>Radioactive waste is generated from nuclear power stations, nuclear weapon testing, mining etc. They are also generated from hospitals, radio-tracers etc. Handling this waste is risky as it can cause cancer, acute radiation syndrome, cardiovascular diseases etc. It can be very dangerous to skin and eyes as this waste is geno-toxic material.</p>`
                },
                { 
                    question: "<p>(i) What is organic farming?</p>",
                    answer: `<p>(i) Organic farming is a farming method that involves growing and nurturing crops without the use of synthetic based fertilizers and pesticides. This process involves the use of biological materials, avoiding synthetic substances to maintain soil fertility and ecological balance thereby minimizing pollution and wastage.</p>`
                },
                { 
                    question: "<p>Discuss how individuals may reduce energy consumption to create a cleaner and eco-friendly world.</p>",
                    answer: `<ol><li>carpooling</li><li>promotion of public transport</li><li>restricted use of fossil fuels</li><li>saving energy like electricity and fossil fuels</li></ol>`
                },
                { 
                    question: "<p>Why are tropical deserts found along the western margins of the continents?</p>",
                    answer: `<p>The major hot deserts of the world are found on the Western margins of the continents because the trade winds blowing from North-East to South-West shed their moisture on the Eastern side and become dry by the time they reach the western side.</p>`
                },
                { 
                    question: "<p>Name the economic activities practiced in the tropical grasslands.</p>",
                    answer: `<p>Cattle rearing is one of the most important economic activity practised in the tropical grasslands. They rear cattle for meat skin and hide. The cattle rearing is still in subsistence level in Llanos but is done on commercial basis in most tropical grassland like Campos and Savannas.</p>`
                },
                { 
                    question: "<p>Give a reason for each of the following:</p>",
                    answer: `<ol><li>'The Equatorial region is also called the lungs of the world.'</li><li>The trees found in the Mediterranean region have long roots and thick barks.</li><li>Tundra region has a low annual range of temperature.</li></ol>`
                },
                { 
                    question: "<p>(i) What type of climate is experienced in the north-western part of Europe?</p>",
                    answer: `<p>(i) The Cool Temperate Continental type or Siberian type of climate is found in the north-western part of Europe.</p>`
                },
            ]
        },
    ]
};
