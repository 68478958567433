export const icsehistorycivics9 = {
    class: "9",
    name: "History & Civics",
    category: "History & Civics",
    chapters: [
        { 
            title: "The Harappan Civilisation",
            questions: [
            ]
        },
        { 
            title: "The Vedic Period",
            questions: [
            ]
        },
        { 
            title: "Jainism and Buddhism",
            questions: [
            ]
        },
        { 
            title: "The Mauryan Empire",
            questions: [
            ]
        },
        { 
            title: "The Sangam Age",
            questions: [
            ]
        },
        { 
            title: "The Age of the Guptas",
            questions: [
            ]
        },
        { 
            title: "Medieval India — (A) The Cholas",
            questions: [
            ]
        },
        { 
            title: "Medieval India — (B) The Delhi Sultanate",
            questions: [
            ]
        },
        { 
            title: "Medieval India — (C) The Mughal Empire",
            questions: [
            ]
        },
        { 
            title: "Medieval India — (D) Composite Culture",
            questions: [
            ]
        },
        { 
            title: "The Modern Age in Europe — (A) Renaissance",
            questions: [
            ]
        },
        { 
            title: "The Modern Age in Europe — (B) Reformation",
            questions: [
            ]
        },
        { 
            title: "The Modern Age in Europe — (C) Industrial Revolution",
            questions: [
            ]
        },
        { 
            title: "Our Constitution",
            questions: [
            ]
        },
        { 
            title: "Salient Features of the Constitution — I",
            questions: [
            ]
        },
        { 
            title: "Salient Features of the Constitution — II",
            questions: [
            ]
        },
        { 
            title: "Elections",
            questions: [
            ]
        },
        { 
            title: "Local Self-Government — Rural",
            questions: [
            ]
        },
        { 
            title: "Local Self-Government — Urban",
            questions: [
            ]
        },
        { 
            title: "Specimen Paper",
            questions: [
            ]
        },
    ]
};
