// src/components/Home.js
import React from 'react';
import './Home.css';

const Home = () => {
    return (
        <div className="home-container">
            <section className="hero text-white text-center d-flex justify-content-center align-items-center">
                <div className="container hero-content">
                    <h1 className="display-4 font-weight-bold main-title">Empowering Young Minds for a Brighter Future</h1>
                    <p className="lead sub-title">Expert guidance for students from Class V to X - CBSE and ICSE. Led by Tr. Sonal Parik with 15 years of teaching excellence.</p>
                    <div className="mt-4">
                        <button className="btn btn-primary btn-lg mx-2">Learn More</button>
                        <button className="btn btn-warning btn-lg mx-2">Get Started</button>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
