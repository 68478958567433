import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './SubjectPage.css';
import { studyMaterialsData } from '../data/studyMaterialsData.js';

const SubjectPage = () => {
    const { board, className, subjectName } = useParams();

    // Decode the URL parameters
    const decodedSubjectName = decodeURIComponent(subjectName);
    const decodedClassName = decodeURIComponent(className);

    // State for search query
    const [searchQuery, setSearchQuery] = useState("");

    // Find the selected subject based on the board, class, and subject name
    const selectedSubject = studyMaterialsData[board]
        .find(material => material.class === decodedClassName && material.name === decodedSubjectName);

    if (!selectedSubject) {
        return <p>Subject not found.</p>; // Handle case where the subject is not found
    }

    // Filter chapters based on search query
    const filteredChapters = selectedSubject.chapters.flatMap(chapter => 
        chapter.questions.filter(q =>
            q.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
            q.answer.toLowerCase().includes(searchQuery.toLowerCase())
        ).map(filteredQ => ({ ...filteredQ, chapterTitle: chapter.title }))
    );

    return (
        <div className="subject-page-container">
            <header className="subject-header">
                <h1>{decodedSubjectName}</h1>
                <p className="subject-description">
                    Explore the chapters and topics covered in {decodedSubjectName}.
                </p>
            </header>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search for questions or keywords..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-input"
                />
            </div>
            {searchQuery ? (
                <div className="search-results-container">
                    {filteredChapters.length > 0 ? (
                        filteredChapters.map((item, index) => (
                            <div key={index} className="search-result-item">
                                <h3>Chapter: {item.chapterTitle}</h3>
                                <p><strong>Question:</strong> <span dangerouslySetInnerHTML={{ __html: item.question }} /></p>
                                <p><strong>Answer:</strong> <span dangerouslySetInnerHTML={{ __html: item.answer }} /></p>
                            </div>
                        ))
                    ) : (
                        <p>No results found for "{searchQuery}".</p>
                    )}
                </div>
            ) : (
                <div className="chapter-list-container">
                    <div className="chapter-list">
                        {selectedSubject.chapters.map((chapter, index) => (
                            <Link 
                                key={index}
                                to={`/study-materials/${board}/${className}/${subjectName}/${encodeURIComponent(chapter.title)}`}
                                className="chapter-link"
                            >
                                {chapter.title}
                            </Link>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubjectPage;
