// src/components/Programs.js
import React from 'react';
import './Programs.css';

const Programs = () => {
    return (
        <div className="programs-container">
            <section className="programs-hero text-center">
                <div className="container">
                    <h1 className="display-4 font-weight-bold">Our Programs</h1>
                    <p className="lead">Empowering students with the tools they need to succeed in an ever-evolving educational landscape.</p>
                </div>
            </section>

            <div className="programs-grid container">
                <section className="program-section">
                    <h2 className="program-title">Intermediate Innovators</h2>
                    <p className="program-description">Championing academic growth for Grades 5-7, our tailored approach encourages critical thinking and creative problem-solving.</p>
                    <ul className="program-features">
                        <li>Advanced Concepts</li>
                        <li>Project-Based Learning</li>
                        <li>Skill Development</li>
                    </ul>
                    <button className="enroll-button">Enroll your kid</button>
                </section>

                <section className="program-section">
                    <h2 className="program-title">Advanced Achievers</h2>
                    <p className="program-description">Preparing Grades 8-10 for academic excellence, with focused coaching that paves the way for success in high school and beyond.</p>
                    <ul className="program-features">
                        <li>Specialized Coaching</li>
                        <li>Strategic Learning</li>
                        <li>Career Pathways</li>
                    </ul>
                    <button className="enroll-button">Enroll your kid</button>
                </section>
            </div>
        </div>
    );
}

export default Programs;
