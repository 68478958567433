import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { studyMaterialsData } from '../data/studyMaterialsData.js';
import './ChapterPage.css';

const ChapterPage = () => {
    const { board, className, subjectName, chapterTitle } = useParams();
    const [subjectData, setSubjectData] = useState(null);
    const [selectedChapter, setSelectedChapter] = useState(null);

    useEffect(() => {
        // Decode the subject and chapter names from the URL
        const decodedSubjectName = decodeURIComponent(subjectName);
        const decodedChapterTitle = decodeURIComponent(chapterTitle);

        // Find the subject data based on the board, class, and subject name
        const foundSubjectData = studyMaterialsData[board]?.find(
            subject => subject.class === className && subject.name === decodedSubjectName
        );

        if (foundSubjectData) {
            setSubjectData(foundSubjectData);

            // Find the chapter data based on the decoded chapter title
            const foundChapter = foundSubjectData.chapters.find(
                chapter => chapter.title === decodedChapterTitle
            );

            // If a chapter is found, set it as the selected chapter
            if (foundChapter) {
                setSelectedChapter(foundChapter);
            } else {
                // If no chapter is specified, default to the first chapter
                setSelectedChapter(foundSubjectData.chapters[0]);
            }
        } else {
            setSubjectData(null);
        }
    }, [board, className, subjectName, chapterTitle]);

    if (!subjectData || !selectedChapter) {
        return <div>Subject or Chapter not found</div>;
    }

    return (
        <div className="chapter-page-container">
           
            <header className="chapter-header">
                <h1>{subjectData.name}</h1>
            </header>

            <div className="chapter-content">
                 {/* Disclaimer Section */}
        
                <aside className="chapter-menu">
                    <h2>Chapters</h2>
                    <ul>
                        {subjectData.chapters.map((chapter, index) => (
                            <li key={index}>
                                <Link 
                                    to={`/study-materials/${board}/${className}/${subjectName}/${encodeURIComponent(chapter.title)}`}
                                    onClick={() => setSelectedChapter(chapter)} // Set the selected chapter on click
                                >
                                    {chapter.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </aside>

                <main className="chapter-details">
                <div className="disclaimer">
                    *This is just for reference and students should use it only as helping material.
                </div>

                    <h2>{selectedChapter.title}</h2>
                    <div className="questions">
                        {selectedChapter.questions
                            .filter(qa => !qa.answer.includes("<img") && qa.answer !== "N/A")  // Filter out questions with images and N/A answers
                            .map((qa, index) => (
                                <div key={index} className="question-answer">
                                    <h3 dangerouslySetInnerHTML={{ __html: `Question: ${qa.question}` }}></h3>
                                    {qa.answer !== "N/A" && (
                                        <p dangerouslySetInnerHTML={{ __html: qa.answer }}></p>
                                    )}
                                </div>
                            ))}
                    </div>

                </main>
            </div>
        </div>
    );
};

export default ChapterPage;
