// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header.js';
import Home from './components/Home.js';
import About from './components/About.js';
import Programs from './components/Programs.js';
import StudyMaterials from './components/StudyMaterials.js';  // Updated from StudyMaterial to match previous example
import Contact from './components/Contact.js';
import SubjectPage from './components/SubjectPage.js';        // New import
import ChapterPage from './components/ChapterPage.js';        // New import

function App() {
    return (
        <Router>
            <Header />
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/programs" element={<Programs />} />
                    <Route path="/study-materials" element={<StudyMaterials />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/study-materials/:board/:className/:subjectName" element={<SubjectPage />} />   {/* New route for subject page */}
                    <Route path="/study-materials/:board/:className/:subjectName/:chapterTitle" element={<ChapterPage />} />  {/* New route for chapter page */}
                </Routes>
            </div>
        </Router>
    );
}

export default App;
