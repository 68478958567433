export const icsecomputerapplications9 = {
    class: "9",
    name: "Computer Applications",
    category: "Computer Applications",
    chapters: [
        { 
            title: "Introduction to Object Oriented Programming Concepts",
            questions: [
                { 
                    question: "<p>Procedure Oriented Programming gives importance to ...........</p>",
                    answer: `<ol><li><strong>Instructions only ✓</strong></li><li>Instructions and data</li><li>Data only</li><li>None of these</li></ol>`
                },
                { 
                    question: "<p>Procedure Oriented Programming mainly uses ...........</p>",
                    answer: `<ol><li><strong>Top-down approach ✓</strong></li><li>Top-down and bottom-up approach</li><li>Bottom-up approach</li><li>None of these</li></ol>`
                },
                { 
                    question: "<p>Object Oriented Programming mainly uses ...........</p>",
                    answer: `<ol><li>Top-down approach</li><li>Top-down and bottom-up approach</li><li><strong>Bottom-up approach ✓</strong></li><li>None of these</li></ol>`
                },
                { 
                    question: "<p>An object belonging to a particular class is known as a/an ........... of that class.</p>",
                    answer: `<ol><li>Interface</li><li><strong>Instance ✓</strong></li><li>Alias</li><li>Member</li></ol>`
                },
                { 
                    question: "<p>Objects that share the same attributes and behaviour are grouped together into a/an ...........</p>",
                    answer: `<ol><li>Interface</li><li>Instance</li><li>Alias</li><li><strong>Class ✓</strong></li></ol>`
                },
                { 
                    question: "<p>........... is the technique of binding both data and functions together to keep them safe from unauthorised access and misuse.</p>",
                    answer: `<ol><li>Abstraction</li><li>Inheritance</li><li><strong>Encapsulation ✓</strong></li><li>Polymorphism</li></ol>`
                },
                { 
                    question: "<p>........... refers to the act of representing essential features without including the background details.</p>",
                    answer: `<ol><li><strong>Abstraction ✓</strong></li><li>Inheritance</li><li>Encapsulation</li><li>Polymorphism</li></ol>`
                },
                { 
                    question: "<p>........... is the feature by means of which one class acquires the properties of another class.</p>",
                    answer: `<ol><li>Abstraction</li><li><strong>Inheritance ✓</strong></li><li>Encapsulation</li><li>Polymorphism</li></ol>`
                },
                { 
                    question: "<p>The ability of a function or object to take on multiple forms is called ...........</p>",
                    answer: `<ol><li>Abstraction</li><li>Inheritance</li><li>Encapsulation</li><li><strong>Polymorphism ✓</strong></li></ol>`
                },
                { 
                    question: "<p>The term OOP stands for</p>",
                    answer: `<ol><li>Object Oriented Procedure</li><li>Object Oriented Packet</li><li><strong>Object Oriented Programming ✓</strong></li><li>Object Orientation Procedure</li></ol>`
                },
                { 
                    question: "<p>Low-level languages are closer to computer hardware.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>In a procedural language, code and data are held separately.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>In object-oriented programming, code and data are held separately.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Wrapping up data and related functions in a single unit represents encapsulation.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The object is also known as an instance.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The class that is derived from another class is called a superclass.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Classes can be derived from other classes.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Inheritance allows a class to acquire the properties of another class.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>A class is a blueprint for the attributes and behaviours of a group of objects.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Objects from the same class do not share the same definition of attributes and behaviours.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>What are programming languages? Describe the various generations of programming languages.</p>",
                    answer: `<ol><li><strong>First Generation Programming Language</strong> — Machine language is the first-generation programming language (1GL). It is made up of binary number 0 (Zero) and 1 (One) so instructions in Machine language are sequences of zeros and ones. It is a low-level language.</li><li><strong>Second Generation Programming Language</strong> — Assembly language is second-generation language (2GL). It uses symbolic operations called mnemonics instead of binary digits that can have up to maximum of five letters. Assembly language is also a low-level language.</li><li><strong>Third Generation Programming Language</strong> — A third-generation language (3GL) is close to English in vocabulary. These languages are easier to read and require less time to write programs. Third-generation programming languages are high-level programming languages, such as FORTRAN, Java, C, and C++.</li><li><strong>Fourth Generation Programming Language</strong> — A fourth-generation language (4GL) is closer to a natural language (for example, English) than a third- generation language. These languages are non-procedural. It means that the programmer specifies what is required as opposed to how it is to be done. Database languages such as Structured Query Language (SQL), report generators such as Oracle Reports, and Python are examples of fourth-generation languages.</li><li><strong>Fifth Generation Programming Language</strong> — A fifth-generation language (5GL) is designed to solve a given problem using constraints given to the program, rather than using an algorithm written by a programmer. The fifth-generation languages are mainly used in Artificial Intelligence. Smalltalk, Prolog, and Mercury are good examples of the fifth-generation languages.</li></ol>`
                },
                { 
                    question: "<p>What are programming paradigms? Briefly explain two popular programming paradigms.</p>",
                    answer: `<ol><li><strong>Procedure Oriented Programming</strong> — In this programming paradigm, a complex programming problem is solved by dividing it into smaller problems using functions (or procedures).</li><li><strong>Object Oriented Programming</strong> — In this programming paradigm, data and functions are wrapped into a single unit.</li></ol>`
                },
                { 
                    question: "<p>What are the characteristics of procedural programming?</p>",
                    answer: `<ol><li>Procedural programming follows a top-down approach.</li><li>The program is divided into blocks of codes called functions, where each function performs a specific task.</li><li>Procedural programs model real-world processes as 'procedures' operating on 'data'.</li><li>The data and functions are detached from each other.</li><li>The data moves freely in a program.</li><li>It is easy to follow the logic of a program.</li><li>A function can access other function's data by calling that function.</li></ol>`
                },
                { 
                    question: "<p>What are the limitations of procedural programming?</p>",
                    answer: `<ol><li>Procedural programming mainly focuses on procedures or functions. Less attention is given to the data.</li><li>The data and functions are separate from each other.</li><li>Global data is freely moving and is shared among various functions. Thus, it becomes difficult for programmers to identify and fix issues in a program that originate due to incorrect data handling.</li><li>Changes in data types need to be carried out manually all over the program and in the functions using the same data type.</li><li>Limited and difficult code reusability.</li><li>It does not model real-world entities (e.g., car, table, bank account, loan) very well where we as a human being, perceive everything as an object.</li><li>The procedural programming approach does not work well for large and complex systems.</li></ol>`
                },
                { 
                    question: "<p>Write a short note on Object Oriented Programming.</p>",
                    answer: `<p><strong>Object Oriented Programming</strong> (OOP) is a programming paradigm which revolves around the behaviour of an object, and its interactions with other objects and classes. In OOP, <strong>the program is organised around data or objects</strong> rather than functions or procedures. It follows the design principles of <strong>Data</strong> <strong>Abstraction</strong>, <strong>Encapsulation</strong>, <strong>Inheritance</strong>, and <strong>Polymorphism</strong>.</p>`
                },
                { 
                    question: "<p>Explain the phrase, \"Everything is an object\".</p>",
                    answer: `<ol><li><p><strong>Tangible Objects</strong> — These are the objects that we can see and touch. For example, chair, pen, book, door, etc.</p></li><li><p><strong>Conceptual Objects</strong> — These objects exists as a conceptual entity that we cannot touch. We may or may not be able to see them. For example, an email, a bank account, a song, patents, etc.</p></li><li><p><strong>Roles</strong> — Roles played by people, such as a student, a teacher or a clerk.</p></li><li><p><strong>Events</strong> — An event is something occurring in a system or an organisation. For example, a sale or a purchase in a departmental store, someone's birthday, etc.</p></li></ol>`
                },
                { 
                    question: "<p>What are the characteristics of object-oriented programming?</p>",
                    answer: `<ol><li>It follows a <strong>bottom-up approach</strong>.</li><li>The program resulting from object-oriented programming is a collection of objects. <strong>Each object has its own data and a set of operations.</strong></li><li>OOP <strong>restricts the free movement of data</strong> and the functions that operate on it.</li><li>A properly defined class can be reused, giving way to <strong>code reusability</strong>.</li><li>The concept of object-oriented programming <strong>models real-world entities very well</strong>.</li><li>Due to its object-oriented approach, it is <strong>extremely useful in solving complex problems</strong>.</li></ol>`
                },
                { 
                    question: "<p>What are the limitations of object-oriented programming?</p>",
                    answer: `<ol><li>The size of the programs created using this approach may become larger than the programs written using procedure-oriented programming approach.</li><li>Software developed using this approach requires a substantial amount of pre-work and planning.</li><li>OOP code is difficult to understand if you do not have the corresponding class documentation.</li><li>In certain scenarios, these programs can consume a large amount of memory.</li></ol>`
                },
                { 
                    question: "<p>What do you mean by Abstraction? Give suitable examples.</p>",
                    answer: `<p>Abstraction refers to the act of representing essential features without including the background details. For example, a building can be viewed as a single component (e.g., hospital) rather than separate components like cement, bricks, and rods. Abstraction is relative to the perspective of the viewer.</p>`
                },
                { 
                    question: "<p>Differentiate between the Owner's and the Vet's perspective in Figure 1.24</p>",
                    answer: `<p>From the viewpoint of the dog's owner, the things that are essential for the dog are his favorite food, the colour of his food bowl, his favorite game that he enjoys playing with his owner, his preferred time for walk, etc. From the viewpoint of the dog's vet (doctor for animals), the important things about the dog are whether the dog's body functions are normal or not to ensure that the dog is healthy. Like this, there can be two abstractions for the dog — one for the dog's owner and the other for the dog's vet.</p>`
                },
                { 
                    question: "<p>Differentiate between the Car Mechanic's and Car Driver's perspective in Figure 1.25</p>",
                    answer: `<p>From the viewpoint of the driver, a car is one single object. It can be operated using gears, indicators, accelerator, brake, etc. The driver doesn't need to know or understand the internal mechanisms of how these components actually work. From the viewpoint of the mechanic, it is important for him to understand how the internal mechanisms like gears, indicators, accelerator, brake, etc. actually work so that they can identify and fix issues with the cars. For a mechanic, each car part is a single object.</p>`
                },
                { 
                    question: "<p>Explain the term Encapsulation using appropriate examples.</p>",
                    answer: `<p>Encapsulation is a mechanism that binds together code and the data it manipulates. It keeps them both safe from the outside world, preventing any unauthorised access or misuse. Only member methods, which are wrapped inside the class, can access the data and other methods. For example, an ATM contains different denominations of currency notes and it provides a set operations to the user to withdraw money. The different denominations of currency notes is the data, the set of operations are the methods and the ATM encapsulates them into a single unit enabling us to withdraw cash conveniently.</p>`
                },
                { 
                    question: "<p>Provide real-life examples to explain the term, Inheritance.</p>",
                    answer: `<p>To explain Inheritance, let's take the example of traffic on the road. This traffic has some commonalities. It consists of things that can move on the road and transport people and goods from one place to another. We call these things vehicles. These vehicles differ from each other in certain aspects like whether it transports passengers or goods, how many passengers it can accommodate at a time, whether it is a two-wheeler or four-wheeler, etc. So, we have different types of vehicles like Cars, Bikes, Scooters, Auto rickshaw, Buses, Trucks, etc. We can represent this traffic using Inheritance as shown below. Here, Vehicle is the base class having the common characteristics and behaviours of all Vehicles. Then we have Car, Bike, Bus, Truck as subclasses of Vehicles.</p>`
                },
                { 
                    question: "<p>Polymorphism means different forms. Explain Polymorphism in Java and provide examples to support your answer.</p>",
                    answer: `<p>Polymorphism is the ability of a method or an object to take on multiple forms. In OOP, polymorphism allows an operation to exhibit different behaviour in different instances. The behaviour depends upon the type of data used in the operation. For example, consider the operation of addition. For two numbers, the operation will generate a sum. If the operands are strings, then the operation would produce a third string by concatenation.</p>`
                },
                { 
                    question: "<p>Write a short note on the principles of Object Oriented Programming.</p>",
                    answer: `<ol><li><strong>Data Abstraction</strong> — It refers to the act of representing essential features without including the background details.</li><li><strong>Encapsulation</strong> — It is a mechanism that binds the data and code (functions) together into a single unit.</li><li><strong>Inheritance</strong> — It is a powerful mechanism by which one class acquires the properties of another class.</li><li><strong>Polymorphism</strong> — It is the ability of a function or object to take on multiple forms.</li></ol>`
                },
                { 
                    question: "<p>Explain the difference between Inheritance and Encapsulation with suitable examples.</p>",
                    answer: `<p>Encapsulation is a mechanism that binds together code and the data it manipulates whereas Inheritance is the mechanism by which a class acquires the properties and methods of another class. An ATM binding together the different denominations of currency notes and all the operations required to withdraw cash is an example of Encapsulation. Classifying Vehicles as Car, Bike, Bus, Truck, etc. is an example of Inheritance.</p>`
                },
                { 
                    question: "<p>What are the differences between Procedural Programming and Object-oriented Programming?</p>",
                    answer: `N/A`
                },
            ]
        },
        { 
            title: "Introduction to Java",
            questions: [
                { 
                    question: "<p>Java applications ...........</p>",
                    answer: `<ol><li>Are platform dependent</li><li>Don't need a platform to run</li><li><strong>Are platform independent ✓</strong></li><li>Can't run on Windows</li></ol>`
                },
                { 
                    question: "<p>JVM stands for ...........</p>",
                    answer: `<ol><li>Java Visual Monitor</li><li>Java Video Monitor</li><li>Java Virtual Monitor</li><li><strong>Java Virtual Machine ✓</strong></li></ol>`
                },
                { 
                    question: "<p>JRE stands for ...........</p>",
                    answer: `<ol><li>Java Runtime Editor</li><li><strong>Java Runtime Environment ✓</strong></li><li>Java Runtime Expression</li><li>Java Runtime Enabler</li></ol>`
                },
                { 
                    question: "<p>Stand-alone Java applications ........... for execution.</p>",
                    answer: `<ol><li>Need Java-compatible web browser</li><li>Need an assembler</li><li><strong>Don't need Java-compatible web browser ✓</strong></li><li>Need a linker</li></ol>`
                },
                { 
                    question: "<p>Java uses ........... for execution.</p>",
                    answer: `<ol><li><strong>Compiler and interpreter ✓</strong></li><li>Interpreter only</li><li>Compiler only</li><li>None of these</li></ol>`
                },
                { 
                    question: "<p>A program that translates code written in a high-level language into machine code is called ...........</p>",
                    answer: `<ol><li>Assembler</li><li>Linker</li><li><strong>Compiler ✓</strong></li><li>None of these</li></ol>`
                },
                { 
                    question: "<p>A program that translates an assembly language program into machine code is called a/an ...........</p>",
                    answer: `<ol><li><strong>Assembler ✓</strong></li><li>Linker</li><li>Compiler</li><li>None of these</li></ol>`
                },
                { 
                    question: "<p>Java is ...........</p>",
                    answer: `<ol><li>Robust</li><li>Object oriented</li><li>Secure</li><li><strong>All of these ✓</strong></li></ol>`
                },
                { 
                    question: "<p>Java can be used to write ...........</p>",
                    answer: `<ol><li>Stand-alone applications only</li><li><strong>Both stand-alone and internet applications ✓</strong></li><li>Internet applications only</li><li>None of these</li></ol>`
                },
                { 
                    question: "<p>Java applications can run on ...........</p>",
                    answer: `<ol><li>Windows platform</li><li>Macintosh platform</li><li>UNIX platform</li><li><strong>All of these ✓</strong></li></ol>`
                },
                { 
                    question: "<p>Stand-alone Java applications cannot run independently on a computer.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>A console based Java application is designed to be used via a text-only interface.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Java Applets are Java applications that run within a web browser.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The machine language version of the source code generated by the compilation process is specific to the processor you are compiling on.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The Bytecode files are generated with the \".java\" extension.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Java uses a combination of compilation and interpretation.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Just-In-Time (JIT) compiler compiles selected portions of Bytecode into executable code.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>An interpreter translates the source code instructions into machine code all at once.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>JVM stands for Java Virtual Monitor.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Machine language for JVM is called Bytecode.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a short note on types of Java programs.</p>",
                    answer: `<p>There are two types of Java programs — Java Stand-Alone Applications and Java Applets.</p>`
                },
                { 
                    question: "<p>How is Java platform independent?</p>",
                    answer: `<p>Java applications are platform independent, which means that Java applications can be run on any platform. After Java source code is compiled, it is converted into bytecode. The bytecode is then interpreted by the JVM. JVM can be installed on several different operating systems such as Windows, UNIX, Macintosh, etc. JVM allows Java programs to run on almost any computer regardless of its hardware configuration. Due to this factor, Java is considered as a platform independent language.</p>`
                },
                { 
                    question: "<p>Describe the traditional compilation process with a suitable diagram.</p>",
                    answer: `<p>In traditional compilation process, the <strong>machine language code</strong> generated by the compiler <strong>is specific to the platform</strong> for which the source code is compiled. Every high-level language has a separate platform specific compiler to generate the machine code that can run on the specific platform. For example, the executable file generated by compiling a C++ program on Windows platform will not run on Macintosh. It needs to be compiled with C++ Macintosh compiler. The below diagram illustrates this:</p>`
                },
                { 
                    question: "<p>Describe the Java compilation process with a suitable diagram.</p>",
                    answer: `<p>In Java compilation process, the source code of a Java program is compiled to an intermediate binary code called the <strong>Bytecode</strong>. This Bytecode cannot be directly executed by the machine. It is understood by a virtual machine known as <strong>Java Virtual Machine</strong> or <strong>JVM</strong>. JVM contains a Java interpreter which converts the Bytecode into machine code of the target computer. <strong>JVM is platform specific</strong> i.e. each platform has its own JVM. But once the proper JVM is installed on the machine, it can run any Java Bytecode program. The below diagram illustrates this:</p>`
                },
                { 
                    question: "<p>How is traditional compilation process different from Java compilation process?</p>",
                    answer: `<p>In traditional compilation process, the machine language code generated by the compiler is specific to the platform for which the source code is compiled. The executable file generated for one platform like Windows will not work on other platforms like Macintosh. In Java compilation process, the source code of a Java program is compiled to an intermediate binary code called the Bytecode. This Bytecode is interpreted and executed by JVM on the target platform. Bytecode is platform independent so the same Bytecode which is generated for one platform like Windows will work on other platforms like Macintosh without any modifications.</p>`
                },
                { 
                    question: "<p>What are JVM and JIT?</p>",
                    answer: `<p><strong>JVM</strong><br/>JVM stands for the Java Virtual Machine. It is a virtual machine that runs Java programs and can be installed on several different operating systems such as Windows, UNIX, Macintosh etc. JVMs allow Java programs to run on almost any computer. A JVM processes instructions similar to a physical processor. However, the Java code (with \"java\" extension) must first be converted into Bytecode that the JVM understands.</p>`
                },
                { 
                    question: "<p>Distinguish between the following:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>What is Java Bytecode?</p>",
                    answer: `<p>The programs written in Java are compiled into a machine language for a virtual computer called Java Virtual Machine (JVM). The machine language for this special Java Virtual Machine is called Java Bytecode. The Bytecode files are generated with the \".class\" extension.</p>`
                },
                { 
                    question: "<p>Describe the slogan, \"Write once, run anywhere\" in relation to Java.</p>",
                    answer: `<p>Bytecode and JVM makes Java programs platform independent. This is one of the most important and powerful features of Java. The Bytecode of a Java program can be run on any platform that has a corresponding JVM installed on it. No modifications to this Bytecode are required. Hence, it is said that Java provides \"Write once, run anywhere\" capabilities.</p>`
                },
                { 
                    question: "<p>Describe four features of Java.</p>",
                    answer: `<ol><li><strong>Object Oriented</strong> — Java is an object-oriented programming language because it treats everything as an object. The entire program code and data resides within objects and classes. This enables us to easily use and extend the object model.</li><li><strong>Robust</strong> — Java is a robust and reliable programming language. It has strict compile time and runtime checking of data types. Memory allocation and de-allocation is automatic in Java, so there is less for the programmer to worry about.</li><li><strong>Platform Independent</strong> — The concept of \"Write once, run anywhere\", is one of the most important and powerful features of Java. Java applications can run on any platform that has a corresponding JVM installed on it.</li><li><strong>Simple</strong> — Java is simple and easy to learn programming language. The removal of many unreliable features of C and C++ (its predecessors) has simplified its understanding and implementation.</li></ol>`
                },
            ]
        },
        { 
            title: "Elementary Concepts of Objects and Classes",
            questions: [
                { 
                    question: "<p>An object has ...........</p>",
                    answer: `<ol><li>Attributes</li><li>State</li><li>Behaviour</li><li><strong>All of these ✓</strong></li></ol>`
                },
                { 
                    question: "<p>A class is ...........</p>",
                    answer: `<ol><li>An object factory</li><li>A blueprint to create objects</li><li>A specification for objects</li><li><strong>All of these ✓</strong></li></ol>`
                },
                { 
                    question: "<p>........... represents an entity in the real-world with its identity and behaviour.</p>",
                    answer: `<ol><li>A class</li><li><strong>An object ✓</strong></li><li>A procedure</li><li>A method</li></ol>`
                },
                { 
                    question: "<p>........... is a template to create similar objects that share common characteristics and behaviour.</p>",
                    answer: `<ol><li>A function</li><li>A procedure</li><li>An attribute</li><li><strong>A class ✓</strong></li></ol>`
                },
                { 
                    question: "<p>The values of an object's ........... represent the state of the object.</p>",
                    answer: `<ol><li>Methods</li><li>Procedures</li><li><strong>Attributes ✓</strong></li><li>Classes</li></ol>`
                },
                { 
                    question: "<p>The terms object and ........... are often interchangeable.</p>",
                    answer: `<ol><li><strong>Instance ✓</strong></li><li>Behaviour</li><li>Attribute</li><li>State</li></ol>`
                },
                { 
                    question: "<p>A class is a specification about the object.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Only one instance can be created from a single class.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>A class is a user-defined data type.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Real world objects encapsulate state and behaviour.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>There can be multiple abstraction of the same entity.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Objects interact with each other through messages.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>What are objects? Give five examples.</p>",
                    answer: `<p>Objects are identifiable entities that have a set of attributes, behaviour and state. Five examples of objects are car, pen, mobile, email, bank account.</p>`
                },
                { 
                    question: "<p>Explain the anatomy of an object.</p>",
                    answer: `<p>Objects are the basic units of an object oriented system. An object is an identifiable entity that has its own set of attributes, behaviour and state. Attributes are individual characteristics that differentiate one object from another. Behaviour is defined by the set of functions or operations an object can perform. State is defined by the set of values held by its attributes.</p>`
                },
                { 
                    question: "<p>Explain the following statement — \"Class is a specification for objects\".</p>",
                    answer: `<p>As class describes the common attributes and behaviours of its objects in detail, hence we can say that a class is a specification for objects. It can be viewed as a template or blueprint for multiple objects with similar features.</p>`
                },
                { 
                    question: "<p>Explain the following statement — \"Abstraction is relative to the perspective of the viewer\".</p>",
                    answer: `<p>It means that there can be different abstractions of an entity depending on the viewpoint of the user. Lets take the example of a pet dog. From the viewpoint of the dog's owner, the things that are essential for the dog are his favorite food, the colour of his food bowl, his favorite game that he enjoys playing with his owner, his preferred time for walk, etc. From the viewpoint of the dog's vet (doctor for animals), the important things about the dog are whether the dog's body functions are normal or not to ensure that the dog is healthy. Like this, there can be two abstractions for the dog — one for the dog's owner and the other for the dog's vet.</p>`
                },
                { 
                    question: "<p>Identify five possible attributes and three possible behaviours of the following entities:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Explain in detail how a class is different from an object.</p>",
                    answer: `<p>The class is just a specification of the object. The attributes and methods in the class are thus declarations that do not contain any values. However, the object is a concrete instance of a class with properly defined values for each attribute and behaves as per the methods of the class.</p>`
                },
                { 
                    question: "<p>Give the reason why a class is known as:</p>",
                    answer: `<p>The entire data and the code, contained in an object, becomes a user-defined data type using the concept of a class. The class may be considered as a data type and an object as a variable of that data type. For example, once the Bird class has been defined, the statement<br/>     <em>Bird parrot;</em><br/>will create a parrot object belonging to the Bird class.</p>`
                },
                { 
                    question: "<p>How are classes and objects inter-related? Support your answer with an example.</p>",
                    answer: `<table><thead><tr><th>Attributes</th><th>Behaviours</th><th>State</th></tr></thead><tbody><tr><td>Model</td><td>Start car</td><td>Model: Baleno</td></tr><tr><td>Registration Number</td><td>Stop car</td><td>Registration Number: WB 01 AZ 6789</td></tr><tr><td>Colour</td><td>Apply break</td><td>Colour: White</td></tr></tbody></table>`
                },
                { 
                    question: "<p>What do you understand by the term objects encapsulate state and behaviour?</p>",
                    answer: `<p>An object stores its state in member variables and exposes its behaviour through the member methods. The member methods operate on member variables and serve as the primary mechanism to interact with the object. Only the member methods which are wrapped inside the class can access the data and change its state. Hence, the state and behaviour are said to be encapsulated by the object, hiding internal state and requiring all interaction to be performed through the methods of the object.</p>`
                },
                { 
                    question: "<p>Explain how objects communicate with each other?</p>",
                    answer: `<p>Objects communicate with each other by sending messages. The sender object requests the receiver object to perform an action.</p>`
                },
            ]
        },
        { 
            title: "Values and Data Types",
            questions: [
                { 
                    question: "<p>ASCII stands for ...........</p>",
                    answer: `<ol><li><strong>American Standard Code for Information Interchange ✓</strong></li><li>American Simulated Code for Information Interchange</li><li>American Standard Code for Interchange of Information</li><li>American Standard Code for Interaction of Information</li></ol>`
                },
                { 
                    question: "<p>ASCII is ...........</p>",
                    answer: `<ol><li>6-bit set of codes</li><li>8-bit set of codes</li><li><strong>7-bit set of codes ✓</strong></li><li>16-bit set of codes</li></ol>`
                },
                { 
                    question: "<p>Extended ASCII is ...........</p>",
                    answer: `<ol><li>6-bit set of codes</li><li><strong>8-bit set of codes ✓</strong></li><li>7-bit set of codes</li><li>16-bit set of codes</li></ol>`
                },
                { 
                    question: "<p>Which of the following is not a token?</p>",
                    answer: `<ol><li>Keyword</li><li>Identifiers</li><li>Operators</li><li><strong>Procedure ✓</strong></li></ol>`
                },
                { 
                    question: "<p>Which of the following is a keyword?</p>",
                    answer: `<ol><li>character</li><li><strong>break ✓</strong></li><li>object</li><li>attribute</li></ol>`
                },
                { 
                    question: "<p>Which of the following is not a legal identifier?</p>",
                    answer: `<ol><li>_age</li><li>$Amount</li><li><strong>9thClass ✓</strong></li><li>nullValue</li></ol>`
                },
                { 
                    question: "<p>Which of the following is an invalid integer?</p>",
                    answer: `<ol><li>1999</li><li>1999999</li><li><strong>199 99 ✓</strong></li><li>199</li></ol>`
                },
                { 
                    question: "<p>Which of the following is not a character literal?</p>",
                    answer: `<ol><li>'\t'</li><li><strong>\"t\" ✓</strong></li><li>'t'</li><li>All of these</li></ol>`
                },
                { 
                    question: "<p>Which of the following punctuator is the statement terminator in Java?</p>",
                    answer: `<ol><li><strong>; ✓</strong></li><li>.</li><li>,</li><li>All of these</li></ol>`
                },
                { 
                    question: "<p>Which of the following is not a primitive data type in Java?</p>",
                    answer: `<ol><li>boolean</li><li>short</li><li>float</li><li><strong>class ✓</strong></li></ol>`
                },
                { 
                    question: "<p>Which of the following is the size of a long data type in Java?</p>",
                    answer: `<ol><li>32 bits</li><li><strong>64 bits ✓</strong></li><li>48 bits</li><li>Long data type is not supported in Java.</li></ol>`
                },
                { 
                    question: "<p>Which of the following is the size of a boolean data type in Java?</p>",
                    answer: `<ol><li>1 bit</li><li>16 bits</li><li><strong>8 bits ✓</strong></li><li>Boolean data type doesn't take any space in memory.</li></ol>`
                },
                { 
                    question: "<p>Java supports the use of the ASCII character set only.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The smallest unit in a Java program is known as token.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The Unicode character set uses 8 to 32 bits per character.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>In an escape sequence, a character is preceded by a forward slash (/).<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>In Java, an identifier cannot begin with a $ sign.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The boolean data types are used for storing logical values.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Java offers five types of tokens.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The char data type reserves 8 bits in memory.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>If a literal constant contains a decimal point, then it is of the type double by default.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The modifier final can be applied to a variable declaration to ensure that the value stored in the variable cannot be changed after the variable has been initialised.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>What are escape sequences in Java? Give three examples.</p>",
                    answer: `<p>An escape sequence is a set of characters that has a special meaning to the Java compiler. In the escape sequence, a character is preceded by a backslash (\). Some examples of escape sequences are \n, \' and \t.</p>`
                },
                { 
                    question: "<p>What is the result of evaluating the following expression?<br/>  (1 + 2 * 2) / 2 + 2</p>",
                    answer: `<p>    (1 + 2 * 2) / 2 + 2 ⇒ (1 + 4) / 2 + 2<br/>⇒ (5) / 2 + 2<br/>⇒ 2 + 2<br/>⇒ 4</p>`
                },
                { 
                    question: "<p>What is a token in Java? Name the tokens available in Java.</p>",
                    answer: `<p>All characters in a Java program are grouped into symbols called Tokens. As you know, a computer program consists of a set of instructions called statements. A statement is composed of various components. Each individual component of a programming statement is referred to as a token. Keywords, identifiers, Operators, Separators and literals are three tokens in Java.</p>`
                },
                { 
                    question: "<p>Why can't you use a keyword as a variable name?</p>",
                    answer: `<p>Keywords are reserved words that have a special meaning to the Java compiler. As Java compiler reserves these words for its own use so they are not available as names for variables or methods.</p>`
                },
                { 
                    question: "<p>Which of the following are Java keywords?<br/>    input, class, public, int, x, y, radius</p>",
                    answer: `<p>class, public, int are Java keywords.</p>`
                },
                { 
                    question: "<p>What are identifiers in Java? List three identifier formation rules.</p>",
                    answer: `<ol><li>An an identifier can be a sequence of alphabets, digits, underscore and dollar sign characters only.</li><li>Identifiers cannot start with a digit.</li><li>An identifier must not be a Keyword or a Boolean or null literal.</li></ol>`
                },
                { 
                    question: "<p>Explain the following statement — \"In Java, total, Total, ToTaL, and TOTAL are all different identifiers.\"</p>",
                    answer: `<p>Java is a case sensitive language. total, Total, ToTaL, and TOTAL have the same set of letters but they differ in the case of these letters. Therefore, Java considers each of them as a different identifier.</p>`
                },
                { 
                    question: "<p>How would you print characters like \, ' and \" in Java?</p>",
                    answer: `<p>We can print characters like \, ' and \" using escape sequences i.e. preceding it with a backslash (\) symbol.</p>`
                },
                { 
                    question: "<p>Distinguish between the following:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Distinguish between \"A\" and 'A'.</p>",
                    answer: `<p>\"A\" is a string literal of length 1 containing the letter A in uppercase whereas 'A' is a character literal having value of A in uppercase.</p>`
                },
                { 
                    question: "<p>Describe primitive data types in Java.</p>",
                    answer: `<p>Primitive data types are fundamental data types that are an integral part of the Java language and are used to declare a variable.</p>`
                },
                { 
                    question: "<p>List the size of primitive data types in Java.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Which integer and floating point data types take up the same number of bits in computer's memory?</p>",
                    answer: `<p>Both, int and float take up 32 bits in memory. Similarly, both long and double take up 64 bits in memory.</p>`
                },
                { 
                    question: "<p>What is variable initialisation in Java? What are the default values of the following type of variables:<br/>short, int, long, float, double, and char.</p>",
                    answer: `<table><thead><tr><th>Data Type</th><th>Default Value</th></tr></thead><tbody><tr><td>short</td><td>0</td></tr><tr><td>int</td><td>0</td></tr><tr><td>long</td><td>0L</td></tr><tr><td>float</td><td>0.0f</td></tr><tr><td>double</td><td>0.0d</td></tr><tr><td>char</td><td>'\u0000'</td></tr></tbody></table>`
                },
            ]
        },
        { 
            title: "Operators in Java",
            questions: [
                { 
                    question: "<p>An operator taking only single operand for its operation is called ...........</p>",
                    answer: `<ol><li><strong>A unary operator ✓</strong></li><li>A binary operator</li><li>A ternary operator</li><li>None of these</li></ol>`
                },
                { 
                    question: "<p>Which one of the following is not a binary operator?</p>",
                    answer: `<ol><li>AND</li><li>%</li><li>==</li><li><strong>! ✓</strong></li></ol>`
                },
                { 
                    question: "<p>Which one of the following is not a valid operator in Java?</p>",
                    answer: `<ol><li>&lt;=</li><li><strong>!== ✓</strong></li><li>!=</li><li>==</li></ol>`
                },
                { 
                    question: "<p>The statement i = i + 1 is equivalent to ...........</p>",
                    answer: `<ol><li>i++</li><li>i += 1</li><li>++i</li><li><strong>All of these ✓</strong></li></ol>`
                },
                { 
                    question: "<p>For x = 5, the statement sum = ++x + 8 evaluates to ...........</p>",
                    answer: `<ol><li>sum = 13</li><li><strong>sum = 14 ✓</strong></li><li>sum = 15</li><li>sum = 16</li></ol>`
                },
                { 
                    question: "<p>The statement (1&gt;0) &amp;&amp; (1&lt;0) evaluates to ...........</p>",
                    answer: `<ol><li>0</li><li>1</li><li><strong>false ✓</strong></li><li>true</li></ol>`
                },
                { 
                    question: "<p>The statement (1&gt;0) || (1&lt;0) evaluates to ...........</p>",
                    answer: `<ol><li>0</li><li>1</li><li>false</li><li><strong>true ✓</strong></li></ol>`
                },
                { 
                    question: "<p>The statement (1==1)? 1: 0 evaluates to ...........</p>",
                    answer: `<ol><li>0</li><li><strong>1 ✓</strong></li><li>false</li><li>true</li></ol>`
                },
                { 
                    question: "<p>The expression 13 % 3 gives the output ...........</p>",
                    answer: `<ol><li>4</li><li>3</li><li>2</li><li><strong>1 ✓</strong></li></ol>`
                },
                { 
                    question: "<p>The expression 13 / 3 gives the output ...........</p>",
                    answer: `<ol><li><strong>4 ✓</strong></li><li>3</li><li>0</li><li>1</li></ol>`
                },
                { 
                    question: "<p>The statement System.out.println(\"six \" + 3 + 3); gives the output ...........</p>",
                    answer: `<ol><li><strong>six 33 ✓</strong></li><li>six 6</li><li>33 six</li><li>6 six</li></ol>`
                },
                { 
                    question: "<p>The expression 4 + 8 % 2 gives the output ...........</p>",
                    answer: `<ol><li>6</li><li>8</li><li><strong>4 ✓</strong></li><li>None of these</li></ol>`
                },
                { 
                    question: "<p>Arithmetic operators + and - also have a unary form.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Operators = and == perform the same operation in Java.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The expression 10 % 4 evaluates to 2.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The expression 3 / 4 evaluates to 0.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The expressions 3 + 4 and \"3\" + \"4\" evaluate to the same value.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The expression x = x + 7 is same as x =+ 7.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The new operator allocates memory during runtime.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The statements x = 7 and x == 7 are same.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The expression z =- 7 is same as z = z-7.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The assignment operator (=) is a binary operator.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>What are logical operators? Give an example of each.</p>",
                    answer: `<table><thead><tr><th>Operator</th><th>Symbol</th></tr></thead><tbody><tr><td>Logical AND</td><td>&&</td></tr><tr><td>Logical OR</td><td>||</td></tr><tr><td>Logical NOT</td><td>!</td></tr></tbody></table>`
                },
                { 
                    question: "<p>What is an assignment operator? Give an example.</p>",
                    answer: `<p>Assignment operator is used to assign the value of an expression to a variable. It has the following syntax:</p>`
                },
                { 
                    question: "<p>Explain the shorthand assignment operator with an example.</p>",
                    answer: `<p>Java provides shorthand assignment operators for all the arithmetic binary operators. Shorthand assignment operators follow the below syntax:</p>`
                },
                { 
                    question: "<p>What is the use and syntax of a ternary operator?</p>",
                    answer: `<p>Ternary operator is used to check if a condition is true and false. Depending on whether the condition tests true or false, expression 1 or expression 2 is evaluated. Its syntax is:</p>`
                },
                { 
                    question: "<p>State the difference between = and ==.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>If a = 5, b = 9, calculate the value of:<br/>    a += a++ - ++b + a</p>",
                    answer: `<p>    a += a++ - ++b + a<br/>⇒ a = a + (a++ - ++b + a)<br/>⇒ a = 5 + (5 - 10 + 6)<br/>⇒ a = 5 + 1<br/>⇒ a = 6</p>`
                },
                { 
                    question: "<p>If x = 3, y = 7, calculate the value of:<br/>x -= x++ - ++y</p>",
                    answer: `<p>    x -= x++ - ++y<br/>⇒ x = x - (x++ - ++y)<br/>⇒ x = 3 - (3 - 8)<br/>⇒ x = 3 - (-5)<br/>⇒ x = 3 + 5<br/>⇒ x = 8</p>`
                },
                { 
                    question: "<p>What will be the output of the following if x=5?<br/>i. 5 * ++x<br/>ii. 5 * x++</p>",
                    answer: `<p>i.  5 * ++x<br/>⇒ 5 * 6<br/>⇒ 30</p>`
                },
                { 
                    question: "<p>What is type conversion? How is an implicit type conversion different from explicit type conversion?</p>",
                    answer: `<p>Type conversion is a process that converts a value of one data type to another data type.</p>`
                },
                { 
                    question: "<p>What do you understand by type conversion?</p>",
                    answer: `<p>Type conversion is a process that converts a value of one data type to another data type.</p>`
                },
                { 
                    question: "<p>What is typecasting in Java? Give an example.</p>",
                    answer: `<p>The process of converting a value of one data type to another data type is called typecasting. For example:</p>`
                },
                { 
                    question: "<p>What are precedence and associativity?</p>",
                    answer: `<p>Precedence of operators refers to the order in which the operators are applied to the operands in an expression.<br/>Associativity of operators refers to the direction of execution of operators (\"Left to Right\" or \"Right to Left\") when operators in an expression have the same precedence.</p>`
                },
                { 
                    question: "<p>Evaluate the following expressions, if the values of the variables are a = 2, b = 3 and c = 3</p>",
                    answer: `<p>i.  a - (b++) * (--c)<br/>⇒ 2 - 3 * 2<br/>⇒ 2 - 6<br/>⇒ -4</p>`
                },
                { 
                    question: "<p>Write the Java expressions for the following:</p>",
                    answer: `<p>x &gt;= 0 &amp;&amp; x &lt;= 50</p>`
                },
                { 
                    question: "<p>Rewrite the following statements without using shorthand operators.</p>",
                    answer: `<p>a. p = p / q<br/>b. p = p - 1<br/>c. p = p * (q + r)<br/>d. p = p - (q - r)</p>`
                },
                { 
                    question: "<p>Determine the output of the following program.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>What is the difference between the following two statements? Explain the results.<br/>x -= 5;<br/>x =- 5;</p>",
                    answer: `<p>The first statement, <code>x -= 5;</code> subtracts 5 from x and assigns the result back to x. It is equivalent to <code>x = x - 5;</code><br/>The second statement, <code>x =- 5;</code> assigns the value of -5 to x.</p>`
                },
                { 
                    question: "<p>What is concatenation? On which data type is concatenation performed?</p>",
                    answer: `<p>Concatenation means joining two strings together. It is performed on String data type.</p>`
                },
                { 
                    question: "<p>Determine the output of the following program.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Determine the output of the following program.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Determine the output of the following program.</p>",
                    answer: `N/A`
                },
            ]
        },
        { 
            title: "Working with Methods",
            questions: [
                { 
                    question: "<p>A method that does not return a value has a ........... return type.</p>",
                    answer: `<ol><li>boolean</li><li>class</li><li>int</li><li><strong>void ✓</strong></li></ol>`
                },
                { 
                    question: "<p>A method can return ...........</p>",
                    answer: `<ol><li>Any number of values</li><li>2 values</li><li><strong>Only 1 value ✓</strong></li><li>3 values</li></ol>`
                },
                { 
                    question: "<p>If a method returns a value, then it must be ...........</p>",
                    answer: `<ol><li><strong>The same data type as defined in its prototype ✓</strong></li><li>void type</li><li>int type</li><li>boolean type</li></ol>`
                },
                { 
                    question: "<p>Parameters in the method definition are called ...........</p>",
                    answer: `<ol><li><strong>Formal parameters ✓</strong></li><li>Actual parameters</li><li>Informal parameters</li><li>void parameters</li></ol>`
                },
                { 
                    question: "<p>The parameters that are passed to the method when it is invoked are called ...........</p>",
                    answer: `<ol><li>Formal parameters</li><li><strong>Actual parameters ✓</strong></li><li>Informal parameters</li><li>void parameters</li></ol>`
                },
                { 
                    question: "<p>The scope of a local variable is limited to the ...........</p>",
                    answer: `<ol><li>Windows</li><li>Multiple programs</li><li>Class</li><li><strong>Method or block it is declared in ✓</strong></li></ol>`
                },
                { 
                    question: "<p>A method can return more than one value.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Methods defined as void must return a value.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>A method may contain any number of return statements.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The non-static methods need an instance to be called.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The static methods need an instance to be called.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>If a method returns a value, then it must be of the same data type as defined in the method prototype.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Parameters in the method definition are called dummy parameters.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Methods reside in a class in Java.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The scope of a local variable is limited to the method or the block it is declared in.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The keyword static makes a variable a class variable.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>What is a method? Explain the various parts of a method.</p>",
                    answer: `<p>A method is a named block of code within a class. It executes a defined set of instructions when called from another part of the program. The different parts of the method are <strong>access-modifier</strong>, <strong>type</strong>, <strong>method-name</strong>, <strong>parameter-list</strong> and <strong>method-body</strong>.</p>`
                },
                { 
                    question: "<p>How do you define and invoke a method?</p>",
                    answer: `<p>The syntax of a method definition is:</p>`
                },
                { 
                    question: "<p>Explain the role of return statement in a method?</p>",
                    answer: `<p>Return statement sends back the value given to it from the called method to the caller method. It also transfers the program control back to the caller method from the called method.</p>`
                },
                { 
                    question: "<p>What does void signify in the method prototype?</p>",
                    answer: `<p>void in method prototype means that the method does not return any value.</p>`
                },
                { 
                    question: "<p>Explain the difference between actual and formal parameters with one example of each.</p>",
                    answer: `<p>The parameters that appear in the method definition are called formal or dummy parameters whereas the parameters that appear in the method call are called actual parameters. Example:</p>`
                },
                { 
                    question: "<p>Explain static and non-static methods.</p>",
                    answer: `<p>Static methods are created with static keyword in their method prototype as shown below:</p>`
                },
                { 
                    question: "<p>Explain the scope of variables in Java</p>",
                    answer: `<ol><li><strong>Local Variables</strong> — The variables declared inside a method or block are called local variables. The scope of local variables is limited to the method or the block they are declared in.</li><li><strong>Parameter Variables</strong> — The variables used as arguments in the method prototype are called parameter variables. Their scope is limited to the method where they are being used.</li><li><strong>Instance Variables (non-static variable)</strong> — The member variables that store the state of an object are known as instance variables.</li><li><strong>Class Variables (static variable)</strong> — A variable declared in a class with the static modifier is called a class variable.</li></ol>`
                },
                { 
                    question: "<p>Write a class that contains the following two methods:</p>",
                    answer: `<ol><li>fahrenheit = (9.0 / 5) * celsius + 32</li><li>celsius = (5.0 / 9) * (fahrenheit - 32)</li></ol>`
                },
            ]
        },
        { 
            title: "Input in Java",
            questions: [
                { 
                    question: "<p>Single line comments can be added using ...........</p>",
                    answer: `<ol><li>//</li><li>/* */</li><li>\\</li><li><strong>Both A and B ✓</strong></li></ol>`
                },
                { 
                    question: "<p>Which keyword do you use to include a class in your program?</p>",
                    answer: `<ol><li><strong>import ✓</strong></li><li>export</li><li>include</li><li>impart</li></ol>`
                },
                { 
                    question: "<p>Default delimiter used in the Scanner class is ...........</p>",
                    answer: `<ol><li>Comma</li><li><strong>Whitespace ✓</strong></li><li>Colon</li><li>There is no default delimiter.</li></ol>`
                },
                { 
                    question: "<p>Which package would you import to display the date and time?</p>",
                    answer: `<ol><li><strong>java.util ✓</strong></li><li>java.Date</li><li>java.io</li><li>java.lang</li></ol>`
                },
                { 
                    question: "<p>Which package would you import for the Scanner class?</p>",
                    answer: `<ol><li><strong>java.util.* ✓</strong></li><li>java.awt.*</li><li>java.io.*</li><li>java.lang.*</li></ol>`
                },
                { 
                    question: "<p>Errors occur in a program when ...........</p>",
                    answer: `<ol><li>Syntax of the programming language is not followed.</li><li>The program does not run properly or does not execute at all.</li><li>The program produces an incorrect result.</li><li><strong>All of the above ✓</strong></li></ol>`
                },
                { 
                    question: "<p>What is a java package? Give an example.</p>",
                    answer: `<p>A package is a named collection of Java classes that are grouped on the basis of their functionality. For example, java.util.</p>`
                },
                { 
                    question: "<p>Explain the use of import statement with an example.</p>",
                    answer: `<p>import statement is used to import built-in and user-defined packages and classes into our Java program. For example, we can import all the classes present in the java.util package into our program using the below statement:</p>`
                },
                { 
                    question: "<p>Explain the statement, \"a well-documented code is as important as the correctly working code\".</p>",
                    answer: `<p>Writing fully commented code is a good programming style. The primary purpose of comments is to document the code so that even a layman can understand the purpose of the written code. Hence, a well-documented code is as important as the correctly working code.</p>`
                },
                { 
                    question: "<p>How can you write single line comments in Java?</p>",
                    answer: `<ol start=\"2\"><li><strong>Using Multi line comments style</strong> — In this style, comments start with the initiating slash-asterisk (/*) and ends with the terminating asterisk-slash(*/). For example:</li></ol>`
                },
                { 
                    question: "<p>Explain data input technique in a program using the Scanner class.</p>",
                    answer: `<p>The data entered by the user through the keyboard is provided to our Java program with the help of standard input stream that is specified as System.in in the program. We connect our Scanner class to this stream while creating its object. The input received by Scanner class is broken into tokens using a delimiter pattern. The default delimiter is whitespace. Scanner class provides various next methods to read these tokens from the stream. The different next methods provided by Scanner class are next(), nextShort(), nextInt(), nextLong(), nextFloat(), nextDouble(), nextLine(), next().charAt(0).</p>`
                },
                { 
                    question: "<p>Distinguish between the following:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>What are delimiters? Which is the default delimiter used in the Scanner class?</p>",
                    answer: `<p>A delimiter is a sequence of one or more characters that separates two tokens. The default delimiter is a whitespace.</p>`
                },
                { 
                    question: "<p>What are errors in a program?</p>",
                    answer: `<p>Errors are mistakes in a program that prevents it from its normal working. They are often referred to as bugs. Errors are classified into three categories — Syntax Errors, Runtime Errors and Logical Errors.</p>`
                },
                { 
                    question: "<p>Explain the following terms, giving an example of each.</p>",
                    answer: `<p>When a program compiles and runs without errors but produces an incorrect result, this type of error is known as logical error. It is caused by a mistake in the program logic which is often due to human error. Logical errors are not caught by the compiler. Consider the below example for finding the perimeter of a rectangle with length 2 and breadth 4:</p>`
                },
                { 
                    question: "<p>A program has compiled successfully without any errors. Does this mean the program is error free? Explain.</p>",
                    answer: `<p>Successful compilation of the program only ensures that there are no syntax errors. Runtime and logical errors can still be there in the program so we cannot say that the program is error free. Only after comprehensive testing of the program, we can say that the program is error free.</p>`
                },
                { 
                    question: "<p>Write a program in Java that uses 'input using initialisation' to calculate the Simple Interest and the Total Amount with the given values:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java that accepts the seconds as input and converts them into the corresponding number of hours, minutes and seconds. A sample output is shown below:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in java to input the temperature in Fahrenheit, convert it into Celsius and display the value in the Terminal window. A sample output is displayed below:</p>",
                    answer: `N/A`
                },
            ]
        },
        { 
            title: "Mathematical Library Methods",
            questions: [
                { 
                    question: "<p>Which Java package includes the Math class?</p>",
                    answer: `<ol><li>java.io</li><li><strong>java.lang ✓</strong></li><li>java.util</li><li>java.sys</li></ol>`
                },
                { 
                    question: "<p>Give the output of Math.sqrt(x); when x = 9.0</p>",
                    answer: `<ol><li>3</li><li><strong>3.0 ✓</strong></li><li>3.00</li><li>All of these</li></ol>`
                },
                { 
                    question: "<p>Give the output of Math.ceil(46.6).</p>",
                    answer: `<ol><li>46.6</li><li>46.5</li><li><strong>47.0 ✓</strong></li><li>46.0</li></ol>`
                },
                { 
                    question: "<p>Give the output of Math.abs(x); when x = -9.99</p>",
                    answer: `<ol><li>-9.99</li><li><strong>9.99 ✓</strong></li><li>0.99</li><li>None of these</li></ol>`
                },
                { 
                    question: "<p>Which of the following is a method to find the square root of a number?</p>",
                    answer: `<ol><li>FindSquareroot(x)</li><li>Sqrt(x)</li><li>Math.Square(x)</li><li><strong>Math.sqrt(x) ✓</strong></li></ol>`
                },
                { 
                    question: "<p>What will be the output of Math.pow(2, 0)?</p>",
                    answer: `<ol><li>0.0</li><li><strong>1.0 ✓</strong></li><li>2.0</li><li>-1.0</li></ol>`
                },
                { 
                    question: "<p>Math.random() returns a double value r such that</p>",
                    answer: `<ol><li><strong>0.0 &lt;= r &lt; 1.0 ✓</strong></li><li>0.0 &lt;= r &lt;= 1.0</li><li>0.0 &lt; r &lt;= 1.0</li><li>0.0 &lt; r &lt; 1.0</li></ol>`
                },
                { 
                    question: "<p>Give the output of Math.floor(46.6)?</p>",
                    answer: `<ol><li>46.5</li><li>47.0</li><li>-46.0</li><li><strong>46.0 ✓</strong></li></ol>`
                },
                { 
                    question: "<p>Distinguish between Math.ceil() and Math.floor() methods.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Explain the following Math functions in Java:</p>",
                    answer: `<p>Rounds off its argument to the nearest mathematical integer and returns its value as an int or long type. If argument is float, return type is int, if argument is double, return type is long. At mid-point, it returns the higher integer. For example, Math.round(2.5) will return 3.</p>`
                },
                { 
                    question: "<p>Write the following as Java expressions:</p>",
                    answer: `<p>(amount*rate) / (1 - (1 / Math.pow(1+rate, n)))</p>`
                },
                { 
                    question: "<p>Write valid statements for the following in Java:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to find the minimum of three numbers using Math.min() method.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program to print:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program to generate random integers in the range 10 to 20.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write the equivalent Java statements for the following, only using the mathematical functions:</p>",
                    answer: `N/A`
                },
            ]
        },
        { 
            title: "Conditional Constructs in Java",
            questions: [
                { 
                    question: "<p>Which operator cannot be used with if-else statement?</p>",
                    answer: `<ol><li>&lt;=</li><li>||</li><li>&amp;&amp;</li><li><strong>? : ✓</strong></li></ol>`
                },
                { 
                    question: "<p>Predict the output of the following code snippet:</p>",
                    answer: `<ol><li>Both values are equal</li><li>Incorrect use of the == operator</li><li><strong>Values are not equal ✓</strong></li><li>No output</li></ol>`
                },
                { 
                    question: "<p>Consider the following code snippet:</p>",
                    answer: `<ol><li><strong>x = (c &gt;d) ? c : d; ✓</strong></li><li>x = (c &gt;d) ? d : c;</li><li>x = (c &gt;d) ? c : c;</li><li>x = (c &gt;d) ? d : d;</li></ol>`
                },
                { 
                    question: "<p>if ((a &gt; b) &amp;&amp; (a &gt; c)), then which of the following statements is true?</p>",
                    answer: `<ol><li><strong>a is the largest number. ✓</strong></li><li>b is the largest number.</li><li>c is the largest number.</li><li>b is the smallest number.</li></ol>`
                },
                { 
                    question: "<p>Consider the following code snippet:</p>",
                    answer: `<ol><li>case 1 will be executed.</li><li><strong>case 2 will be executed. ✓</strong></li><li>default will be executed.</li><li>both case 1 and 2 will be executed.</li></ol>`
                },
                { 
                    question: "<p>A sequence of statements enclosed between a pair of curly brackets is called</p>",
                    answer: `<ol><li><strong>a compound statement ✓</strong></li><li>an empty statement</li><li>a null statement</li><li>a void statement</li></ol>`
                },
                { 
                    question: "<p>Which clause is optional in the switch statement?</p>",
                    answer: `<ol><li><strong>default ✓</strong></li><li>case</li><li>switch</li><li>None of these</li></ol>`
                },
                { 
                    question: "<p>Which of the following statements involves a fall-through?</p>",
                    answer: `<ol><li>if-else</li><li>for loop</li><li>if-else-if ladder</li><li><strong>switch ✓</strong></li></ol>`
                },
                { 
                    question: "<p>Which of the following causes a fall-through in the switch statement?</p>",
                    answer: `<ol><li>the omission of fall</li><li>the omission of continue</li><li><strong>the omission of break ✓</strong></li><li>the omission of loop</li></ol>`
                },
                { 
                    question: "<p>Which of the following is mandatory in the switch statement?</p>",
                    answer: `<ol><li>break</li><li>continue</li><li><strong>case ✓</strong></li><li>default</li></ol>`
                },
                { 
                    question: "<p>Explain the significance of break statement in the switch statement.</p>",
                    answer: `<p>The break statement is used inside the switch statement to terminate a statement block. It brings the program control out of the switch statement.</p>`
                },
                { 
                    question: "<p>Explain the significance of the default label in the switch statement.</p>",
                    answer: `<p>When none of the case values are equal to the expression of switch statement then default case is executed. In the example below, value of number is 4 so case 0, case 1 and case 2 are not equal to number. Hence println statement of default case will get executed printing \"Value of number is greater than two\" to the console.</p>`
                },
                { 
                    question: "<p>Format the following if statements with indentation:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Rewrite the following if statement, using the switch statement:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write the following switch statement by using nested if statements:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write an if statement to find the smallest of the three given integers using the min() method of the Math class.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Using the switch statement in Java, write a program to display the colour of the spectrum (VIBGYOR) according to the user's choice.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Employees at Arkenstone Consulting earn the basic hourly wage of Rs.500. In addition to this, they also receive a commission on the sales they generate while tending the counter. The commission given to them is calculated according to the following table:</p>",
                    answer: `<table><thead><tr><th>Total Sales</th><th>Commmision Rate</th></tr></thead><tbody><tr><td>Rs. 100 to less than Rs. 1000</td><td>1%</td></tr><tr><td>Rs. 1000 to less than Rs. 10000</td><td>2%</td></tr><tr><td>Rs. 10000 to less than Rs. 25000</td><td>3%</td></tr><tr><td>Rs. 25000 and above</td><td>3.5%</td></tr></tbody></table>`
                },
                { 
                    question: "<p>Write a program in Java to compute the perimeter and area of a triangle, with its three sides given as a, b, and c using the following formulas:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Mayur Transport Company charges for parcels as per the following tariff:</p>",
                    answer: `<table><thead><tr><th>Weight</th><th>Charges</th></tr></thead><tbody><tr><td>Upto 10 Kg.</td><td>Rs. 30 per Kg.</td></tr><tr><td>For the next 20 Kg.</td><td>Rs. 20 per Kg.</td></tr><tr><td>Above 30 Kg.</td><td>Rs. 15 per Kg.</td></tr></tbody></table>`
                },
                { 
                    question: "<p>The electricity board charges the bill according to the number of units consumed and the rate as given below:</p>",
                    answer: `<table><thead><tr><th>Units Consumed</th><th>Rate Per Unit</th></tr></thead><tbody><tr><td>First 100 units</td><td>80 Paisa per unit</td></tr><tr><td>Next 200 units</td><td>Rs. 1 per unit</td></tr><tr><td>Above 300 units</td><td>Rs. 2.50 per unit</td></tr></tbody></table>`
                },
                { 
                    question: "<p>Write a menu driven program to accept a number from the user and check whether it is a Buzz number or an Automorphic number.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to accept three numbers and check whether they are Pythagorean Triplet or not. The program must display the message accordingly. [Hint: h<sup>2</sup>=p<sup>2</sup>+b<sup>2</sup>]</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>A cloth showroom has announced the following festival discounts on the purchase of items based on the total cost of the items purchased:</p>",
                    answer: `<table><thead><tr><th>Total Cost</th><th>Discount Rate</th></tr></thead><tbody><tr><td>Less than Rs. 2000</td><td>5%</td></tr><tr><td>Rs. 2000 to less than Rs. 5000</td><td>25%</td></tr><tr><td>Rs. 5000 to less than Rs. 10,000</td><td>35%</td></tr><tr><td>Rs. 10,000 and above</td><td>50%</td></tr></tbody></table>`
                },
                { 
                    question: "<p>A box of cookies can hold 24 cookies, and a container can hold 75 boxes of cookies. Write a program that prompts the user to enter the total number of cookies, the number of cookies in each box, and the number of cookies boxes in a container. The program then outputs the number of boxes and the number of containers required to ship the cookies.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>A special two-digit number is such that when the sum of its digits is added to the product of its digits, the result is equal to the original two-digit number.<br/>Example: Consider the number 59.<br/>Sum of digits = 5 + 9 = 14<br/>Product of digits = 5 * 9 = 45<br/>Sum of the sum of digits and product of digits = 14 + 45 = 59<br/>Write a program to accept a two-digit number. Add the sum of its digits to the product of its digits. If the value is equal to the input number, display the message \"Special 2 - digit number\" otherwise, display the message \"Not a special two-digit number\".</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Using the switch statement, write a menu driven program:</p>",
                    answer: `<ol><li>To check and display whether a number input by the user is a composite number or not.<br/>A number is said to be composite, if it has one or more than one factors excluding 1 and the number itself.<br/>Example: 4, 6, 8, 9...</li><li>To find the smallest digit of an integer that is input:<br/>Sample input: 6524<br/>Sample output: Smallest digit is 2<br/>For an incorrect choice, an appropriate error message should be displayed.</li></ol>`
                },
            ]
        },
        { 
            title: "Iterative Constructs in Java",
            questions: [
                { 
                    question: "<p>Which of the following loop executes at least once?</p>",
                    answer: `<p>do-while</p>`
                },
                { 
                    question: "<p>Which of the following is an exit-controlled loop?</p>",
                    answer: `<p>do-while</p>`
                },
                { 
                    question: "<p>Which of the following is an invalid loop?</p>",
                    answer: `<p>repeat</p>`
                },
                { 
                    question: "<p>Which of the following is not a jump statement in Java?</p>",
                    answer: `<p>jump</p>`
                },
                { 
                    question: "<p>How many times will the following code print \"Hello\"?</p>",
                    answer: `<p>1</p>`
                },
                { 
                    question: "<p>What will be the output of the following code?</p>",
                    answer: `<table><thead><tr><th>No. of Iterations</th><th>Value of i</th><th>Value of sum</th><th>Test Condition</th></tr></thead><tbody><tr><td>1st</td><td>1</td><td>1</td><td>True</td></tr><tr><td>2nd</td><td>2</td><td>2</td><td>True</td></tr><tr><td>3rd</td><td>3</td><td>3</td><td>True</td></tr><tr><td>4th</td><td>4</td><td>4</td><td>True</td></tr><tr><td>5th</td><td>5</td><td>5</td><td>False</td></tr></tbody></table>`
                },
                { 
                    question: "<p>How many times will the following loop execute?</p>",
                    answer: `<p>Infinite loop</p>`
                },
                { 
                    question: "<p>How many times will the following loop execute?</p>",
                    answer: `<table><thead><tr><th>Iterations</th><th>i</th><th>i++ % 2 == 0</th><th>Output</th><th>Remarks</th></tr></thead><tbody><tr><td>1st</td><td>1</td><td>False</td><td></td><td>Value ofibecomes 2 after if check asi++increments it after the evaluation ofi++ % 2 == 0</td></tr><tr><td>2nd</td><td>2</td><td>True</td><td>3</td><td>ibecomes 3 after if check, hence 3 is printed in this iteration.</td></tr><tr><td>3rd</td><td>3</td><td>False</td><td></td><td>ibecomes 4.</td></tr><tr><td>4th</td><td>4</td><td>True</td><td>5</td><td>ibecomes 5.</td></tr><tr><td>5th</td><td>5</td><td>False</td><td></td><td>ibecomes 6.</td></tr><tr><td>6th</td><td>6</td><td>True</td><td>7</td><td>ibecomes 7.</td></tr><tr><td>7th</td><td>7</td><td>False</td><td></td><td>ibecomes 8.</td></tr><tr><td>8th</td><td>8</td><td>True</td><td>9</td><td>ibecomes 9.</td></tr><tr><td>9th</td><td>9</td><td>False</td><td></td><td>ibecomes 10.</td></tr></tbody></table>`
                },
                { 
                    question: "<p>To execute a do-while loop, the condition must be true in the beginning.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The while loop is an exit-controlled loop.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The while part of a do-while statement must be terminated by a semicolon.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The continue statement terminates the current loop and then continues from the statement immediately following the current loop.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The return statement is a jump statement.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The for loop may contain multiple initialisations and updates.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>A loop that never terminates is called an empty loop.<br/><em><strong>False</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>The do-while loop executes at least once even if the condition is false.<br/><em><strong>True</strong></em></p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>What are looping control structures?</p>",
                    answer: `<p>A loop is a set of instructions that is continually repeated until a certain condition is met. Looping control structures refer to certain looping constructs which execute a block of code repeatedly until a certain condition remains true. For example, for loop, while loop, do - while loop etc.</p>`
                },
                { 
                    question: "<p>What are the essential parts of a looping control structure?</p>",
                    answer: `<ol><li><strong>Initialisation</strong> — This segment initialises the loop control variable before starting the loop. It is executed only once at the beginning of the loop.<br/>For example, <code>int counter = 1;</code></li><li><strong>Test-condition</strong> — The test-condition is the expression that is evaluated at the beginning of each iteration. Its value determines whether the body of the loop is to be executed (test condition is true) or the loop is to be terminated (test condition is false).<br/>For example, <code>counter &lt;= 10</code></li><li><strong>Update</strong> — This is the increment or decrement operation of the control variable. This operation is performed at the end of each iteration.<br/>For example, <code>counter++ ;</code></li></ol>`
                },
                { 
                    question: "<p>How are these statements different from each other:</p>",
                    answer: `<p>(i) The <strong>break statement</strong> terminates the current loop or switch statement. The execution then continues from the statement immediately following the current loop or switch statement.</p>`
                },
                { 
                    question: "<p>Identify all the errors in the following repetitive statements.</p>",
                    answer: `<p>The test expression <code>i &gt; 0</code> will always remain <code>true</code> and hence, the for loop will become an infinite loop and keep on iterating.</p>`
                },
                { 
                    question: "<p>Identify all the errors in the following repetitive statements.</p>",
                    answer: `<p>The test expression <code>z &lt; 1 &amp;&amp; z &gt; 100</code> will never be <code>true</code> as the conditions <code>z &lt; 1</code> and <code>z &gt; 100</code> cannot be <code>true</code> at the same time. Thus, the <code>&amp;&amp;</code> operator will always result in <code>false</code>.</p>`
                },
                { 
                    question: "<p>Identify all the errors in the following repetitive statements.</p>",
                    answer: `<p>The test expression <code>x == y</code> will always remain <code>true</code> as in each iteration <code>x = y</code> is executed, which will store the value of <code>y</code> in <code>x</code>. Thus, an infinite loop will be generated.</p>`
                },
                { 
                    question: "<p>What is an empty statement? Explain its usefulness.</p>",
                    answer: `<p>Empty statement consists only of a semicolon <code>;</code>. It is useful when we want to make a loop an empty loop.</p>`
                },
                { 
                    question: "<p>Convert the following for loop statement into the corresponding while loop and do-while loop:</p>",
                    answer: `<p>while loop</p>`
                },
                { 
                    question: "<p>What are the differences between while loop and do-while loop?</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>How many times are the following loop bodies repeated? What is the final output in each case?</p>",
                    answer: `<p>The loop repeats for infinite times.</p>`
                },
                { 
                    question: "<p>How many times are the following loop bodies repeated? What is the final output in each case?</p>",
                    answer: `<p>The loop repeats for infinite times.</p>`
                },
                { 
                    question: "<p>How many times are the following loop bodies repeated? What is the final output in each case?</p>",
                    answer: `<table><thead><tr><th>Iterations</th><th>z</th><th>(z++) % 2 == 0</th><th>Output</th><th>Remarks</th></tr></thead><tbody><tr><td>1st</td><td>1</td><td>False</td><td></td><td>Value ofzbecomes 2 after if check asz++increments it after the evaluation of(z++) % 2 == 0</td></tr><tr><td>2nd</td><td>2</td><td>True</td><td>3</td><td>zbecomes 3 after if check, hence 3 is printed in this iteration.</td></tr><tr><td>3rd</td><td>3</td><td>False</td><td></td><td>zbecomes 4.</td></tr><tr><td>4th</td><td>4</td><td>True</td><td>5</td><td>zbecomes 5.</td></tr><tr><td>5th</td><td>5</td><td>False</td><td></td><td>zbecomes 6.</td></tr><tr><td>6th</td><td>6</td><td>True</td><td>7</td><td>zbecomes 7.</td></tr><tr><td>7th</td><td>7</td><td>False</td><td></td><td>zbecomes 8.</td></tr><tr><td>8th</td><td>8</td><td>True</td><td>9</td><td>zbecomes 9.</td></tr><tr><td>9th</td><td>9</td><td>False</td><td></td><td>zbecomes 10.</td></tr></tbody></table>`
                },
                { 
                    question: "<p>Write a program to accept n number of input integers and find out:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program using do-while loop to compute the sum of the first 50 positive odd integers.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program to read the number n via the Scanner class and print the Tribonacci series:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program to calculate the value of Pi with the help of the following series:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a menu-driven program to display the pattern as per user's choice:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program to input a number and check and print whether it is a Pronic number or not. (Pronic number is a number which is the product of two consecutive integers.)</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program to accept a number and check and display whether it is a spy number or not. (A number is spy if the sum of its digits equals the product of the digits.)<br/>Example: Consider the number, 1124. Sum of the digits = 1 + 1 + 2 + 4 = 8.<br/>Product of the digits = 1 * 1 * 2 * 4 = 8.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program to accept a number and check and display whether it is a Niven number or not. (Niven number is a number which is divisible by the sum of its digits).<br/>Example:<br/>Consider the number 126.<br/>Sum of its digits is 1 + 2 + 6 = 9 and 126 is divisible by 9.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Using the switch statement, write a menu driven program to:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program to input a number and print whether the number is a special number or not. (A number is said to be a special number, if the sum of the factorial of the digits of the number is same as the original number).</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a menu driven program to accept a number and check and display whether it is a Prime number or not OR an Automorphic Number or not. (Use switch-case statement).</p>",
                    answer: `<ol><li>Prime number: A number is said to be a prime number if it is divisible only by 1 and itself and not by any other number.<br/>Example: 3, 5, 7, 11, 13 etc.</li><li>Automorphic number: An automorphic number is the number which is contained in the last digit(s) of its square.<br/>Example: 25 is an automorphic number as its square is 625 and 25 is present as the last two digits.</li></ol>`
                },
                { 
                    question: "<p>Write a menu driven program to access a number from the user and check whether it is a BUZZ number or to accept any two numbers and to print the GCD of them.</p>",
                    answer: `<ol><li>A BUZZ number is the number which either ends with 7 or is divisible by 7.</li><li>GCD (Greatest Common Divisor) of two integers is calculated by continued division method. Divide the larger number by the smaller; the remainder then divides the previous divisor. The process is repeated till the remainder is zero. The divisor then results the GCD.</li></ol>`
                },
                { 
                    question: "<p>Write a program in Java to find the sum of the given series:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to find the sum of the given series:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to find the sum of the given series:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to find the sum of the given series:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to find the sum of the given series:</p>",
                    answer: `N/A`
                },
            ]
        },
        { 
            title: "Nested for loops",
            questions: [
                { 
                    question: "<p>Write a program to generate the following output.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program to print the series given below.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to display the following patterns.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to display the following patterns.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to display the following patterns.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to display the following patterns.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to display the following patterns.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to display the following patterns.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to display the following patterns.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to display the following patterns.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to display the following patterns.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Write a program in Java to display the following patterns.</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Distinguish between the following:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>Distinguish between the following:</p>",
                    answer: `N/A`
                },
                { 
                    question: "<p>What will be the value of sum after each of the following nested loops is executed?</p>",
                    answer: `<table><thead><tr><th>i</th><th>j</th><th>Sum</th><th>Remarks</th></tr></thead><tbody><tr><td>0</td><td>0 - 10</td><td>0</td><td>0 is added to sum 11 times</td></tr><tr><td>1</td><td>0 - 10</td><td>11</td><td>1 is added to sum 11 times⇒ 0 + 11 = 11</td></tr><tr><td>2</td><td>0 - 10</td><td>33</td><td>2 is added to sum 11 times⇒ 11 + 22 = 33</td></tr><tr><td>3</td><td>0 - 10</td><td>66</td><td>3 is added to sum 11 times⇒ 33 + 33 = 66</td></tr><tr><td>4</td><td>0 - 10</td><td>110</td><td>4 is added to sum 11 times⇒ 66 + 44 = 110</td></tr><tr><td>5</td><td>0 - 10</td><td>165</td><td>5 is added to sum 11 times⇒ 110 + 55 = 165</td></tr><tr><td>6</td><td>0 - 10</td><td>231</td><td>6 is added to sum 11 times⇒ 165 + 66 = 231</td></tr><tr><td>7</td><td>0 - 10</td><td>308</td><td>7 is added to sum 11 times⇒ 231 + 77 = 308</td></tr><tr><td>8</td><td>0 - 10</td><td>396</td><td>8 is added to sum 11 times⇒ 308 + 88 = 396</td></tr><tr><td>9</td><td>0 - 10</td><td>495</td><td>9 is added to sum 11 times⇒ 396 + 99 = 495</td></tr><tr><td>10</td><td>0 - 10</td><td>605</td><td>10 is added to sum 11 times⇒ 495 + 110 = 605</td></tr><tr><td>11</td><td></td><td></td><td>Loop terminates</td></tr></tbody></table>`
                },
                { 
                    question: "<p>What will be the value of sum after each of the following nested loops is executed?</p>",
                    answer: `<table><thead><tr><th>i</th><th>j</th><th>Sum</th><th>sum + (i + j)</th></tr></thead><tbody><tr><td>1</td><td>1</td><td>2</td><td>0 + 1 + 1</td></tr><tr><td></td><td>2</td><td>5</td><td>2 + 1 + 2</td></tr><tr><td></td><td>3</td><td>9</td><td>5 + 1 + 3</td></tr><tr><td>2</td><td>1</td><td>12</td><td>9 + 2 + 1</td></tr><tr><td></td><td>2</td><td>16</td><td>12 + 2 + 2</td></tr><tr><td></td><td>3</td><td>21</td><td>16 + 2 + 3</td></tr><tr><td>3</td><td>1</td><td>25</td><td>21 + 3 + 1</td></tr><tr><td></td><td>2</td><td>30</td><td>25 + 3 + 2</td></tr><tr><td></td><td>3</td><td>36</td><td>30 + 3 + 3</td></tr><tr><td>4</td><td></td><td></td><td>Loop terminates</td></tr></tbody></table>`
                },
                { 
                    question: "<p>Write a program to generate a triangle or an inverted triangle till n terms based upon the user's choice of triangle to be displayed.</p>",
                    answer: `N/A`
                },
            ]
        },
        { 
            title: "Computing and Ethics",
            questions: [
                { 
                    question: "<p>The term IPR stands for:</p>",
                    answer: `<p>Intellectual Property Rights</p>`
                },
                { 
                    question: "<p>IPR applies to:</p>",
                    answer: `<p>All of these</p>`
                },
                { 
                    question: "<p>Privacy of data can be breached by disclosing information pertaining to:</p>",
                    answer: `<p>All of these</p>`
                },
                { 
                    question: "<p>Which of the following is not a form of software piracy?</p>",
                    answer: `<p>Encrypting</p>`
                },
                { 
                    question: "<p>The right to decide how much personal information you want to communicate with others is known as:</p>",
                    answer: `<p>Privacy</p>`
                },
                { 
                    question: "<p>The unsolicited emails and attractive advertisements are collectively known as:</p>",
                    answer: `<p>Spam</p>`
                },
                { 
                    question: "<p>The right which is concerned with the design and algorithm of the product rather than the entire product is called:</p>",
                    answer: `<p>Patent</p>`
                },
                { 
                    question: "<p>Hacking a web server or taking control of another person's website is called:</p>",
                    answer: `<p>Web Hijacking</p>`
                },
                { 
                    question: "<p>Which one of the following is a cyber crime?</p>",
                    answer: `<p>All of these</p>`
                },
                { 
                    question: "<p>............... involves an attack coupled with a demand for money to stop further attacks.</p>",
                    answer: `<p>Cyber Extortion</p>`
                },
                { 
                    question: "<p>Pick the cybercrime in which the attacker harasses a victim by using the electronic communication.</p>",
                    answer: `<p>Cyberstalking</p>`
                },
                { 
                    question: "<p>Explain what are computer ethics?</p>",
                    answer: `<p>Computer ethics involves the code of conduct to use Information Technology in a responsible way. It fulfils the requirements of an individual user without manipulating or destroying the data of any other user. Therefore, all the computer users must follow computer ethics.</p>`
                },
                { 
                    question: "<p>What are the major ethical issues in the field of computing?</p>",
                    answer: `<ol><li>Data privacy</li><li>Spam</li><li>Software Piracy</li><li>Cybercrimes</li><li>Hacking</li></ol>`
                },
                { 
                    question: "<p>What is hacking? How does it harm a computer and an organisation?</p>",
                    answer: `<p>Hacking refers to an illegal intrusion into a computer system or network.</p>`
                },
                { 
                    question: "<p>What are ethical hackers? What do they do?</p>",
                    answer: `<p>The people who are involved in hacking activities with the goal of improving security are known as ethical hackers. They use ethical hacking for a good cause such as national security or to stop crimes and fraud.</p>`
                },
                { 
                    question: "<p>Why should we control software piracy?</p>",
                    answer: `<ol><li>It is unethical.</li><li>It costs government, businesses and individuals a lot of money.</li><li>It increases the chances of cybersecurity attacks.</li><li>It makes businesses less productive.</li></ol>`
                },
                { 
                    question: "<p>What are spams? How do they affect our life?</p>",
                    answer: `<p>Spams are unwanted bulk emails that come from unsolicited sources.</p>`
                },
                { 
                    question: "<p>Describe various types of cyber crimes prevalent these days.</p>",
                    answer: `<ol><li><strong>Cyberstalking</strong> — It is a crime in which the attacker harasses a victim by using electronic communication, such as an e-mail, instant messaging, or messages posted to a website or a discussion group.</li><li><strong>Cyberbullying</strong> — It is an act of harming or harassing using information technology in a deliberate manner.</li><li><strong>Cyberespionage</strong> — It is the practice of using information technology to obtain secret information without permis­sion from its owners or holders.</li><li><strong>Cyberwarfare</strong> — It involves the use of information technology by a nation to penetrate another nation's networks to cause damage or disruption.</li><li><strong>Cyberterrorism</strong> — Cyberterrorism is the act of conducting terrorism through the use of computers and the internet in order to cause fear and panic.</li><li><strong>Cyber Extortion</strong> — It is a crime that involves an attack coupled with a demand for money to stop further attacks.</li><li><strong>Sexting</strong> — Sexting means sending or forwarding exposed, sexual material to others without taking their consent.</li></ol>`
                },
                { 
                    question: "<p>What Netiquettes do you need to follow when using a social networking site like Twitter?</p>",
                    answer: `<ol><li>Avoid using inflammatory or offensive language, and respect other people's opinions, even if you disagree with them.</li><li>Avoid sarcasm as the other person may misinterpret it.</li><li>Avoid the overuse of emoticons.</li><li>Do not spam and and do not use automated scripts or bots to post repetitive content.</li><li>Avoid the overuse of hashtags as it can make the posts difficult to read.</li><li>Respect privacy.</li><li>Respect everyone's cultures and diversity.</li><li>Never write the entire message in capital letters.</li></ol>`
                },
            ]
        },
    ]
};
