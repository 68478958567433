// src/components/About.js
import React, { useState } from 'react';
import './About.css';
import founderImage from '../assets/image.png'; // Ensure the image is in the correct folder

const teachers = [
    {
        name: "Tr. Rajesh Sharma",
        image: "path/to/rajesh-image.png",
        description: "Tr. Rajesh Sharma is an expert in Mathematics with 10 years of teaching experience. His unique teaching methods help students grasp complex concepts with ease."
    },
    {
        name: "Tr. Neha Verma",
        image: "path/to/neha-image.png",
        description: "Tr. Neha Verma specializes in Science, with a focus on making learning fun and engaging. She has been with Bright Tutorials for 8 years."
    },
    {
        name: "Tr. Pooja Singh",
        image: "path/to/pooja-image.png",
        description: "Tr. Pooja Singh is an English language specialist with a passion for helping students excel in their communication skills. She brings 7 years of teaching experience to Bright Tutorials."
    },
    {
        name: "Tr. Amit Kumar",
        image: "path/to/amit-image.png",
        description: "Tr. Amit Kumar has been teaching Social Studies for 9 years and is known for making history and geography engaging and relevant to his students."
    },
    {
        name: "Tr. Priya Desai",
        image: "path/to/priya-image.png",
        description: "Tr. Priya Desai is a Physics expert with a deep understanding of the subject. Her 6 years of teaching experience have helped many students achieve top grades in science."
    },
];

const About = () => {
    const [selectedTeacher, setSelectedTeacher] = useState(teachers[0]);

    return (
        <div className="about-container">
            <section className="about-hero text-center">
                <div className="container">
                    <p className="quote" style={{ fontFamily: 'Indie Flower, cursive' }}>"Your success is our priority."</p>
                    <p className="about-intro">
                        In an ever-evolving educational landscape, we offer a personalized approach to learning, ensuring that each student’s unique needs and goals are met with expertise and care. Our team of dedicated educators is committed to delivering top-quality instruction that not only enhances academic skills but also fosters a love for learning.
                    </p>
                    <p className="about-intro">
                        We understand that choosing the right coaching class is crucial for your child’s academic growth and future aspirations. That’s why at Bright Tutorials, we focus on creating an environment that is both challenging and supportive, helping students to thrive academically and develop the confidence to succeed in all their endeavors. Whether it’s mastering complex concepts, excelling in exams, or developing critical thinking skills, we are here to guide your child every step of the way. Join us on this journey to academic excellence and watch your child shine brighter.
                    </p>
                </div>
            </section>

            <section className="vision-mission-section">
                <div className="container text-center">
                    <h3 className="vision-title">Vision</h3>
                    <p className="vision-text">Our vision at Bright Tutorials is to foster a community of lifelong learners, empowered to achieve their highest potential through personalized and innovative education.</p>
                    <h3 className="mission-title">Mission</h3>
                    <p className="mission-text">Our mission is to provide exceptional, student-centered coaching that ignites curiosity, fosters academic excellence, and builds the foundation for lifelong success.</p>
                </div>
            </section>

            <section className="founder-section">
                <div className="container founder-layout">
                    <div className="founder-image-container">
                        <img src={founderImage} alt="Mrs. Sonal Tushar Parik" className="founder-image" />
                    </div>
                    <div className="founder-info">
                        <h3>Founder</h3>
                        <p>Spearheaded by our founder, Mrs. Sonal Tushar Parik, an educational visionary with over 15 years of diverse teaching experience. Holding an M.A and a B.Ed, Mrs. Parik has enriched her expertise through roles in various esteemed institutions, shaping the minds of learners from children to adults. Her founding of Bright Tutorials was driven by a deep-seated belief in every child’s potential to excel with the right support and mentorship. Under her guidance, Bright Tutorials stands as a beacon of personalized and impactful education, nurturing students to reach their fullest potential.</p>
                        <p><em>“Every child harbors a universe of potential, and it’s our sacred duty to ignite the spark of learning that transforms potential into brilliance. At Bright Tutorials, we don’t just teach; we awaken dreams and nurture futures.”</em></p>
                        <h4>Mrs. Sonal Tushar Parik</h4>
                    </div>
                </div>
            </section>

            <section className="teachers-section">
                <div className="container teachers-layout">
                    <div className="teacher-image-container">
                        <img src={selectedTeacher.image} alt={selectedTeacher.name} className="teacher-image" />
                    </div>
                    <div className="teacher-info">
                        <h3>{selectedTeacher.name}</h3>
                        <p>{selectedTeacher.description}</p>
                    </div>
                    <div className="teacher-list">
                        {teachers.map((teacher, index) => (
                            <button key={index} className={`teacher-button ${selectedTeacher.name === teacher.name ? 'active' : ''}`} onClick={() => setSelectedTeacher(teacher)}>
                                {teacher.name}
                            </button>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default About;
