import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.png';

const Header = () => {
    const location = useLocation();
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    return (
        <header className="header-section">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg navbar-dark justify-content-center">
                    <a className="navbar-brand" href="#">
                        <img src={logo} alt="Bright Tutorials" className="logo" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded={!isNavCollapsed ? true : false}
                        aria-label="Toggle navigation"
                        onClick={handleNavCollapse}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse justify-content-center`} id="navbarNav">
                        <ul className="navbar-nav">
                            <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                                <Link className="nav-link" to="/" onClick={handleNavCollapse}>Home</Link>
                            </li>
                            <li className={`nav-item ${location.pathname === '/about' ? 'active' : ''}`}>
                                <Link className="nav-link" to="/about" onClick={handleNavCollapse}>About Us</Link>
                            </li>
                            <li className={`nav-item ${location.pathname === '/programs' ? 'active' : ''}`}>
                                <Link className="nav-link" to="/programs" onClick={handleNavCollapse}>Programs Offered</Link>
                            </li>
                            <li className={`nav-item ${location.pathname === '/study-materials' ? 'active' : ''}`}>
                                <Link className="nav-link" to="/study-materials" onClick={handleNavCollapse}>Study Material</Link>
                            </li>
                            <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
                                <Link className="nav-link" to="/contact" onClick={handleNavCollapse}>Contact Us</Link>
                            </li>
                        </ul>
                        <div className="ml-auto">
                            <button className="btn btn-warning enroll-btn">Enroll Now</button>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header;
