import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_1rhbeno', 'template_nn9dcu6', e.target, 'XQCSSp7pTEHjpuHDQ')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
            }, (error) => {
                console.log(error.text);
                alert('Failed to send the message.');
            });

        setFormData({
            name: '',
            email: '',
            message: ''
        });
    };

    return (
        <div className="contact-container">
            <section className="contact-hero">
                <h1>Contact Us</h1>
                <p>Get in touch with us for any queries or assistance.</p>
            </section>

            <div className="contact-content">
                <div className="contact-details">
                    <h2>Our Location</h2>
                    <p>Flat No. 401 Vinnet Heights, Durga Nagar, Bhavani Nagar, Nashik Road, Nashik, Maharashtra 422101</p>
                    <div className="google-map">
                        <iframe
                            title="Bright Tutorials"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.746627248305!2d73.82537969999999!3d19.948016599999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdd95db425a3e5f%3A0xc390690ab70980cf!2sBright%20Tutorials!5e0!3m2!1sen!2sin!4v1693488722901!5m2!1sen!2sin"
                            width="100%"
                            height="350"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>

                <div className="contact-form">
                    <h2>Contact Form</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            ></textarea>
                        </div>
                        <button type="submit" className="submit-btn">Send Message</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
